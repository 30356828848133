export const FaqNftData = [
  {
    question: `Can I use Webm without purchasing an NFT?`,
    answer: `No, Webm is only accessible through the NFT. The NFT serves as your access key to the ad platform as well as membership into the Web3m marketing community.`
  },
  {
    question: `Can I sell my NFT?`,
    answer: `Yes, you are welcome to sell your Webm NFT at any time. You will no longer have access to the NFT benefits—which includes losing access to the Webm ad platform. `
  },
  {
    question: `When will the Webm tokens be released?`,
    answer: `The exact date is not yet scheduled. Expect the token to launch in Q3 2023.`
  },
  {
    question: `Why do I have to apply to purchase the Webm NFT?`,
    answer: `Webm is more than a mere ad platform. It’s a community of Web3 marketers. We have limited the number of available NFTs (and by extension, memberships) to 2,000. The application process helps us ensure a strong and collaborative community.`
  },
  {
    question: `How many people can purchase the NFT?`,
    answer: `Webm has 2,000 total NFTs. This is part of the reason you must apply before purchasing the NFT. With limited available membership, we want to ensure the right businesses have access to the platform and community.`
  },
]