import {Box404, ButtonReport, LinkBox} from "./Page404.styles";
import Image404 from '../../assets/img/404/Group 3.webp'
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {SupportForm} from "./SupportForm";

export const Page404 = ({setIs404}) => {
    const [formStatus, setFormStatus] = useState(false)

    useEffect(() => {
        setIs404(true)
    }, [])

    return (
        <>{formStatus
            ? <SupportForm setIs404={setIs404}/>
            : <Box404>
                <img src={Image404} alt={'404'}/>
                <LinkBox>
                    <Link to={'/'} onClick={() => setIs404(false)}>{'Back to homepage'}</Link>
                    <ButtonReport variant={'text'} onClick={() => setFormStatus(true)}>{'Report error'}</ButtonReport>
                </LinkBox>
            </Box404>
        }</>
    )
}