import {Button, Stack, styled} from "@mui/material";
import {FlexBox} from "../../styles/components/Layout";
import theme from "../../styles/theme";

export const Box404 = styled(Stack)`
  width: 100%;
  min-height: 40rem;
  height: 100vh;
  justify-content: center;
  align-items: center;
  img {
    max-width: 53rem;
    max-height: 50vh;
    ${theme.breakpoints.down('br480')} {
      max-width: 40rem;
    }
  }
`

export const LinkBox = styled(FlexBox)`
  margin-top: 5rem;
  a {
    margin-right: 4rem;
    font-size: 1.6rem;
    &:visited {
      color: black;
    }
  }
`

export const ButtonReport = styled(Button)`
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 1.6rem;
  color: black;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    background-color: unset;
  }
`