import { Button, Stack, styled } from "@mui/material";
import {
  FlexBox,
  ImgBox,
  SectionContainer,
} from "../../styles/components/Layout";
import DecentralandLogo from "../../assets/img/partners/DecentralandLogo.webp";
import DecentralandText from "../../assets/img/partners/DecentralandText.webp";
import theme from "../../styles/theme";

export const TrustedContainer = styled(FlexBox)`
  position: relative;
  max-height: 39.7rem;
  width: 100vw;
  background-color: #FFFFFF;
  background-image: linear-gradient(
    180deg,
    rgba(229, 230, 234, 0.47) 0%,
    rgba(199, 201, 208, 0.47) 100%
  );
`;

export const PartnersBox = styled(SectionContainer)`
  position: relative;
  display: flex;
  margin: 9rem auto 8.5rem;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    &:first-of-type {
      margin-right: 12rem;
      ${theme.breakpoints.down("lg")} {
        margin-right: 7%;
      }
      ${theme.breakpoints.down("br1024")} {
        margin-right: 6%;
      }
      ${theme.breakpoints.down("md")} {
        margin-right: 0;
      }
    }
    ${theme.breakpoints.down("md")} {
      justify-content: space-around;
      margin-bottom: 5rem;
    }
  }

  ${theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    margin: 9rem auto 4rem;
  }
`;

export const PartnerBox1 = styled(ImgBox)`
  ${theme.breakpoints.down("md")} {
    min-width: 15%;
    &:not(:last-of-type) {
      margin-right: 3rem;
    }
  }
  &:nth-of-type(1) {
    max-width: 12rem;
  }
  &:nth-of-type(2) {
    max-width: 12rem;
  }
  &:nth-of-type(3) {
    max-width: 11rem;
    //margin-bottom: 1rem;
  }
`;

export const PartnerBox2 = styled(ImgBox)`
  ${theme.breakpoints.down("md")} {
    &:not(:last-of-type) {
      margin-right: 3rem;
    }
  }
  &:nth-of-type(1) {
    max-width: 17.5rem;
  }
  &:nth-of-type(2) {
    max-width: 10.2rem;
  }
  &:nth-of-type(3) {
    max-width: 15rem;
  }
`;

export const Decentraland = () => {
  return (
    <Stack maxWidth={"18.7rem"} maxHeight={"12.7rem"} alignItems={"center"}>
      <ImgBox height={"69%"} width={"50%"} margin={"0 auto"}>
        <img src={DecentralandLogo} />
      </ImgBox>
      <ImgBox height={"31%"} width={"100%"} margin={"0 auto"}>
        <img src={DecentralandText} />
      </ImgBox>
    </Stack>
  );
};

export const TrustedButton = styled(Button)`
  position: absolute;
  top: -14rem;
  left: 0;
  border-radius: 3.2rem 0 3.2rem 0;
  border: solid 3.2px #fff;
  padding: 1.5rem 5.2rem;
  background-image: linear-gradient(153deg, #f3f5fa -80%, #e3e8f2 69%);
  font-family: "F37 Judge", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #2c6083;
  transform: rotate(-13deg);
  white-space: nowrap;
  &:disabled {
    color: #2c6083;
  }

  &.light:disabled {
    background-image: none;
    background-color: #fff;
  }
  ${theme.breakpoints.down("br768")} {
    left: 50%;
    transform: translateX(-50%) rotate(-13deg);
  }
`;
