// import {Header} from "./sections/main/header/Header";
import { Main } from "./sections/main/Main";
// import {Footer} from "./sections/main/footer/Footer";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import { BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

function App() {
  // const location = useLocation()
  // const onRedirectCallback = (appState) => {
  //     location.push(appState?.returnTo || window.location.pathname);
  // };

  const redirectUri = () => {
    if (typeof window !== "undefined") {
      return window?.location.origin;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HttpsRedirect>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            audience="https://dev-s5y764on.us.auth0.com/api/v2/"
            scope="read:current_user update:current_user_metadata"
            useRefreshTokens
            redirectUri={redirectUri()}
            // onRedirectCallback={onRedirectCallback}
            // cacheLocation="localstorage"
          >
            <Main />
          </Auth0Provider>
        </HttpsRedirect>
      </BrowserRouter>
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
