import { Stack, styled, Typography } from "@mui/material";
import { FlexBox, SvgBox } from "../../styles/components/Layout";
import theme from "../../styles/theme";

export const Top = styled(FlexBox)`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  min-height: 32rem;

  ${theme.breakpoints.down("br1024")} {
    flex-direction: column;
    justify-content: center;
    padding: 8rem 0;
  }

  ${theme.breakpoints.down("sm")} {
    /* margin-top: 5rem; */
    padding-top: 11rem;
  }
`;

export const TopBox = styled(FlexBox)`
  align-items: center;

  & a:first-of-type {
    margin-right: 5rem;
  }

  ${theme.breakpoints.down("br1024")} {
    margin-top: 5rem;
    flex-direction: column;
    width: 65%;

    & a:first-of-type {
      width: 100%;
      max-width: 55rem;
      margin-right: 0;
      margin-bottom: 5rem;
      white-space: nowrap;
    }
  }
`;
export const Title = styled(Typography)`
  ${theme.breakpoints.down("br768")} {
    text-align: center;
  }
`;
export const PositiveNumbersBox = styled(FlexBox)`
  height: fit-content;
  justify-content: space-between;
  margin-top: 8.5rem;
  margin-bottom: 24.15rem;
  ${theme.breakpoints.down("br768")} {
    flex-direction: row;
    margin-top: 5rem;
    margin-bottom: 12rem;
  }
  /* ${theme.breakpoints.down("sm")} {

  } */
`;

export const NumbersCard = styled(Stack)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 31.5%;
  aspect-ratio: 1/1;
  border-radius: 29px;
  background-image: linear-gradient(
    to bottom,
    rgba(229, 230, 234, 0.17),
    rgba(199, 201, 208, 0.17)
  );
  & div {
    width: 46%;
    @media screen and (max-width: 1058px) {
      width: 52%;
    }
  }
  &:nth-of-type(3) {
    & > div {
      padding-left: 2rem;
    }
  }

  ${theme.breakpoints.down("br768")} {
    width: 33.3%;
    background-color: #fff;
    background-image: none;
    > div {
      background-color: #fff;
      width: 90%;
      align-items: center;
    }
    &:nth-of-type(3) {
      & > div {
        padding-left: 0;
      }
    }
  }
`;

export const TitleCard = styled(Typography)`
  font-family: "F37 Judge", sans-serif;
  font-size: 4.03rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 1.41px;
  color: #4540bb;

  ${theme.breakpoints.down("md")} {
    font-size: 3rem;
  }
  ${theme.breakpoints.down("br768")} {
    font-size: 4.03rem;
    text-align: center;
  }
  ${theme.breakpoints.down("sm")} {
    font-size: 2rem;
  }
`;

export const SubtitleCard = styled(Typography)`
  font-weight: 500;
  line-height: 1.11;
  color: #11354f;
  max-width: 15rem;
  margin-top: 2.4rem;

  ${theme.breakpoints.down("md")} {
    font-size: 1.6rem;
    max-width: 10rem;
  }
  ${theme.breakpoints.down("br768")} {
    font-size: 2.4rem;
    max-width: 25rem;
    text-align: center;
  }
  ${theme.breakpoints.down("sm")} {
    margin-top: 1.8rem;
    font-size: 1.2rem;
    max-width: 12rem;
  }
`;

export const IconBox = styled(SvgBox)`
  max-width: 4.4rem;
  margin-bottom: 1.5rem;

  ${theme.breakpoints.down("md")} {
    max-width: 3.4rem;
  }
  ${theme.breakpoints.down("br768")} {
    max-width: 4.4rem;
  }
  & img {
    width: 100%;
    height: 100%;
  }

  ${theme.breakpoints.down("br768")} {
    img {
      width: auto;
      height: 3rem;
    }
  }
`;
