import {styled, Box, Typography} from "@mui/material";
import theme from "../../styles/theme";
import {SectionContainer} from "../../styles/components/Layout";

export const TitleContainer = styled(Box)`
  position: relative;
  width: 100vw;
  background-color: #002541;
  /* margin-top: 5rem; */
  padding-top: 8rem;
  padding-bottom: 9rem;
  margin-bottom: 5rem;
  ${theme.breakpoints.down("br768")} {
    max-width: 100%;
    margin-bottom: 35%;
    padding-bottom: 0;
  }
  ${theme.breakpoints.down("sm")} {
    padding-top: 4rem;
  }
  ${theme.breakpoints.down("br480")} {
    margin-bottom: 40%;
  }
  h1 {
    max-width: 70rem;
    margin-bottom: 2.5rem;
    b {
      color: #f2a626;
    }
    ${theme.breakpoints.down("xl")} {
      max-width: 50%;
    }
    ${theme.breakpoints.down("br768")} {
      max-width: 100%;
      text-align: center;
    }
    ${theme.breakpoints.down("sm")} {
      font-size: 3.3rem;
    }
  }
  p {
    max-width: 50%;
    color: #fff;
    ${theme.breakpoints.down("xl")} {
      max-width: 40%;
    }
    ${theme.breakpoints.down("br768")} {
      max-width: 63rem;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    ${theme.breakpoints.down("br480")} {
      font-size: 1.8rem;
    }
  }
  .tip {
    position: absolute;
    top: calc(8.9rem + 100%);
    left: 9%;
    z-index: 2;
    ${theme.breakpoints.down("br768")} {
      display: none;
    }
  }
`;

export const TitleSectionContainer = styled(SectionContainer)`
  margin-bottom: 15rem;
  & a:first-of-type {
    margin-right: 4rem;
  }
  ${theme.breakpoints.down("br768")} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 11rem;
    width: 65%;
    & a:first-of-type {
      margin-bottom: 4rem;
      margin-right: 0;
      width: 100%;
    }
  }
`;
export const ImageContainer = styled(Box)`
  position: absolute;
  right: -2.5rem;
  top: -1rem;
  max-width: 66rem;
  /* z-index: 2; */
  img {
    -webkit-user-drag: none;
    &:nth-of-type(1) {
      display: block;
      margin-left: auto;
      width: 100%;
      animation: fromRight 0.5s ease-in-out;
    }
    &:nth-of-type(2) {
      width: 43%;
      transform: translate(-33%, -85%);
      animation: fromBottom 0.5s ease-in-out;
    }
  }
  ${theme.breakpoints.down("br1024")} {
    max-width: 49%;
    top: 10rem;
  }
  ${theme.breakpoints.down("md")} {
    max-width: 52%;
    /* top: 7.5rem; */
  }
  ${theme.breakpoints.down("br768")} {
    position: relative;
    max-width: 108%;
    right: unset;
    /* left: -1.5rem; */
    top: unset;
    margin: 5rem -4% -15%;
    transform: scale(0.9) translateX(5%);
    height: 22rem;
  }
  @keyframes fromRight {
    0% {
      opacity: 0;
      transform: translateX(50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fromBottom {
    0% {
      opacity: 0;
      transform: translate(-33%, -25%);
    }
    100% {
      opacity: 1;
      transform: translate(-33%, -85%);
    }
  }
`;

export const VideoContainer = styled(Box)`
  position: absolute;
  bottom: -34.6rem;
  right: -2rem;
  z-index: 0;
  video {
    max-width: 120rem;
    //width: 100%;
    //height: 100%;
  }
  @media screen and (max-width: 1900px){
    bottom: -35.4rem;
    right: -4rem;
    video {
      max-width: 110rem;
    }
  }
  @media screen and (max-width: 1200px){
    bottom: -35.4rem
  }
  @media screen and (max-width: 767px){
    bottom: -19.5rem;
    right: 0;
    video {
      max-width: 80rem;
    }
  }
  ${theme.breakpoints.down('sm')} {
    bottom: -19.6rem;
  }
  ${theme.breakpoints.down('br480')} {
    bottom: -18.7rem;
    right: -5rem
  }
  @media screen and (max-width: 360px){
    bottom: -10rem;
    right: -4rem;
    video {
      max-width: 60rem;
    }
  }
`

export const TitleZIndex = styled(Typography)`
  position: relative;
  z-index: 1;
`