import web3Client from "./web3Client";
import {DEV_ONLY} from "../utils/helpers";
import gatewayClient, {findToken} from "./gatewayClient";

export const Send404Form = async (data) => {
    const body = {
        ...data,
        origin_of_bug: DEV_ONLY ? 'advertisers.web3m.io' : 'web3m.io'
    }
    try {
        const response = await web3Client.post('send_404_form', body)
        return response.data
    } catch (err) {
        console.log(err)
        return null
    }
}

export const CheckNFT = async (wallet) => {
    try {
        const response = await web3Client.post('validate_nft', {
            wallet_address: wallet,
        })
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const UploadLogo = async (data) => {
    try {
        const response = await web3Client.post('upload_logo_base64',data)
        return response?.data
    }catch (error) {
        console.log(error)
    }
}