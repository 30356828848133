import KeyPoints from "../sections/keyPoints/KeyPoints";
import { PositiveNumbers } from "../sections/positiveNumber/PositiveNumbers";
import Title from "../sections/title/Title";
import { TrustedBlock } from "../sections/trustedBlock/TrustedBlock";
import { CampaignBlock } from "../sections/campaigns/campaignBlock";
import React, { useState } from "react";

const HomePage = () => {
  const [active, setActive] = useState({num: 1, name: 'defi'});
    // const [active, setActive] = useState({num: 2, name: 'decentralized exchange'});

    return (
    <>
      <Title />
      <CampaignBlock active={active} setActive={setActive} />
      <KeyPoints />
      <PositiveNumbers />
      <TrustedBlock />
    </>
  );
};

export default HomePage;
