import React, { useEffect } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { SocialMediaIcons } from "../../assets/svg/socialMedia";
import { SectionContainer } from "../../styles/components/Layout";
import { SocialMedia } from "../socialMedia/SocialMedia";
import { TrustedBlock } from "../trustedBlock/TrustedBlock";
import {
  JoinDiscordButton,
  ThankYouImageBox,
  ThankYouLowerMobileBackground,
  ThankYouSocialMedia,
  ThankYouTitleContainer,
} from "./ThankYou.styles";
import AppRotate from "../../assets/img/AppRotate.webp";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const ThankYou = () => {
  const notMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (location.state !== "sent") {
    return <Navigate to="/signup" />;
  }

  return (
    <>
      <ThankYouTitleContainer>
        <SectionContainer>
          <Typography variant="h1" zIndex={10}>
            <b>CONGRATULATIONS!</b> <br /> WE GOT YOUR REQUEST
          </Typography>
          <Typography variant="body2" marginBottom={"10.5rem"} maxWidth={"50rem !important"}>
            Our team is excited to talk with you. You’ll hear from us soon about all the details.
          </Typography>
          <JoinDiscordButton startIcon={SocialMediaIcons.discord}>
            <a href="https://discord.gg/A7Fa9EctNp" target="discord"> Meet our Discord {notMobile && `community`}</a>
          </JoinDiscordButton>
          <ThankYouSocialMedia>
            <Typography>and also stay tuned with:</Typography>
            <SocialMedia />
          </ThankYouSocialMedia>
          <ThankYouImageBox>
            <img src={AppRotate} alt="app" />
          </ThankYouImageBox>
        </SectionContainer>
        <ThankYouLowerMobileBackground />
      </ThankYouTitleContainer>
      <TrustedBlock />
    </>
  );
};

export default ThankYou;
