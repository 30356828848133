import BasketballImg from '../../../assets/img/nft/benefits/Basketball.webp'
import PlanesImg from '../../../assets/img/nft/benefits/papperPlanes.webp'
import TvImg from '../../../assets/img/nft/benefits/TV.webp'
import IncreaseImg from '../../../assets/img/nft/benefits/Increase.webp'
import HouseImg from '../../../assets/img/nft/benefits/House.webp'
import HatImg from '../../../assets/img/nft/benefits/Hat.webp'

export const benefitsData = [
  {
    image: <img src={BasketballImg} alt={'Basketball'}/>,
    title: 'AI-targeting and creative',
    desc: 'Scale your advertising with smart targeting and AI-assisted content creation.'
  },
  {
    image: <img src={PlanesImg} alt={'Plane'}/>,
    title: 'Multi-channel campaigns',
    desc: 'Seamlessly advertise across Web3. Build and go live with your first multi-channel campaign in just 5 minutes.'
  },
  {
    image: <img src={TvImg} alt={'TV'}/>,
    title: 'More than just an NFT',
    desc: 'Webm is a premier advertising and media planning tool—that just happens to operate on the blockchain.'
  },
  {
    image: <img src={IncreaseImg} alt={'Increase'}/>,
    title: 'Detailed campaign analytics',
    desc: 'The Webm dashboard gives you real-time insights so that you can double down on what’s working.\n'
  },
  {
    image: <img src={HouseImg} alt={'House'}/>,
    title: 'Decentralized to the core',
    desc: 'We built Webm to be a community-built platform. Token holders have voting privileges, giving power to the users'
  },
  {
    image: <img src={HatImg} alt={'Hat'}/>,
    title: 'Vibrant (and vetted) community',
    desc: 'Enjoy membership to exclusive online channels and events to meet fellow Web3 marketers and entrepreneurs.'
  },
]