import React from "react";
import {browserName} from 'react-device-detect'
import { SectionContainer } from "../../styles/components/Layout";
import { DialogTip } from "../../assets/svg/decorations";
import {
  ImageContainer,
  TitleContainer,
  TitleSectionContainer, TitleZIndex, VideoContainer
} from "./Title.styles";
import { LinkDiscord, MainButton } from "../../styles/components/buttons";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import VideoMp4 from "../../assets/video/head-amimation-mp4.mp4";
import VideoWebm from "../../assets/video/head-amimation-webm.webm";
import VideoBG from "../../assets/img/VideoBG.webp";
// import HeadDsk from '../../assets/img/screens/Head_dsk.webp'
// import VideoMov from '../../assets/video/samp3_1.mov'
// import NewWebm from '../../assets/video/samp3_1-vp9-chrome.webm'
// import NewMp4 from '../../assets/video/samp3_1-hevc-safari.mp4'

const Title = () => {
  const { isAuthenticated } = useAuth0();
  // console.log(browserName);
  return (
    <>
      <TitleContainer>
        <SectionContainer>
          <TitleZIndex variant="h1">
            The multi-channel <b> web3</b> marketing platform
          </TitleZIndex>
          <TitleZIndex variant="body2">
            All the necessary tools to grow. Analyze your brand, generate
            automated creative and reachout 1B+ active users on multiple
            channels - all in one place.
          </TitleZIndex>
          <ImageContainer>
            {/*<img src={HeadDsk} alt=""/>*/}
            {/*<img src={HeadMob} alt=""/>*/}
          </ImageContainer>
          <VideoContainer>
            <video muted autoPlay loop playsInline poster={VideoBG}>
              {/*{browserName === 'Safari' && <source src={VideoMov} type="video/mov" />}*/}
              {/*{browserName !== 'Safari' && <source src={NewWebm} type="video/webm" />}*/}
              {/*{browserName === 'Safari' && <source src={NewMp4} type="video/mp4" />}*/}
              <source src={VideoWebm} />
              <source src={VideoMp4}/>
            </video>
          </VideoContainer>
          <DialogTip className="tip" />
        </SectionContainer>
      </TitleContainer>
      <TitleSectionContainer zIndex={2}>
        {isAuthenticated
          ? <MainButton href={"https://dashboard.web3m.io"} target={"_dashboard"}>
            {"Enter your user account"}
          </MainButton>
          : <MainButton component={Link} to={"/signup"}>
            {"Get early access"}
          </MainButton>
        }
        <LinkDiscord href={"https://discord.gg/7NFVRymVZf"} target={"_blank"}>
          {"Join the community"}
        </LinkDiscord>
      </TitleSectionContainer>
    </>
  );
};

export default Title;