import { Box, List, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FlexBox } from "../../styles/components/Layout";
import theme from "../../styles/theme";
import { TitleContainer } from "../title/Title.styles";

export const SignUpTitleContainer = styled(TitleContainer)`
  padding-bottom: 47rem;
  h1 {
    max-width: 95rem;
  }
  img {
    position: absolute;
    right: -2.5rem;
    top: -1rem;
    max-width: 67.5rem;
    z-index: 2;

    ${theme.breakpoints.down("br1024")} {
      max-width: 49%;
      top: 7rem;
    }
    ${theme.breakpoints.down("md")} {
      max-width: 49%;
      top: 9rem;
    }
    ${theme.breakpoints.down("br768")} {
      position: relative;
      max-width: 108%;
      right: unset;
      left: -1.5rem;
      top: unset;
      margin: 5rem -4% -60%;
    }
  }
  ${theme.breakpoints.down("md")} {
    padding-bottom: 17rem;
    margin-bottom: 1rem;

    h1 {
      text-align: center;
    }
    p {
      max-width: 90%;
      margin: 0 auto;
    }
    img {
      display: none;
    }
  }
`;

export const SignUpBodyContainer = styled(FlexBox)`
  align-items: flex-start;
  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    /* align-items: center; */
  }
`;

export const SignUpFormContainer = styled(Box)`
  width: 77rem;
  padding: 12.5rem 9rem 8.5rem;
  border-radius: 3rem;
  /* box-shadow: -1px 51px 45px -6px rgba(0, 0, 0, 0.17); */
  border: solid 1px #c6d6e3;
  background-color: #ebf1f5;
  margin-top: -30%;

  label {
    text-transform: uppercase;
  }

  ${theme.breakpoints.down("md")} {
    margin-top: -10rem;
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 15% 10% 10% 10%;
    box-shadow: -1px 21px 25px -6px rgba(0, 0, 0, 0.1);
  }

  label {
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 4.2px;
    color: #42475b;
    margin-bottom: 0.7rem;

    ${theme.breakpoints.down("sm")} {
      margin-bottom: 0;
    }
  }

  .MuiInputBase-root {
    width: 100%;
    border-radius: 1.3rem;
    box-shadow: 0 6px 13px 0 #d6dee3;
    border: solid 1.3px #97a0c3;
    background-color: #fafcfe;
    margin-bottom: 5.5rem;

    ${theme.breakpoints.down("sm")} {
      margin-bottom: 4.5rem;
    }

    input,
    textarea {
      padding: 1.1rem 2rem 1.3rem;
      font-size: 2.1rem;
      letter-spacing: 0.65px;
      color: #636e95;
    }
    textarea {
      padding: 0 0.5rem;
    }
    fieldset {
      border: none;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  button {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: normal;
    padding: 2.2rem 3.9rem;
    margin-bottom: 5.1rem;
    ${theme.breakpoints.down("sm")} {
      margin-top: 2rem;
      width: 100%;
    }
  }

  p {
    max-width: 42rem;
    opacity: 0.9;
    font-size: 1.96rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: 1.13px;
    color: #42475b;
    ${theme.breakpoints.down("sm")} {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
`;

export const FormError = styled(Typography)`
  font-weight: 600 !important;
  color: ${theme.palette.error.main} !important;
  transform: translateY(-100%);
`;

export const ForWhoListContainer = styled(List)`
  margin-bottom: 6rem;

  ${theme.breakpoints.down("sm")} {
    margin-bottom: 3rem;
  }
  li {
    padding-left: 0;
    height: 6.6rem;
    margin-bottom: 1.5rem;

    ${theme.breakpoints.down("sm")} {
      height: 6rem;
    }

    > div {
      align-items: flex-start;
    }

    .MuiListItemText-root {
      max-width: 46rem;
      ${theme.breakpoints.down("sm")} {
        max-width: 75vw;
      }
    }

    svg {
      margin-right: 1.2rem;

      ${theme.breakpoints.down("sm")} {
        width: 3rem;
        position: relative;
        top: 4px;
        /* align-self: center; */
        /* transform: translateY(5px); */
      }
    }
  }
`;

export const SubText = styled(Typography)`
  opacity: 0.9;
  font-size: 2.46rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 1.42px;
  color: #233847;
`;
