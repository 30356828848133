import {Box, IconButton, Stack, styled, Tab, Typography} from "@mui/material";
import {FlexBox, ImgBox} from "../../styles/components/Layout";
import CreativeSvg from '../../assets/img/CreativeBox.webp'
import theme from "../../styles/theme";

export const ExampleContainer = styled(Stack)`
  position: relative;
  margin-bottom: -30rem;
  z-index: 2;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 30px;
  box-shadow: -1px -11px 45px -6px rgba(0, 0, 0, 0.1), inset -7px -19px 23px 17px #fff;
  padding: 14rem 4.9rem 2.6rem;
  // animation: 1s view;
  // opacity: ${props => !props.active && 0};
  // @keyframes view {
  //   0% {
  //     opacity: 0;
  //     height: 80%;
  //     margin-top: -20rem;
  //   }
  //   100% {
  //     opacity: 1;
  //     height: 100%;
  //     margin-top: 0;
  //   }
  // }
  ${theme.breakpoints.down('br768')} {
    box-shadow: unset;
    border: 3.6px dashed #636E95;
    animation: unset;
    padding: 9rem 3rem 2.6rem;
  }
  ${theme.breakpoints.down('sm')} {
    border: 2.8px dashed #636E95;
  }
`

export const ButtonClose = styled(IconButton)`
  position: absolute;
  top: 2.6rem;
  right: 2.6rem;
  width: 2.1rem;
  height: 2.1rem;
  padding: 0;
  & svg {
    width: 100%;
    height: 100%;
  }
`
export const CommunitySentiment = styled(Stack)(({theme}) =>`
  position: relative;
  border-top: 1px solid #c1c4c9;
  border-radius: 0 0 0.9rem 0.9rem;
  padding: 5.2rem 0 7rem;
  height: fit-content;
   margin: 0 2.5rem 0 .5rem;
   
  &.loading{
    background-color: rgba(221, 221, 221, 0.2);
    box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.03);
  }
${theme.breakpoints.down('br1024')} {
    margin:0;
}

  ${theme.breakpoints.down('br768')}{
    padding-top: 3rem;
  //   padding-bottom: 3rem;
    border-top: none;
    // margin-bottom: 2rem;
  }
`);
export const CommunitySentimentTitle = styled(Typography)(({theme}) => `
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  color: #a5aab5;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -0.2rem;
  padding-right: 2rem;
  transform: translate(0, -50%);
`);

export const CommunitySentimentDescription = styled(Typography)`
  width: 95%;
  margin-bottom: 3rem;
  font-size: 1.4rem;
    `

export const SentimentCustomTab = (props) => {
    const StyledTab = styled(Tab)`
    /* flex: 1; */
      text-transform: capitalize;
    min-height: auto;
    padding: 1.4rem 0;

    font-size: 2.2rem;
    font-weight: bold;

    letter-spacing: 0.24px;

      ${theme.breakpoints.down('br1024')} {
        font-size: 2rem;
    }
      ${theme.breakpoints.down('sm')} {
        font-size: 1.6rem;
      }
    &.Mui-selected {
      color: #132338;
    }

    &[aria-selected="false"] {
      color: #1a418e;

      opacity: 0.44;
    }

    &:not(:last-of-type) {
      margin-right: 2.5rem;
      ${theme.breakpoints.down('sm')} {
        margin-right: 0;
      }
    },
      &:last-of-type {
        ${theme.breakpoints.down('sm')} {
          margin-left: 2rem;
        }
      }

    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  `;

    return <StyledTab iconPosition="start" {...props} />;
};

export const SentimentComments = styled(Stack)(
    ({ theme }) => `
  margin-top: 6rem;

  ${theme.breakpoints.down("br1024")}{
    margin-top: 3rem;
    padding-right: 3rem;
  }

  p {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    
    &:first-of-type {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.41;
      letter-spacing: 0.2px;
      color: #4a5767;
      ${theme.breakpoints.down("br1024")}{
      font-size: 16px;
    }
  }
    
    &:last-of-type {
      font-size: 16px;
      line-height: 1.59;
      letter-spacing: 0.18px;
      color: #4a5767;
      ${theme.breakpoints.down("br1024")}{
      font-size: 14px;
    }
  }
  }
  
`
);

export const CreativesContainer = styled(FlexBox)`
  width: 100%;
  height: max-content;
  margin-bottom: 5.1rem;
  justify-content: space-between;
`

export const CreativeCard = styled(Stack)`
  width: 18.5%;
  height: min-content;
  ${theme.breakpoints.down('lg')} {
    width: 23.5%;
  }
  ${theme.breakpoints.down('md')} {
    width: 31%;
  }
  ${theme.breakpoints.down('br768')} {
    width: 100%;
    max-width: 45rem;
    margin: 0 auto 8rem;
  }
`

export const BackgroundCreative = styled(Box)`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(${CreativeSvg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
`
export const CreativeTop = styled(Stack)`
  width: 100%;
  position: relative;
`

export const ChannelBox = styled(FlexBox)`
  padding: 1.6rem;
  ${theme.breakpoints.down('br768')} {
    padding: 1.9rem 2rem 1.5rem;
  }
`

export const NumbersBox = styled(ChannelBox)`
  margin: 0 0.7rem 1rem;
  justify-content: space-between;
  ${theme.breakpoints.down('br768')} {
    justify-content: space-around;
    margin: 0 0.7rem 2.2rem;
  }
`

export const ChannelImgBox = styled(ImgBox)`
  max-width: 3.6rem;
  max-height: 3.6rem;
  margin-right: 1rem;
  ${theme.breakpoints.down('br768')} {
    max-width: 5rem;
    max-height: 5rem;
    margin-top: 1rem;
  }
`

export const TitleChannel = styled(Typography)`
  font-size: 1.27rem;
  font-weight: 600;
  letter-spacing: 0.14px;
  color: #4f5c87;
  text-transform: capitalize;
  ${theme.breakpoints.down('br768')} {
    font-size: 1.8rem;
  }
`

export const SubtitleChannel = styled(Typography)`
  font-size: .94rem;
  font-weight: 500;
  letter-spacing: 0.29px;
  color: #4f5c87;
  ${theme.breakpoints.down('br768')} {
    font-size: 1.4rem;
    line-height: 0.7;
  }
`

export const CreativeNumber = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 1.97rem;
  font-weight: bold;
  letter-spacing: 0.61px;
  color: #242f57;
  ${theme.breakpoints.down('br768')} {
    font-size: 3rem;
    margin-top: 1rem;
  }
`

export const CreativeNumberText = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 1.12rem;
  font-weight: 500;
  letter-spacing: 0.35px;
  text-align: center;
  color: #7a86b4;
  ${theme.breakpoints.down('br768')} {
    font-size: 1.8rem;
    line-height: 1;
  }
`