import React from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Stack,
} from "@mui/material";
import {Checkbox} from "../../assets/svg/icons";
import {ForWhoListContainer, SubText} from "./SignUp.styles";
import {whoIsItForList} from "./SignUpHelpers";

const ForWhoList = () => {
    return (
        <Stack
            sx={{
                marginTop: {xs: "10rem", sm: "17rem"},
                marginBottom: {xs: "13rem", sm: "25rem"},
                marginLeft: {xs: 0, md: "15rem"},
            }}
        >
            <Typography variant="h2" marginBottom={"5.7rem"}>
                Who is it for?
            </Typography>
            <ForWhoListContainer>
                {whoIsItForList.map((el, i) => (
                    <ListItem key={el + i}>
                        <ListItemIcon>
                            <Checkbox width={36}/>
                            <ListItemText primary={<Typography>{el}</Typography>}/>
                        </ListItemIcon>
                    </ListItem>
                ))}
            </ForWhoListContainer>
            <SubText>Sounds like you? See you inside!</SubText>
        </Stack>
    );
};

export default ForWhoList;
