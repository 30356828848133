import {
  AllRights,
  FooterContainer,
  LogoFooterBox,
  SubContainer,
} from "./Footer.styles";
import { Logo } from "../../../assets/svg/logo";
import { SocialMedia } from "../../socialMedia/SocialMedia";
import { useBreakpoints } from "../../../shared/hooks/useBreakpoints";

export const Footer = () => {
  const { screen1024 } = useBreakpoints();

  return (
    <FooterContainer>
      {screen1024 && <SocialMedia />}
      <SubContainer>
        <LogoFooterBox>
          <Logo />
        </LogoFooterBox>
        {!screen1024 && <SocialMedia />}
        <AllRights>{"All rights reserved © 2022 Web3m"}</AllRights>
      </SubContainer>
    </FooterContainer>
  );
};
