import { Box, List, styled } from "@mui/material";
import { MainButton } from "../../../styles/components/buttons";
import theme from "../../../styles/theme";
import { NFTSectionContainer } from "../../../styles/components/Layout";

export const HeroNFTOuterContainer = styled(Box)`
  position: relative;
  //overflow: hidden;
  background-color: #00213a;
  width: 100%;
  padding-top: 12rem;

  h1 {
    margin-bottom: 3.1rem;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.04;
    letter-spacing: -0.25px;
    color: #fff;
    margin-bottom: 1.5rem;
  }

  ${theme.breakpoints.down("md")} {
    padding-top: 6rem;
    > div {
      h1 {
        max-width: 50vw;
      }

      ul {
        max-width: 40vw;
      }
    }
  }

  ${theme.breakpoints.down("br768")} {
    z-index: 5;
    border-bottom-left-radius: 32rem;

    > div {
      padding-bottom: 30rem;

      h1 {
        max-width: 100%;
      }

      ul {
        max-width: 100%;

        li {
          padding-top: 0;
        }
      }
    }
  }
  ${theme.breakpoints.down("sm")} {
    > div {
      padding-bottom: 20rem;
    }
  }
  ${theme.breakpoints.down("br480")} {
    > div {
      padding-bottom: 15rem;
    }
  }
`;

export const HeroContent = styled(NFTSectionContainer)`
  ${theme.breakpoints.down("br768")} {
    max-width: 55%;
  }
  ${theme.breakpoints.down("sm")} {
    max-width: 65%;
  }
  ${theme.breakpoints.down("br480")} {
    max-width: 75%;
  }
`;

export const HeroNFTList = styled(List)`
  padding-bottom: 6.5rem;
  li {
    padding-left: 0;

    .MuiListItemAvatar-root {
      min-width: auto;
      margin-right: 0.9rem;
      display: flex;
    }

    svg {
      width: 3.9rem;
      height: 3.9rem;
    }

    span {
      opacity: 0.66;
      font-family: Hellix, sans-serif;
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: -0.21px;
      color: #fff;
    }
  }
`;

export const CTAButtonNFT = styled(MainButton)`
  padding: 1.5rem 4.7rem 1.5rem 3.5rem;
  margin-bottom: 14rem;
  svg {
    height: 5rem;
    margin-right: 0.5rem;
  }
`;

export const HeroNftImage = styled(Box)`
  position: absolute;
  left: 50%;
  bottom: -7.5rem;
  z-index: 1;

  .swiper {
    position: absolute;
    width: 70rem;
    height: 72rem;
    left: -7rem;
    //right: 0;
    bottom: 5rem;
  }
  //.alice-carousel {
  //  position: absolute;
  //  height: 72rem;
  //  left: -7rem;
  //  //right: 0;
  //  bottom: 3.5rem;
  //}

  img {
    //left: 0;
    height: 72rem;
    //width: 60rem;
  }
  ${theme.breakpoints.down("md")} {
    .alice-carousel {
      //left: -8.5rem;
      //width: 100vw;
      //bottom: 1rem;

      //li:not(.__active){
      //  display: none;
      //}

      img {
        //height: 70rem;
      }
    }
  }
  ${theme.breakpoints.down("br768")} {
    position: absolute;
    //bottom: 1px;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 90%;
    max-width: 70rem;
    //top: 88%;
    z-index: 10;

    img {
      position: relative;
      //display: none;
      //height: auto;
      max-height: 60rem;
      margin: 0 auto;
      left: 50%;
      //transform: translateX(-65%);
      transform: translateX(-53%);
      //bottom: -1rem;
      bottom: 11.4rem;
    }
  }
  ${theme.breakpoints.down("sm")} {
    img {
      max-height: 50rem;
      bottom: 10.7rem;
    }
  }

  ${theme.breakpoints.down("br480")} {
    img {
      //bottom: 10rem;
      max-height: 40rem;
      bottom: 10.1rem;
    }
  }
`;

export const HeroNftImageBg = styled(Box)`
  min-width: 50vw;
  background-image: linear-gradient(to right, transparent 30rem, #f2a626 0rem);

  svg {
    height: 68rem;
  }

  ${theme.breakpoints.down("br768")} {
    width: 100%;
    background-image: none;

    svg {
      height: auto;
      width: 100%;
      //max-width: 80%;
      //margin: 0 auto;
    }
  }
`;
