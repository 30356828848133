import Coinzilla from '../img/channels/coinzilla.png'
export const channelIcons = {
  bitmedia: <BitMediaSvg />,
  coinzilla: <img src={Coinzilla} alt='coinzilla icon'/>,
};

export function BitMediaSvg(props) {
  return (
    <svg viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M233.454 254.239c.038.002.062.005.086.005h3.874a.74.74 0 00.144-.014c.758-.153 1.073-1.059.556-1.639-.558-.625-1.138-1.23-1.708-1.844l-.648-.7.615-.663c.587-.632 1.18-1.258 1.759-1.897.275-.304.351-.672.18-1.052-.173-.387-.49-.602-.917-.604-1.27-.006-2.542-.002-3.813-.002-.042 0-.085.004-.128.006v8.404zm-3.268-11.455H237.373c2 0 3.693 1.456 3.994 3.444.201 1.336-.19 2.484-1.111 3.457l-.342.36c.117.126.228.249.343.367a4.07 4.07 0 01-.141 5.823 3.901 3.901 0 01-2.715 1.074c-1.368.007-2.736.003-4.104 0a.32.32 0 00-.252.107c-.913.931-1.828 1.86-2.742 2.79-.034.034-.06.076-.089.115l-.028-.019v-17.518z"
        transform="translate(-1566 -2855) translate(465.5 1721.946) translate(45.744 731.365) translate(824.846 .028) translate(0 159.816)"
        fill="#8DACD4"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CoinzillaSvg(props) {
  return (
    <svg
      viewBox="0 0 113 120"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="55.1656259%"
          y1="22.8501207%"
          x2="49.0038435%"
          y2="76.3824188%"
          id="a"
        >
          <stop stopColor="#EA8532" offset="0%" />
          <stop stopColor="#D6711D" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="62.2111643%"
          y1="0.806607845%"
          x2="48.7824001%"
          y2="74.2278007%"
          id="b"
        >
          <stop stopColor="#EA8532" offset="0%" />
          <stop stopColor="#D6711D" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="52.5460612%"
          y1="24.7025096%"
          x2="33.5480933%"
          y2="87.2728294%"
          id="c"
        >
          <stop stopColor="#EA8532" offset="0%" />
          <stop stopColor="#D6711D" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="42.8968638%"
          y1="100.755439%"
          x2="53.6618342%"
          y2="2.26914696%"
          id="d"
        >
          <stop stopColor="#D69545" offset="0%" />
          <stop stopColor="#CC6815" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="60.2320667%"
          y1="24.23172%"
          x2="47.8075615%"
          y2="67.6102295%"
          id="e"
        >
          <stop stopColor="#F0CD57" offset="0%" />
          <stop stopColor="#F39321" offset="100%" />
        </linearGradient>
        <path id="f" d="M0 24.4L29.8 24.4 31 0 0 6.4z" />
        <linearGradient
          x1="79.1321796%"
          y1="308.044016%"
          x2="68.5724763%"
          y2="209.410909%"
          id="h"
        >
          <stop stopColor="#D69545" offset="0%" />
          <stop stopColor="#CC6815" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M23.202 64.091c0-.9.3-1.8.9-2.4-2.1-.3-3.9-1.2-3.9-3.3 0-2.1 1.8-3 3.9-3.6-.9-.6-1.2-1.5-1.2-2.4.3-4.8 10.2-.6 10.2-.6V64.091c0 .3-9.6 4.5-9.9 0z"
          fill="url(#a)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M24.402 86.591c0-.6.3-1.2.6-1.5-1.5-.3-2.7-.9-2.7-2.4s1.2-2.1 2.7-2.4c-.6-.3-.9-.9-.9-1.5 0-3.3 6.9-.3 6.9-.3v8.4s-6.6 3-6.6-.3z"
          fill="url(#b)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M21.402 33.691c.3-.9 1.2-1.8 2.1-2.1-2.4-1.5-3.9-3.3-2.7-5.7 1.2-2.4 3.6-2.7 6-1.8-.6-.9-.6-2.1 0-3 2.4-5.1 11.4 4.5 11.4 4.5l-1.8 4.2-.3.6-2.1 4.8-.3.6-1.8 3.9c.3-.3-12.6-.6-10.5-6z"
          fill="url(#c)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M38.802 112.791s-16.8 7.2-25.9 0l2.7-4.8h19.9c.3 0 4.8.9 4.8.9l-1.5 3.9z"
          fill="#D6743D"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M70.702 77.291c2.4 1.5 8.7 3.9 6.3 9.9-.9 1.5-2.1 3-3.3 3.9-2.1 1.5-.3 2.1.9 1.8 1.2 0 15.9-3.3 9-15.6-1.2-2.4-6.9-7.2-12-6.3-5.1.9-2.1 5.7-.9 6.3z"
          fill="url(#d)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M31.002 80.591v4.8s-1.2 16.8-11.1 16.8c0 0-17.1 1.8-19.9-23.5 0 0-.9 50.5 38.5 32.5l1.5 8.4 9.9-12.9 16.8 1.8 6.3 9.3.9-49s30.7 4.2 36.1 4.8c0 0 10.8-64.1-29.5-66.8-53.2-6-49.3 37.6-49.3 37.6l-.3 26.2.1 10z"
          fill="url(#e)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M45.702 57.491c.3-9.3 2.1-12.3 4.2-12.3s4.2 7.8 4.2 17.7l-8.4-5.4z"
          fill="#EDEDBB"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M67.702 43.291c0 2.1 3.3 3.6 7.5 3.6s7.5-1.8 7.5-3.6-3.3-3.6-7.5-3.6-7.5 1.5-7.5 3.6zM90.802 42.691c-.3.9.9 2.1 2.7 2.7 1.8.3 3.3 0 3.6-1.2.3-1.2-1.2-2.4-2.7-2.7-1.7-.3-3.6 0-3.6 1.2zM60.502 46.291c0 .6.6 1.5 2.1 1.8 1.2.3 2.4 0 2.7-.6 0-.6-.6-1.5-2.1-1.8-1.5-.6-2.7-.3-2.7.6zM75.202 49.891c-.3.6.6 1.5 1.8 1.8 1.2.3 2.4 0 2.7-.6.3-.6-.6-1.5-1.8-1.8-1.2-.3-2.7 0-2.7.6z"
          fill="#D69545"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M55.102 77.291s-1.8 5.4-4.5 6.9c1.8 2.4 5.1 4.8 8.7-.6-1.8-1.8-3-3.9-3.9-6.3 0 0 5.7 12 11.7-.3 0 0-3 9.9-7.5 6.6-1.5 3.6-5.1 8.4-9.3.3-1.8.9-4.2-.6-6.9-6.9-.1.6 5.6 12.9 11.7.3z"
          fill="#D6711D"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M77.902 19.291c-.3-6.3-5.4-11.4-12-11.1-1.5 0-3 .3-4.2.9-4.2 1.8-6.9 6-7.2 10.5 0 3.9 1.8 7.5 5.1 9.6 6 3.3 13.5 1.2 16.8-4.8 0-.3.3-.3.3-.6.9-1.5 1.2-3 1.2-4.5z"
          fill="#D6743D"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M77.002 18.091c-.3-6-5.1-10.8-11.1-10.5-1.5 0-2.7.3-3.9.9-3.9 1.8-6.6 5.4-6.6 9.9-.3 3.6 1.8 7.2 4.8 9.3 5.7 3.3 12.6 1.2 15.9-4.5 0-.3.3-.3.3-.6.3-1.5.6-3 .6-4.5z"
          fill="#EDEDBB"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M61.402 20.491c0 2.4 1.8 4.2 4.2 4.2 2.4 0 4.2-1.8 4.2-4.2 0-2.4-1.8-4.2-4.2-4.2-2.1-.3-3.9 1.5-4.2 4.2 0-.3 0-.3 0 0z"
          fill="#9F522B"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M62.902 19.891c0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-1.2-.3-2.4.9-2.7 2.7.3-.3 0-.3 0 0z"
          fill="#532714"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M61.702 16.591c0 1.2.9 2.1 2.1 2.1 1.2 0 2.1-.9 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1.9-2.1 2.1z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M63.802 33.391c4.8 0 9.6-1.8 13.2-5.1-5.4 7.2-15.6 8.7-22.9 3.3-1.2-.9-2.4-2.1-3.3-3.3 3.4 3.3 8.2 5.1 13 5.1z"
          fill="#D69545"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M65.302 31.291c3.9 0 7.8-1.8 10.5-4.5-4.2 6-12.6 7.5-18.6 3.3-1.2-.9-2.1-1.8-3-2.7 3.3 2.7 7.2 4.2 11.1 3.9z"
          fill="#D69545"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M66.202 67.691c5.4 1.5 11.1 2.7 16.8 3.6 5.1.9 9.9 1.5 13.8 1.8 7.8.9 12.9.9 12.9.9s-4.8-.6-12.6-1.8c-3.9-.6-8.7-1.5-13.8-2.4-5.1-.9-10.8-2.4-16.5-3.9-5.4-1.5-10.8-3.6-15.6-6-2.1-1.2-4.2-2.4-6-3.9-1.8-1.5-3-3-4.2-5.1-.9-1.5-1.2-3.3-1.2-5.1v-.6-.6-.9c0-.3 0-.6.3-.9.3-.3.3-.6.3-.9.3-.9.6-1.5.9-2.4l.3-.9-.6.9c-.6.6-.9 1.5-1.2 2.4 0 .3-.3.6-.3.9 0 .3-.3.6-.3.9 0 .3-.3.6-.3 1.2v1.2c-.3 1.8 0 3.6.9 5.4.9 2.1 2.4 3.9 3.9 5.7 1.8 1.8 3.9 3.3 6.3 4.5 5.4 2.4 10.8 4.5 16.2 6z"
          fill="#D6743D"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <g transform="translate(.398 .309) translate(81.502 10.491)">
          <mask id="g" fill="#fff">
            <use xlinkHref="#f" />
          </mask>
          <g mask="url(#g)" fillRule="nonzero">
            <g transform="translate(.6 -2.845)">
              <path
                d="M0 11.945c.3 6 5.1 10.8 11.1 10.5 1.5 0 2.7-.3 3.9-.9 3.9-1.8 6.6-5.4 6.6-9.9 0-3.6-1.8-7.2-4.8-9.3-5.7-3.3-12.6-1.2-15.9 4.5 0 .3-.3.3-.3.6-.3 1.5-.6 3-.6 4.5z"
                fill="#D6743D"
              />
              <path
                d="M.3 10.745c0 5.7 5.1 10.5 10.8 10.2 1.5 0 2.7-.3 3.9-.9 3.9-1.8 6.3-5.4 6.6-9.6 0-3.6-1.8-6.9-4.8-9-5.4-3-12.3-1.2-15.3 4.2 0 .3-.3.6-.3.6-.6 1.5-.9 3-.9 4.5z"
                fill="#EDEDBB"
              />
              <circle fill="#9F522B" cx={11.8} cy={12.8452015} r={4.2} />
              <path
                d="M8.7 12.245c0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.5-1.2-2.7-2.7-2.7-1.1-.3-2.7.9-2.7 2.7z"
                fill="#532714"
              />
              <path
                d="M13.3 25.145c4.2.6 8.4-.6 12-3-5.4 5.7-14.4 6.3-20.5.9-.9-.9-1.8-2.1-2.7-3.3 3 3 6.9 5.1 11.2 5.4z"
                fill="#D69545"
              />
              <path
                d="M7.5 8.945c0 1.2.9 2.1 2.1 2.1 1.2 0 2.1-.9 2.1-2.1 0-1.2-.9-2.1-2.1-2.1-1.2 0-2.1.9-2.1 2.1z"
                fill="#FFF"
              />
              <path
                d="M14.2 23.045c3.6-.6 6.6-2.1 9-4.8-3.3 5.7-10.5 7.8-16.2 4.8-.9-.6-1.8-1.2-2.7-2.1 2.9 1.8 6.6 2.7 9.9 2.1z"
                fill="#D69545"
              />
            </g>
          </g>
        </g>
        <path
          d="M103.802 4.791c-3.9 8.1-12.6 12.9-21.7 11.4.3.1 15.7-3.9 21.7-11.4zM77.002 6.591c-3.6-1.8-18.6-9-24.7.9 1.2-3.6 4.2-6.3 8.1-6.9 6.1-1.8 12.7.6 16.6 6z"
          fill="#D69545"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
        <path
          d="M35.802 77.291c-2.4 1.5-8.7 3.9-6.3 9.9.9 1.5 1.8 2.7 3.3 3.9 2.1 1.2.3 2.1-.9 1.8-1.2 0-15.9-3-9-15.6 1.2-2.4 6.9-7.2 12-6.3 5.1.9 2.1 5.7.9 6.3z"
          fill="url(#h)"
          fillRule="nonzero"
          transform="translate(.398 .309)"
        />
      </g>
    </svg>
  )
}

export function TriangleSvg(props) {
  return (
    <svg viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-1013 -2616) translate(465.5 1721.946) translate(45.744 731.365) translate(274.949) translate(0 .028) translate(0 159.816) translate(228.395 5.254)"
        stroke="#90B3D4"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          strokeWidth={1.87218494}
          strokeLinecap="round"
          d="M0 17.7078518L0 0 11.8902965 8.53347425 3.12900892 15.3411278 3.12900892 11.2831911"
        />
        <ellipse
          strokeWidth={0.936092469}
          fill="#D8D8D8"
          cx={3.13342505}
          cy={7.98773694}
          rx={1}
          ry={1}
        />
      </g>
    </svg>
  )
}