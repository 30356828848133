import {
    Decentraland,
    PartnerBox1, PartnerBox2,
    PartnersBox,
    TrustedButton,
    TrustedContainer
} from "./TrustedBlock.styles";
import Sandbox from '../../assets/img/partners/Sandbox.webp'
import AxieInfinity from '../../assets/img/partners/logos/Axie Infinity (AXS).webp'
import Vee from '../../assets/img/partners/logos/vee.webp'
import Bitdao from '../../assets/img/partners/logos/bitdao.webp'
import Nansen from '../../assets/img/partners/logos/nansen.webp'
import Ripple from '../../assets/img/partners/logos/ripple.webp'
import Mises from '../../assets/img/partners/Mises.webp'
import Gala from '../../assets/img/partners/Gala.webp'
import Exodia from '../../assets/img/partners/Exodia.webp'
import Appics from '../../assets/img/partners/Appics.webp'
import {FlexBox} from "../../styles/components/Layout";
import { useLocation } from "react-router-dom";

export const TrustedBlock = () => {
    const location = useLocation();
    const faqPage = location.pathname === '/faq';

    return (
        <TrustedContainer>
            <PartnersBox>
                <TrustedButton disabled className={faqPage ? 'light' : ''}>{'Trusted by'}</TrustedButton>
                <FlexBox>
                    <PartnerBox1><img src={Mises}/></PartnerBox1>
                    <PartnerBox1><img src={Gala}/></PartnerBox1>
                    <PartnerBox1><Decentraland/></PartnerBox1>
                </FlexBox>
                <FlexBox>
                    <PartnerBox2><img src={Sandbox}/></PartnerBox2>
                    <PartnerBox2><img src={Exodia}/></PartnerBox2>
                    <PartnerBox2><img src={Appics}/></PartnerBox2>
                </FlexBox>
            </PartnersBox>
        </TrustedContainer>
    );

}