import Resizer from "react-image-file-resizer";
import {UploadLogo} from "../api/web3Requests";

export const optimizeImage = async (file) => {
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "WEBP",
                90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    return await resizeFile(file);
};


export const uploadImage = async (file, id) => {
    const optimizedFile = await optimizeImage(file);
    const data = { image: optimizedFile, logo_id: id };
    return await UploadLogo(data)
};