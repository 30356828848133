import { Typography, useMediaQuery, Box } from "@mui/material";
import { FAQMobSvg, FAQSvg } from "../../assets/svg/faq";
import { SectionContainer } from "../../styles/components/Layout";
import { MainHeading } from "../../styles/components/typography";
import { FAQTitleContainer } from "./FAQ.style";
import React from "react";

const FAQTitle = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <SectionContainer>
      <FAQTitleContainer>
        <Box>
          <Typography sx={{ fontSize: "2.5rem" }}>FAQ</Typography>
          <MainHeading variant="h1" color={"#00213a"}>
            Your attention is <b>important to us</b> and we try to answer any
            question
          </MainHeading>
        </Box>
        {isMobile ? <FAQMobSvg style={{ maxWidth: "15rem" }}/> : <FAQSvg style={{ maxWidth: "30rem" }} />}
      </FAQTitleContainer>
    </SectionContainer>
  );
};

export default FAQTitle;
