//Core
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//Styles
import { Box, Drawer, List, ListItem, Stack, Toolbar, Typography } from "@mui/material";
import { FlexBox, SectionContainer } from "../../../styles/components/Layout";
import {
  AttentionSvgBox, AttentionWhiteSvgBox,
  BurgerButton,
  CtaButton,
  CustomTooltip,
  HeaderContainer,
  HeaderNavBox,
  HeaderNavItem,
  LearnMoreText, OoopsText,
  TooltipMainTitle, WhyBox,
  WhyText
} from "./Header.styles";
import ConnectNFTButton from "./ConnectNFTButton";
//Images
import { Logo } from "../../../assets/svg/logo";
import { AttentionUserSvg } from "../../../assets/svg/icons";
import { TooltipUserSvg, TooltipWhySvg } from "../../../assets/svg/tooltipUserSvg";
//Components
import MobileMenu from "./MobileMenu";
//Hooks
import { useAuth } from "./useAuth";
import { useHeader } from "./useHeader";
//Utils
import { dashboardLink } from "../../../utils/helpers";


export const Header = () => {
  const location = useLocation();
  const {
    headerFloat,
    menuOpen,
    toggleMobileMenu,
    attentionUser,
    walletChecking,
    pages,
    login
  } = useHeader();
  const { isAuthenticated } = useAuth0();
  useAuth();
  const navigate = useNavigate();

  const faqPage = location.pathname === "/faq";
  const nftPage = location.pathname === "/membership";

  const AttentionTooltip = (white = false) => {
    return (
      <FlexBox>
        <Box>
          <TooltipUserSvg />
        </Box>
        <Stack ml={"3rem"}>
          <TooltipMainTitle>{`THERE IS NO WEBM NFT DETECTED`}</TooltipMainTitle>
          <OoopsText><span>{"OOPS.. "}</span>{"There is no Webm NFT in this wallet"}</OoopsText>
          <WhyBox>
            <TooltipWhySvg />
            <LearnMoreText>
              {`Wrong wallet. Click on `}
              <span onClick={() => window.location.replace(`${dashboardLink}/settings`)}>{"User account"}</span>
              {` to fix it!`}
            </LearnMoreText>
          </WhyBox>
          <LearnMoreText>
            {`OR you don't own Webm NFT - go `}
            <span onClick={() => navigate("/signup")}>{"HERE"}</span>
            {` to learn more and sign up for minting the NFT.`}
          </LearnMoreText>
        </Stack>
      </FlexBox>
    );
  };
  const buttonsMenu = (page) => {
    return (
      <>
        {page.link?.startsWith("https") ? (
          page.title === "User account" ? (
            <CustomTooltip
              title={attentionUser ? <AttentionTooltip /> : ""}
              arrow
              placement={"bottom-end"}
            >
              <Box position={"relative"}>
                <a href={page.link} target={page.title}>
                  <Typography variant="body2">{page.title}</Typography>
                </a>
                <AttentionSvgBox visibility={attentionUser}>
                  <AttentionUserSvg />
                </AttentionSvgBox>
              </Box>
            </CustomTooltip>
          ) : (
            <a href={page.link} target={page.title}>
              <Typography variant="body2">{page.title}</Typography>
            </a>
          )
        ) : (
          <Link to={page.link}>
            <Typography variant="body2">{page.title}</Typography>
          </Link>
        )}
      </>
    );
  };

  const mobileProps = { pages, toggleMobileMenu, login, buttonsMenu };

  return (
    <>
      <HeaderContainer
        elevation={0}
        sx={{
          backgroundColor:
            headerFloat || faqPage
              ? "transparent"
              : nftPage
                ? "#00213A"
                : "#002541"
        }}
        position={headerFloat ? "fixed" : "relative"}
      >
        <Toolbar>
          <SectionContainer sx={headerFloat ? { maxWidth: "160rem" } : {}}>
            <HeaderNavBox
              className={`${headerFloat ? "float" : ""} ${
                location.pathname === "/signup"
                  ? "signupPage"
                  : faqPage
                    ? "white-theme"
                    : ""
              } `}
            >
              <Link to="/">
                <Logo />
              </Link>

              <List sx={{ display: "flex" }}>
                {pages?.map((page, i) => (
                  <HeaderNavItem
                    key={page.title + i}
                    className={location.pathname === page.link ? "active" : ""}
                  >
                    {buttonsMenu(page)}
                  </HeaderNavItem>
                ))}
                <ListItem>
                  <CustomTooltip arrow
                                 title={(attentionUser && headerFloat) ? <AttentionTooltip /> : ""}
                                 placement={"bottom-end"}
                  >
                    <CtaButton startIcon={isAuthenticated &&
                      <AttentionWhiteSvgBox visibility={attentionUser}>
                        <AttentionUserSvg />
                      </AttentionWhiteSvgBox>}
                    >
                      {isAuthenticated ? (
                        <a href={`${dashboardLink}/settings`} target={"_self"}>{"User account"}</a>
                      ) : (
                        <Link to={"/signup"}>Get early access</Link>
                      )}
                    </CtaButton>
                  </CustomTooltip>
                </ListItem>
                {headerFloat && (
                  <ListItem>
                    {!isAuthenticated && (
                      <ConnectNFTButton
                        float={headerFloat}
                        dark
                        title={"Login"}
                        loading={walletChecking}
                        onClick={login}
                      />
                    )}
                  </ListItem>
                )}
              </List>
              <BurgerButton
                onClick={toggleMobileMenu}
                className={`${headerFloat && "float "} ${faqPage && "dark"}`}
              >
                <b />
                <b />
                <b />
              </BurgerButton>
            </HeaderNavBox>
          </SectionContainer>
          {!headerFloat && !isAuthenticated && (
            <ConnectNFTButton
              dark={faqPage}
              title={"Login"}
              loading={walletChecking}
              onClick={login}
            />
          )}
        </Toolbar>
      </HeaderContainer>
      {headerFloat && <Box sx={{ height: "64px" }}></Box>}
      <Drawer open={menuOpen} anchor="right">
        <MobileMenu {...mobileProps} />
      </Drawer>
    </>
  );
};
