import HeroNft from "../sections/nft/hero/HeroNft";
import { Box } from "@mui/material";
import { FaqNft } from "../sections/nft/faq/FaqNft";
import CommunityNft from "../sections/nft/community/CommunityNft";
import MembershipNFT from "../sections/nft/membership/MembershipNFT";
import { TrustedBlock } from "../sections/trustedBlock/TrustedBlock";
import HowItWorksNft from "../sections/nft/howItWorks/HowItWorksNft";
import { StepsNft } from "../sections/nft/steps/StepsNft";

const Nft = () => {
  return (
    <Box>
      <HeroNft />
      <CommunityNft />
      <MembershipNFT />
      <HowItWorksNft />
      <StepsNft />
      <FaqNft />
      <TrustedBlock />
    </Box>
  );
};

export default Nft;