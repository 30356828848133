import {useState} from "react";
import * as yup from 'yup';
import {useNavigate} from "react-router-dom";
import {uploadImage} from "../../utils/optimizeImage";
import {useAuth0} from "@auth0/auth0-react";
import {Send404Form} from "../../api/web3Requests";


export const useSupportForm = (setIs404) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email_to_return: '',
        issue_title: '',
        issue_desc: '',
        issue_screenshots_links: [],
    })
    const [loadingImage, setLoadingImage] = useState({})
    const [loadingForm, setLoadingForm] = useState(false)
    //Status of request 'sent_support_form
    const [status, setStatus] = useState(false)

    const { user } = useAuth0();
    const navigate = useNavigate()

    const validationSchema = yup.object({
        first_name: yup.string().min(1).required('Name is required'),
        email_to_return: yup.string().email('Email not valid').required('Email is required'),
        issue_title: yup.string().min(1).required('Title is required'),
        issue_desc: yup.string().min(1).required('Description is required')
    })

    const uploadFile = async (e, i) => {
        setLoadingImage({...loadingImage, [e.target.id]: true})
        const file = await uploadImage(e.target.files?.[0], e.target.id)
        setLoadingImage({...loadingImage, [e.target.id]: false})
        console.log(file)
        const newImages = [...formData.issue_screenshots_links]
        newImages.splice(i, 1, file.image)
        setFormData({...formData, issue_screenshots_links: newImages})
    }

    const deleteFile = (i) => {
        const newImages = [...formData.issue_screenshots_links]
        newImages.splice(i, 1)
        setFormData({...formData, issue_screenshots_links: newImages})
    }

    const sendForm = async (values) => {
        setLoadingForm(true)
        const response = await Send404Form({
            ...values,
            issue_screenshots_links: formData.issue_screenshots_links,
            user_id: user.sub
        })
        setLoadingForm(false)
        if (response?.sent) setStatus(true)
        if (setIs404) {
            setTimeout(() => {
                setIs404(false)
                navigate('/')
            }, 3000)
        }

    }

    return {
        formData,
        status,
        setStatus,
        uploadFile,
        deleteFile,
        loadingImage,
        loadingForm,
        sendForm,
        validationSchema,
    }
}