import { ArrowForwardIos } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FlexBox, SectionContainer } from "../../styles/components/Layout";
import { FAQContentBackground, FAQItem } from "./FAQ.style";
import { FAQdata } from "./FAQData";
import { useFAQ } from "./useFAQ";

const FAQContent = () => {
  const { active, handleClick } = useFAQ();

  return (
    <FAQContentBackground elevation={0}>
      <SectionContainer>
        <Typography variant="h4">About us</Typography>
        <Grid
          container
          rowSpacing={"2.4rem"}
          columnSpacing={"6.4rem"}
          marginBottom={"8.5rem"}
        >
          {FAQdata.aboutUs.map((el, ind) => (
            <Grid item xs={12} md={6} key={el.q + ind}>
              <FAQItem className={active.aboutUs !== ind ? "hidden" : ""}>
                <FlexBox onClick={() => handleClick("aboutUs", ind)}>
                  <Typography variant="h5">{el.q}</Typography>
                  <ArrowForwardIos />
                </FlexBox>
                <Box className="content">
                  <Divider />
                  <Typography>
                    {el.a}
                    <a href={el.l} target={"_blank"}>
                      {el.l}
                    </a>
                  </Typography>
                </Box>
              </FAQItem>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h4">Our benefits</Typography>
        <Grid container rowSpacing={"2.4rem"} columnSpacing={"6.4rem"}>
          {FAQdata.benefits.map((el, ind) => (
            <Grid item xs={12} md={6} key={el.q + ind}>
              <FAQItem className={active.benefits !== ind ? "hidden" : ""}>
                <FlexBox onClick={() => handleClick("benefits", ind)}>
                  <Typography variant="h5">{el.q}</Typography>
                  <ArrowForwardIos />
                </FlexBox>
                <Box className="content">
                  <Divider />
                  <Typography>{el.a}</Typography>
                </Box>
              </FAQItem>
            </Grid>
          ))}
        </Grid>
      </SectionContainer>
    </FAQContentBackground>
  );
};

export default FAQContent;
