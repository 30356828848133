import { Close } from "@mui/icons-material";
import { Button, IconButton, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/svg/logo";
import { SectionContainer } from "../../../styles/components/Layout";
import { MobileMenuBody, MobileMenuContainer, MobileMenuHead } from "./Header.styles";
import { useAuth0 } from "@auth0/auth0-react";
import ConnectNFTButton from "./ConnectNFTButton";
import React from "react";
import { isMobile } from "react-device-detect";

const MobileMenu = (props) => {
    const { isAuthenticated } = useAuth0();
    const { pages, toggleMobileMenu, login, buttonsMenu } = props;

    return (
        <MobileMenuContainer>
            <SectionContainer>
                <MobileMenuHead>
                    <Logo />
                    <IconButton onClick={toggleMobileMenu} disableRipple>
                        <Close />
                    </IconButton>
                </MobileMenuHead>
                <MobileMenuBody>
                    {pages.map((page) => {
                        // console.log(page)
                        return (
                            <ListItem
                                onClick={async () => {
                                    if (page.title === "Login") {
                                        await login();
                                        toggleMobileMenu();
                                    } else {
                                        toggleMobileMenu();
                                    }
                                }}
                                key={page.title}
                            >
                                {buttonsMenu(page)}
                            </ListItem>
                        );
                    })}
                    {!isAuthenticated && <ConnectNFTButton dark title={"Login"} onClick={async () => login()}/>}
                    {!isAuthenticated && (
                        <ListItem className={"access"}>
                            <Button onClick={toggleMobileMenu} disableRipple>
                                <Link to={"/signup"}> Get early access</Link>
                            </Button>
                        </ListItem>
                    )}
                </MobileMenuBody>
            </SectionContainer>
        </MobileMenuContainer>
    );
};

export default MobileMenu;