import { Box, Paper, styled } from "@mui/material";
import theme from "../../styles/theme";

export const KeyPointsBackground = styled(Paper)`
  position: absolute;
  width: 100vw;
  height: calc(100% + 20rem);
  background-color: #f4ede4;
  z-index: 0;

  ${theme.breakpoints.down("sm")} {
    height: calc(100% + 13rem);
  }
`;

export const KeyPointContainer = styled(Box)`
  display: flex;
  margin-bottom: 20rem;
  column-gap: 12rem;

  ${theme.breakpoints.down("lg")} {
    column-gap: 4rem;
  }

  ${theme.breakpoints.down("md")} {
    flex-direction: column !important;
    width: 80%;
    margin: 0 auto 15rem;
  }

  ${theme.breakpoints.down("sm")} {
    flex-direction: column !important;
    width: 80%;
    margin: 0 auto 12rem;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;

    img {
      border-radius: 30px 0 30px 30px;

      ${theme.breakpoints.down("sm")} {
        border-radius: 15px 0 15px 15px;
      }
    }
  }

  > div:first-of-type {
    /* width: 50rem; */
    flex: 1 0 50rem;
    ${theme.breakpoints.down("md")} {
      flex: 1 0 auto;
      margin-bottom: 2rem;
    }
  }

  h2 {
    margin-bottom: 3.1rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .early-access {
    font-size: 1.82rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #1b418e;
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: #1b418e;
    }

    svg {
      height: 1rem;
      margin-left: 0.2rem;
    }

    span {
      letter-spacing: -1.5px;
      font-size: 1.1rem;
    }
  }

  img {
    width: 100%;
    border-radius: 0 30px 30px 30px;
    max-width: 85rem;
    box-shadow: -1px -11px 45px -6px rgba(0, 0, 0, 0.1);

    ${theme.breakpoints.down("sm")} {
      border-radius: 0 15px 15px 15px;
    }
  }
`;
