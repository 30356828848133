import {dashboardLink} from "../../../utils/helpers";

export const pageData = [
  { title: "Homepage", link: "/" },
  { title: "Membership", link: '/membership'},
  { title: "Our community", link: "https://discord.com/invite/7NFVRymVZf" },
  { title: "FAQ", link: "/faq" },
  { title: "Login" },
  // { title: "Logout" },
  {
    title: "User account", link: dashboardLink
  },
  { title: "Sign up", link: "/signup" },
];

export const sectionsAvailable = {
  notAuthorized: ["Homepage", "Membership", "Our community", "FAQ", 'Sign up'],
  connected: ["Homepage", "Membership", "Our community", "FAQ", "User account", "Logout"],
};