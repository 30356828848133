import {
  AppBar,
  Box,
  Button,
  List,
  ListItem,
  Tooltip,
  styled,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { FlexBox } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";

export const HeaderContainer = styled(AppBar)`
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  z-index: 1100;
  ${theme.breakpoints.down("sm")} {
    padding-top: 0.5rem;
  }
  > div > div {
    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const HeaderNavBox = styled(FlexBox)`
  justify-content: space-between;
  
  > a{
    display: flex;
    
  }
  > a > svg {
    width: 13rem;
    ${theme.breakpoints.down("sm")} {
      width: 9rem;
      margin-left: 0.5rem;
    }
  }
  ul {
    ${theme.breakpoints.down("br768")} {
      display: none;
    }
  }
  li:last-of-type {
    display: none;
  }
  &.float {
    margin-top: 0.7rem;
    border-radius: 5.4rem;
    box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    height: 9.4rem;
    animation: headerShow 0.3s ease-in-out;
    > a > svg {
      margin-left: 7.7rem;

      ${theme.breakpoints.down("br768")} {
        margin-left: 4rem;      }
      path {
        fill: #00213a;
      }
    }
    ul {
      li {
        padding: 0;
        margin-right: 3rem;
        a,
        p {
          color: #00213a;
        }
        &:nth-of-type(5) {
          display: none;
        }
        &:last-of-type {
          display: block;
        }
      }
    }
    &.signupPage {
      ul {
        li {
          &:nth-of-type(4) {
            display: inherit;
            margin-right: 5rem;
          }
          &:last-of-type {
            display: none;
          }
        }
      }
    }
    @keyframes headerShow {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      70% {
        transform: translateY(20%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    ${theme.breakpoints.down("sm")} {
      height: 7.5rem;
      svg {
        margin-left: 3rem;
      }
    }
    @media only screen and (max-height: 500px) {
      height: 7.5rem;
      margin-top: -3rem;
      svg {
        margin-left: 3rem;
      }
    }

    //&.nftPage {
    //  background-color: #00213a;
    //
    //  a > svg{
    //    margin-left: 4.5rem;
    //    > path {
    //      fill: #fff;
    //      opacity: .5;
    //    }
    //  } 
    //}
  }

  &.white-theme {
    svg {
      path {
        fill: #00213a;
      }
    }
    a,
    p {
      color: #00213a;
    }
  }
`;

export const CtaButton = styled(Button)`
  padding: 1.3rem 2rem;
  border-radius: 4rem;
  background-color: #195afe;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: -0.21px;
  text-transform: none;
  white-space: nowrap;
  &:hover {
    background-color: #195afe;
  }
  a {
    color: #fff !important;
    text-decoration: none;
  }
`;

export const HeaderNavItem = styled(ListItem)`
  white-space: nowrap;
  /* margin-left: 3.2rem; */
  a {
    text-decoration: none;
    color: #fff;
  }
  &.active {
    opacity: 0.5;
  }
  p {
    cursor: pointer;
  }
`;

export const BurgerButton = styled(Box)`
  display: none;
  height: 27px;
  width: 25px;
  position: relative;
  cursor: pointer;
  ${theme.breakpoints.down("br768")} {
    display: block;
  }
  b {
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: #fff;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
    }
    &:nth-of-type(3) {
      top: 100%;
    }
  }
  ${theme.breakpoints.down("sm")} {
    width: 15px;
    height: 15px;
    margin-right: 1rem;
    b {
      width: 20px;
    }
  }
  &.dark,
  &.float {
    b {
      background-color: #00213a;
    }
  }
  &.float {
    margin-right: 5rem;
  }
`;

export const MobileMenuContainer = styled(Box)`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MobileMenuHead = styled(FlexBox)`
  width: 95%;
  margin: 2.5rem auto;
  justify-content: space-between;
  > svg {
    width: 13rem;
    path {
      fill: #00213a;
    }
  }
  > button > svg {
    font-size: 4rem;
    transform: translateX(20px);
    path {
      fill: #00213a;
    }
  }
`;

export const MobileMenuBody = styled(List)`
  width: 95%;
  margin: 5vh auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  a {
    text-decoration: none;
    color: #000;
    width: 100%;
    button {
      width: 100%;
    }
  }
  li {
    font-size: 3rem;
    font-weight: 500;

    p {
      font-size: 3rem;
    }
    margin-bottom: 3vh;
    padding-left: 0;
    &.access {
      width: 101%;
      margin-top: auto;
    }
    button {
      width: 100%;
      background-color: #195afe;
      height: 6rem;
      &:hover {
        background-color: #195afe;
      }
      a {
        color: #fff;
        font-size: 2rem;
      }
    }
  }
  button {
    display: flex !important;
  }
`;

export const AttentionSvgBox = styled(Box)`
  position: absolute;
  top: -2.2rem;
  right: -2.2rem;
  display: ${(props) => (props.visibility ? "block" : "none")};
  width: 1.5rem;
  height: 1.3rem;
  svg {
    width: 100%;
    height: 100%;
    fill: #FF7979;
  }
`;

export const AttentionWhiteSvgBox = styled(Box)`
  width: 2.3rem;
  height: 2rem;
  svg {
    width: 100%;
    height: 100%;
    fill: #FFFFFF;
  }
`

//Tooltip User
export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}/>
))(({ theme }) => ({
    paddingTop: '1.7rem !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    maxWidth: 540,
    width: "fit-content",
    fontSize: "1.4rem",
    borderRadius: "1.4rem",
    padding: "4rem 7rem 4rem 3rem",
    boxShadow: '0 5px 17px 0 rgb(0 0 0 / 17%)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },
}));

export const TooltipMainTitle = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #4a5767;
  width: fit-content;
  text-transform: uppercase;
`;

export const OoopsText = styled(Typography)`
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  color: #000;
  font-weight: bold;
  margin-top: 1.36rem;
  span {
    font-size: 1.54rem;
    color: rgba(0, 0, 0, 0.4);
    line-height: 1.17;
    letter-spacing: 0.17px;
    text-transform: uppercase;
    margin-left: 0.5rem;
  }
`

export const WhyBox = styled(FlexBox)`
  align-items: center;
  margin: 1.5rem 0;
  width: 77%;
  svg {
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
`

export const LearnMoreText = styled(Typography)`
  color: #000000;
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  span {
    cursor: pointer;
    text-decoration: underline;
    color: #4d86b0;
  }
`;

export const WhyText = styled(Typography)`
  font-size: 1.54rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: 0.17px;
  color: rgba(0, 0, 0, 0.4);
  margin: 2rem 0 1.5rem;
`;
