//Core
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";

//Components
import FAQ from "../../pages/FAQ";
import HomePage from "../../pages/Homepage";
import SignUp from "../signup/SignUp";
import ThankYou from "../thankYou/ThankYou";
import ScrollToTop from "./ScrollToTop";
import { Page404 } from "../404/Page404";
import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";
//Styles
import { LoaderContainer } from "./Main.styles";
import Nft from "../../pages/NFT";

export const Main = () => {
  const [allChecked, setAllChecked] = useState(false);
  const { isLoading } = useAuth0();
  const [is404, setIs404] = useState(false);

  return (
    <>
      <LoaderContainer sx={{ display: isLoading ? "flex" : "none" }}>
        <CircularProgress />
      </LoaderContainer>
      <div
        style={{
          height: isLoading ? "100vh" : "fit-content",
          overflow: "hidden",
        }}
      >
        {!is404 && <Header />}
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/signup">
              <Route index element={<SignUp />} />
              <Route path="done" element={<ThankYou />} />
            </Route>
            <Route path='/membership' element={<Nft/>}/>
            <Route path="*" element={<Page404 setIs404={setIs404} />} />
            {/*<Route path="*" element={<Navigate to={""} replace />} />*/}
          </Routes>
        </ScrollToTop>
        {!is404 && <Footer />}
      </div>
    </>
  );
};
