import {SocialMediaIcons} from "../../assets/svg/socialMedia";
import {MediaButton, SocialMediaContainer} from "./SocialMedia.styles";

export const SocialMedia = () => {
    return (
        <SocialMediaContainer>
            <MediaButton startIcon={SocialMediaIcons.discord} onClick={() => window.open('https://discord.gg/7NFVRymVZf')}>
                {'Discord'}
            </MediaButton>
            <MediaButton startIcon={SocialMediaIcons.twitter} onClick={() => window.open('https://twitter.com/_WEB3M_')}>
                {'Twitter'}
            </MediaButton>
            <MediaButton startIcon={SocialMediaIcons.linkedin} onClick={() => window.open('https://il.linkedin.com/company/web3-m')}>
                {'Linkedin'}
            </MediaButton>
            <MediaButton startIcon={SocialMediaIcons.tiktok} onClick={() => window.open('https://www.tiktok.com/@web3m')}>
                {'Tiktok'}
            </MediaButton>
        </SocialMediaContainer>
    )
}