import { FlexBox, NFTSectionContainer, SectionContainer } from "../../../styles/components/Layout";
import { MembershipCTA, MembershipNftBg, MembershipNftCard, MembershipSvgBox, PlusIcon } from "./MembershipNft.styles";
import { Typography } from "@mui/material";
import { PlatformMobileSvg, PlatformSvg } from "../../../assets/svg/platform";
import MembershipImg from '../../../assets/img/nft/Membership.webp'
import PlatformImg from '../../../assets/img/nft/PlatformMembershipMob.webp'
import MembershipBgWebm from '../../../assets/img/nft/MembershipBg.webp'
import PlatformBgWebm from '../../../assets/img/nft/PlatformBg.webp'
import { PlusSvg } from "../../../assets/svg/icons";
import { CTAButtonNFT } from "../hero/HeroNft.styles";
import { RabbitLogo } from "../../../assets/svg/logo";
import { ApplyForNFTMask, ApplyForNFTMaskMobile, MembershipNftCardTop } from "../../../assets/svg/decorations";
import { MembershipBg } from "../../../assets/svg/MembershipBg";
import { useNavigate } from "react-router-dom";

const MembershipNft = () => {
  const navigate = useNavigate()
  return (
    <MembershipNftBg>
      <NFTSectionContainer>
        <FlexBox>
          <MembershipNftCard>
            {/*<MembershipSvgBox className={'mobileOnly'}>*/}
            {/*  /!*<MembershipBg/>*!/*/}
            {/*  <img src={PlatformBgWebm} alt={'bg'}/>*/}
            {/*</MembershipSvgBox>*/}
            <Typography variant="h3">Top advertising platform</Typography>
            <Typography>
              With Webm, marketers enjoy smart targeting, audience analytics,
              and a media planning dashboard for all your campaigns. <br />{" "}
              <br /> Advertise your project across thousands of popular
              exchanges, Web3 blogs, and news outlets.
            </Typography>
            <PlatformSvg className={'desktopOnly'}/>
            {/*<PlatformMobileSvg className={'mobileOnly'}/>*/}
            <img src={PlatformImg} alt={'Platform'} className={'mobileOnly'}/>
          </MembershipNftCard>
          <PlusIcon><PlusSvg/></PlusIcon>
          <MembershipNftCard>
            <MembershipNftCardTop className='mobCardTop mobileOnly'/>
            {/*<MembershipSvgBox className={'mobileOnly'}>*/}
            {/*  /!*<MembershipBg/>*!/*/}
            {/*  <img src={MembershipBgWebm} alt={'bg'}/>*/}
            {/*</MembershipSvgBox>*/}
            <Typography variant="h3">Membership <br/> NFT</Typography>
            <Typography>
              Webm users are also owners in the platform through an NFT. Enjoy
              access to a Web3 marketing community. Plus, receive kickbacks from
              the total ad revenue on Webm. <br /> <br /> As the platform
              succeeds, so do you.
            </Typography>
            <img src={MembershipImg}  alt='ZAI nft image'/>
          </MembershipNftCard>
        </FlexBox>
        <MembershipCTA>
          <ApplyForNFTMask className={'desktopOnly'}/>
          <ApplyForNFTMaskMobile className={'mobileOnly'}/>
        <CTAButtonNFT startIcon={<RabbitLogo/>} onClick={() => navigate('/signup')}>Apply for early access</CTAButtonNFT>
        </MembershipCTA>
      </NFTSectionContainer>

    </MembershipNftBg>
  );
};

export default MembershipNft;
