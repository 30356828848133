import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { sendFormData } from "./SignUpHelpers";

export const useSignUpForm = () => {
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  });
  const [errors, setErrors] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    company: yup.string().required(),
  });

  const handleType = async (e) => {
    setInput((input) => ({ ...input, [e.target.name]: e.target.value }));
  };

  const handleSendForm = async () => {
    try {
      const valid = await schema.validate(input);
      setErrors([]);
      await sendFormData(input);
      setInput({ firstName: "", lastName: "", email: "", company: "" });
      navigate("/signup/done", { state: "sent" });
      // setSent(true);
    } catch (err) {
      setErrors(err.errors);
    }
  };

  useEffect(() => {
    const validateInput = async () => {
      try {
        await schema.validate(input);
        if (!isValid) setIsValid(true);
      } catch (err) {
        if (isValid) setIsValid(false);
      }
    };

    validateInput();
  }, [input]);

  return { input, handleType, errors, handleSendForm, isValid };
};
