export const FAQdata = {
  aboutUs: [
    {
      q: "What is Web3m?",
      a: "The best platform for Web3 brands that want to advertise themselves.",
    },
    {
      q: "What is our mission?",
      a: "To make it easy and affordable for web3 companies to advertise and reach their target audiences.",
    },
    {
      q: "What is our vision?",
      a: "To become the leading platform for web3 advertising and to help build the web3 ecosystem.",
    },
    {
      q: "How can I learn more about Web3m?",
      a: "You can join our community on discord, telegram, or email and we will be happy to answer any of your questions.",
    },
    {
      q: "How to get early access to Web3m?",
      a: `The early access is granted currently only for our NFT holders, we will open it to the public in 2023, but the good news is that you can get one for yourself now, learn how here - `,
      l: 'https://web3m.io'
    },
  ],
  benefits: [
    {
      q: "What companies can promote using Web3m?",
      a: "The platform is a perfect fit solution for companies within the crypto, web3, metaverse, and NFT sector.",
    },
    {
      q: "What's Web3m's approach to communities?",
      a: "We're all about building and being part of communities. We believe that's the best way to learn and grow. We also have a dedicated team that helps our clients with their community building efforts.",
    },
    {
      q: "Why are we the best at what we do?",
      a: "We don't take sides, instead of embracing one channel that can keep you locked we integrate every good channel that emerges to offer our clients the best diversity of channels that will perform best without sacrificing the quality or results.",
    },
    {
      q: "I don't know if my product is a good fit, what should I do?",
      a: "If you're not sure if your product is a good fit, reach out to us on discord, telegram, or email and we can help you figure it out.",
    },
  ],
};
