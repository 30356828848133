export function FAQSvg(props) {
  return (
    <svg
      viewBox="0 0 304.0 214.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M2371 0v1732H0V0h2371z" />
        </clipPath>
        <filter
          id="b"
          x="-14.4206701%"
          y="-10.5757932%"
          filterUnits="userSpaceOnUse"
          width="121.919419%"
          height="122.326675%"
        >
          <feGaussianBlur
            stdDeviation={3}
            in="SourceAlpha"
            result={1.2201094945561182e22}
          />
          <feOffset
            dy={7}
            in={1.2201094945561182e22}
            result={1.0148115571171015e23}
          />
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1964307664991259 0"
            in={1.0148115571171015e23}
          />
        </filter>
        <clipPath id="c">
          <path d="M111.417 0c10.611 0 19.21 8.598 19.21 19.21v26.894h11.525c10.612 0 19.21 8.598 19.21 19.21v81.341c0 10.266-12.41 15.406-19.678 8.145l-16.49-16.49h-59.88c-10.612 0-19.21-8.598-19.21-19.21v-11.525h-9.936l-16.49 16.49C12.41 131.325 0 126.185 0 115.92V19.21C0 8.598 8.598 0 19.21 0h92.207z" />
        </clipPath>
        <clipPath id="d">
          <path d="M111.417 0c10.611 0 19.21 8.598 19.21 19.21v26.894h11.525c10.612 0 19.21 8.598 19.21 19.21v81.341c0 10.266-12.41 15.406-19.678 8.145l-16.49-16.49h-59.88c-10.612 0-19.21-8.598-19.21-19.21v-11.525h-9.936l-16.49 16.49C12.41 131.325 0 126.185 0 115.92V19.21C0 8.598 8.598 0 19.21 0h92.207zm30.735 53.787h-11.525v34.578c0 10.612-8.599 19.21-19.21 19.21h-57.63V119.1c0 6.37 5.164 11.526 11.526 11.526h61.472a3.87 3.87 0 012.72 1.121l17.611 17.62c2.42 2.42 6.562.707 6.562-2.713V65.313c0-6.37-5.163-11.526-11.526-11.526zM111.417 7.684H19.21c-6.362 0-11.526 5.156-11.526 11.526v96.71c0 3.419 4.134 5.132 6.562 2.712l17.612-17.62a3.87 3.87 0 012.72-1.121h76.839c6.362 0 11.526-5.156 11.526-11.526V19.21c0-6.37-5.164-11.526-11.526-11.526zM96.049 69.155a3.843 3.843 0 013.842 3.842 3.843 3.843 0 01-3.842 3.842H34.578a3.843 3.843 0 01-3.842-3.842 3.843 3.843 0 013.842-3.842h61.471zM57.629 38.42a3.843 3.843 0 013.842 3.842 3.843 3.843 0 01-3.842 3.842H34.578a3.843 3.843 0 01-3.842-3.842 3.843 3.843 0 013.842-3.842h23.051z" />
        </clipPath>
        <clipPath id="e">
          <path d="M104.731 0c39.241.374 58.862 1.842 58.862 4.404 0 3.842-6.437 146.767-11.136 146.767-4.7 0-157.031-14.966-152.351-26.747 3.12-7.854 8.747-26.083 16.882-54.688h87.45l.293-15.441V0z" />
        </clipPath>
        <clipPath id="f">
          <path d="M74.594 0a9.096 9.096 0 019.097 9.097v36.387a9.096 9.096 0 01-9.097 9.097H17.128l-7.81 7.809C5.878 65.829 0 63.394 0 58.533V9.097A9.096 9.096 0 019.097 0h65.497zm0 3.639H9.097a5.458 5.458 0 00-5.458 5.458v49.436c0 1.62 1.957 2.43 3.107 1.284l8.34-8.343a1.832 1.832 0 011.288-.531h58.22a5.458 5.458 0 005.459-5.459V9.097a5.458 5.458 0 00-5.459-5.458zm-18.193 29.11a1.82 1.82 0 010 3.639H27.29a1.82 1.82 0 010-3.64H56.4zM38.207 18.194a1.82 1.82 0 010 3.639H27.29a1.82 1.82 0 010-3.64h10.916z" />
        </clipPath>
        <clipPath id="g">
          <path d="M51.284 0c3.454 0 6.254 2.8 6.254 6.254v25.017c0 3.454-2.8 6.254-6.254 6.254H11.775l-5.368 5.368C4.04 45.257 0 43.583 0 40.241V6.254C0 2.8 2.8 0 6.254 0h45.03zm0 2.502H6.254a3.752 3.752 0 00-3.752 3.752v33.987c0 1.114 1.346 1.671 2.136.883l5.734-5.736a1.26 1.26 0 01.885-.365h40.027a3.752 3.752 0 003.752-3.752V6.254a3.752 3.752 0 00-3.752-3.752zM38.775 22.515a1.251 1.251 0 010 2.501H18.762a1.251 1.251 0 010-2.501h20.013zM26.267 12.508a1.251 1.251 0 010 2.502h-7.505a1.251 1.251 0 010-2.502h7.505z" />
        </clipPath>
        <clipPath id="h">
          <path d="M38.463 0a4.69 4.69 0 014.69 4.69v18.763a4.69 4.69 0 01-4.69 4.69H8.83L4.805 32.17C3.03 33.943 0 32.688 0 30.18V4.691A4.69 4.69 0 014.69 0h33.773zm0 1.876H4.69a2.814 2.814 0 00-2.815 2.815v25.49c0 .835 1.01 1.253 1.603.662l4.3-4.302a.945.945 0 01.664-.274h30.02a2.814 2.814 0 002.814-2.814V4.69a2.814 2.814 0 00-2.814-2.815zm-9.381 15.01a.938.938 0 010 1.876h-15.01a.938.938 0 010-1.876h15.01zM19.7 9.381a.938.938 0 010 1.876h-5.628a.938.938 0 010-1.876H19.7z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-1568 -162)">
        <path
          d="M0 19.21C0 8.598 8.598 0 19.21 0h92.207c10.611 0 19.21 8.598 19.21 19.21v26.894h11.525c10.612 0 19.21 8.598 19.21 19.21v81.341c0 10.266-12.41 15.406-19.678 8.145l-16.49-16.49h-59.88c-10.612 0-19.21-8.598-19.21-19.21v-11.525h-9.936l-16.49 16.49C12.41 131.325 0 126.185 0 115.92V19.21z"
          filter="url(#b)"
          transform="translate(1568.304 162.857) translate(114.153)"
        />
        <g
          clipPath="url(#c)"
          transform="translate(1568.304 162.857) translate(114.153)"
        >
          <path
            fill="#FFF"
            d="M0 0L161.362262 0 161.362262 158.2 0 158.2 0 0z"
          />
        </g>
        <g
          clipPath="url(#d)"
          transform="translate(1568.304 162.857) translate(114.153)"
        >
          <path
            fill="#00213A"
            d="M0 0L161.362262 0 161.362262 158.2 0 158.2 0 0z"
          />
        </g>
        <g
          clipPath="url(#e)"
          transform="translate(1568.304 162.857) translate(114.153) translate(25.95 37.858)"
        >
          <g clipPath="url(#d)" transform="translate(-25.95 -37.858)">
            <path
              fill="#F2A526"
              d="M25.9500492 37.8579768L161.362262 37.8579768 161.362262 158.2 25.9500492 158.2 25.9500492 37.8579768z"
            />
          </g>
        </g>
        <g>
          <g
            clipPath="url(#f)"
            transform="translate(1568.304 162.857) translate(51.35 148.515)"
          >
            <path
              fill="#F2A526"
              d="M0 0L83.6912527 0 83.6912527 64 0 64 0 0z"
            />
          </g>
        </g>
        <g>
          <g
            clipPath="url(#g)"
            transform="translate(1568.304 162.857) matrix(-1 0 0 1 57.538 94.515)"
          >
            <path
              fill="#00213A"
              d="M0 0L57.5377362 0 57.5377362 44 0 44 0 0z"
            />
          </g>
        </g>
        <g>
          <g
            clipPath="url(#h)"
            transform="translate(1568.304 162.857) matrix(-1 0 0 1 78.811 51.515)"
          >
            <path
              fill="#F2A526"
              d="M0 0L43.1533022 0 43.1533022 33 0 33 0 0z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FAQMobSvg(props) {
  return (
    <svg
      viewBox="0 0 159.0 153.0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M812 0v2383H0V0h812z" />
        </clipPath>
        <clipPath id="b">
          <path d="M812 0v4016H0V0h812z" />
        </clipPath>
        <filter
          id="c"
          x="-17.6363547%"
          y="-12.9301196%"
          filterUnits="userSpaceOnUse"
          width="126.807259%"
          height="127.296919%"
        >
          <feGaussianBlur
            stdDeviation={3}
            in="SourceAlpha"
            result={9.595971005412012e25}
          />
          <feOffset
            dy={7}
            in={9.595971005412012e25}
            result={1.0955981195698572e24}
          />
          <feColorMatrix
            values="0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.0 0 0 0 0 0.1964307664991259 0"
            in={1.0955981195698572e24}
          />
        </filter>
        <clipPath id="d">
          <path d="M89.591 0c8.533 0 15.447 6.914 15.447 15.447v21.625h9.268c8.533 0 15.447 6.914 15.447 15.447v65.408c0 8.254-9.979 12.388-15.824 6.549l-13.26-13.26H52.52c-8.533 0-15.447-6.913-15.447-15.446v-9.268h-7.989l-13.26 13.26C9.98 105.6 0 101.466 0 93.211V15.447C0 6.914 6.914 0 15.447 0H89.59z" />
        </clipPath>
        <clipPath id="e">
          <path d="M89.591 0c8.533 0 15.447 6.914 15.447 15.447v21.625h9.268c8.533 0 15.447 6.914 15.447 15.447v65.408c0 8.254-9.979 12.388-15.824 6.549l-13.26-13.26H52.52c-8.533 0-15.447-6.913-15.447-15.446v-9.268h-7.989l-13.26 13.26C9.98 105.6 0 101.466 0 93.211V15.447C0 6.914 6.914 0 15.447 0H89.59zm24.715 43.25h-9.268v27.805c0 8.533-6.914 15.447-15.447 15.447h-46.34v9.268a9.267 9.267 0 009.268 9.268h49.43c.821 0 1.606.327 2.187.902l14.161 14.168c1.947 1.946 5.277.568 5.277-2.181V52.519a9.267 9.267 0 00-9.268-9.268zM89.59 6.18H15.447a9.267 9.267 0 00-9.268 9.268v77.765c0 2.75 3.324 4.127 5.276 2.18l14.162-14.167a3.112 3.112 0 012.187-.902h61.787a9.267 9.267 0 009.268-9.268V15.447a9.267 9.267 0 00-9.268-9.268zm-12.357 49.43a3.09 3.09 0 010 6.178h-49.43a3.09 3.09 0 010-6.179h49.43zM46.34 30.892a3.09 3.09 0 010 6.18H27.804a3.09 3.09 0 010-6.18H46.34z" />
        </clipPath>
        <clipPath id="f">
          <path d="M84.215 0c31.554.301 47.331 1.481 47.331 3.54 0 3.09-5.175 118.018-8.954 118.018-3.78 0-126.27-12.035-122.507-21.508 2.509-6.315 7.034-20.974 13.575-43.975H83.98l.236-12.416V0z" />
        </clipPath>
        <clipPath id="g">
          <path d="M89.591 0c8.533 0 15.447 6.914 15.447 15.447v21.625h9.268c8.533 0 15.447 6.914 15.447 15.447v65.408c0 8.254-9.979 12.388-15.824 6.549l-13.26-13.26H52.52c-8.533 0-15.447-6.913-15.447-15.446v-9.268h-7.989l-13.26 13.26C9.98 105.6 0 101.466 0 93.211V15.447C0 6.914 6.914 0 15.447 0H89.59zm24.715 43.25h-9.268v27.805c0 8.533-6.914 15.447-15.447 15.447h-46.34v9.268a9.267 9.267 0 009.268 9.268h49.43c.821 0 1.606.327 2.187.902l14.161 14.168c1.947 1.946 5.277.568 5.277-2.181V52.519a9.267 9.267 0 00-9.268-9.268zM89.59 6.18H15.447a9.267 9.267 0 00-9.268 9.268v77.765c0 2.75 3.324 4.127 5.276 2.18l14.162-14.167a3.112 3.112 0 012.187-.902h61.787a9.267 9.267 0 009.268-9.268V15.447a9.267 9.267 0 00-9.268-9.268zm-12.357 49.43a3.09 3.09 0 010 6.178h-49.43a3.09 3.09 0 010-6.179h49.43zM46.34 30.892a3.09 3.09 0 010 6.18H27.804a3.09 3.09 0 010-6.18H46.34z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-333 -464)">
        <g clipPath="url(#b)" transform="translate(0 529.198)">
          <path
            fill="#F4EDE4"
            d="M0 0L812 0 812 1853.80163 0 1853.80163 0 0z"
          />
        </g>
        <path
          d="M0 15.447C0 6.914 6.914 0 15.447 0H89.59c8.533 0 15.447 6.914 15.447 15.447v21.625h9.268c8.533 0 15.447 6.914 15.447 15.447v65.408c0 8.254-9.979 12.388-15.824 6.549l-13.26-13.26H52.52c-8.533 0-15.447-6.913-15.447-15.446v-9.268h-7.989l-13.26 13.26C9.98 105.6 0 101.466 0 93.211V15.447z"
          filter="url(#c)"
          transform="translate(339.457 464.857)"
        />
        <g clipPath="url(#d)" transform="translate(339.457 464.857)">
          <path
            fill="#FFF"
            d="M0 0L129.752649 0 129.752649 127.20985 0 127.20985 0 0z"
          />
        </g>
        <g clipPath="url(#e)" transform="translate(339.457 464.857)">
          <path
            fill="#00213A"
            d="M0 0L129.752649 0 129.752649 127.20985 0 127.20985 0 0z"
          />
        </g>
        <g
          clipPath="url(#f)"
          transform="translate(339.457 464.857) translate(20.867 30.442)"
        >
          <g clipPath="url(#g)" transform="translate(-20.867 -30.442)">
            <path
              fill="#F2A526"
              d="M20.8666363 30.4418934L129.752649 30.4418934 129.752649 127.20985 20.8666363 127.20985 20.8666363 30.4418934z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
