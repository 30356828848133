import React from "react";
import {
  capitalize,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { MainButton } from "../../styles/components/buttons";
import { FormError, SignUpFormContainer } from "./SignUp.styles";
import { useSignUpForm } from "./useSignUpForm";

const SignUpForm = () => {
  const { input, errors, isValid, handleType, handleSendForm } =
    useSignUpForm();
  return (
    <SignUpFormContainer>
      <form>
      <InputLabel shrink htmlFor="name-input">
        First Name
      </InputLabel>
      <OutlinedInput
        id={"name-input"}
        name="firstName"
        value={input.firstName}
        onChange={handleType}
      />
      <InputLabel shrink htmlFor="last_name-input">
        Last Name
      </InputLabel>
      <OutlinedInput
        id={"last_name-input"}
        value={input.lastName}
        name="lastName"
        onChange={handleType}
      />
      <InputLabel shrink htmlFor="email-input">
        EMAIL
      </InputLabel>
      <OutlinedInput
        id="email-input"
        value={input.email}
        name="email"
        onChange={handleType}
      />
      <InputLabel shrink htmlFor="company-input">
        COMPANY NAME
      </InputLabel>
      <OutlinedInput
        id="company-input"
        value={input.company}
        name="company"
        onChange={handleType}
      />
      {errors.map((el, i) => (
        <FormError key={el + i}>*{capitalize(el)}</FormError>
      ))}
      <MainButton onClick={handleSendForm} disabled={!isValid} >
        Get early access
      </MainButton>
      <Typography>
        Sign up and our team will be contacting you in no time!
      </Typography>
      </form>
    </SignUpFormContainer>
  );
};

export default SignUpForm;
