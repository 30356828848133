import FAQContent from "../sections/faq/FAQContent";
import FAQTitle from "../sections/faq/FAQTtitle";
import { TrustedBlock } from "../sections/trustedBlock/TrustedBlock";
import React from "react";

const FAQ = () => {
  return (
    <>
      <FAQTitle />
      <FAQContent />
      <TrustedBlock />
    </>
  );
};

export default FAQ;
