import { Button, styled } from "@mui/material";
import { FlexBox } from "../../styles/components/Layout";
import theme from "../../styles/theme";

export const SocialMediaContainer = styled(FlexBox)`
  margin-left: 6rem;
  ${theme.breakpoints.down("br1024")} {
    margin-left: 0;
  }

  ${theme.breakpoints.down("br480")} {
    margin-top: -3rem;
    width: 90%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

export const MediaButton = styled(Button)`
  color: rgb(157, 161, 170);
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.18px;
  opacity: 0.72;
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
  @media screen and (max-width: 480px) {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }

  & span {
    //width: 2.3rem;
    width: 2.4rem;
    height: 2rem;
    margin-bottom: 0.3rem;
    & > div {
      display: flex;

      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
