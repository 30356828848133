//Core
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
//Styles
import {SectionContainer} from "../../styles/components/Layout";
import {LinkDiscord, MainButton} from "../../styles/components/buttons";
import {
    IconBox,
    NumbersCard,
    PositiveNumbersBox,
    SubtitleCard,
    Title,
    TitleCard,
    Top,
    TopBox
} from "./PositiveNumbers.styles";
import {Divider, Stack} from "@mui/material";
//Images
import Like from '../../assets/svg/Like.svg'
import Money from '../../assets/svg/Money.svg'
import Star from  '../../assets/svg/Star.svg'
//Components
import {SocialMedia} from "../socialMedia/SocialMedia";


export const PositiveNumbers = () => {
    const {isAuthenticated} = useAuth0()
    return (
        <SectionContainer>
            <Top>
                <SocialMedia/>
                <TopBox>
                    {isAuthenticated
                        ? <MainButton href={'https://dashboard.web3m.io'} target={'_dashboard'}>{'Enter your user account'}</MainButton>
                        : <MainButton component={Link} to={'/signup'}>{'Get early access'}</MainButton>
                    }
                    <LinkDiscord href={'https://discord.gg/7NFVRymVZf'}
                                 target={'_blank'}>{'Join the community'}</LinkDiscord>
                </TopBox>

            </Top>
            <Divider sx={{
                width: '100vw',
                transform: 'translateX(-50%)',
                marginLeft: '50%',
                height: '1px',
                marginBottom: {xs:'11rem', sm: '11.5rem'},
                background: 'rgba(204,204,211,0.3)'
            }}/>
            <Title variant={'h2'}>{'Here are some positive numbers:'}</Title>
            <PositiveNumbersBox>
                <NumbersCard>
                    <Stack>
                        <IconBox><img src={Star}/></IconBox>
                        <TitleCard>{'+50K web3 brands'}</TitleCard>
                        <SubtitleCard>{'Struggle to Promote their brand'}</SubtitleCard>
                    </Stack>
                </NumbersCard>
                <NumbersCard>
                    <Stack>
                        <IconBox><img src={Like}/></IconBox>
                        <TitleCard>{'95% of founders'}</TitleCard>
                        <SubtitleCard>{'Say it saves them time and money'}</SubtitleCard>
                    </Stack>
                </NumbersCard>
                <NumbersCard>
                    <Stack>
                        <IconBox><img src={Money}/></IconBox>
                        <TitleCard>{'100'}</TitleCard>
                        <TitleCard>{'m$<'}</TitleCard>
                        <SubtitleCard>{'Total ads spend budget'}</SubtitleCard>
                    </Stack>
                </NumbersCard>
            </PositiveNumbersBox>
        </SectionContainer>
    )
}