import {Fragment, useEffect} from 'react';
import {SectionContainer} from "../../styles/components/Layout";
import {Grid} from "@mui/material";
import {
    Ball,
    ButtonLeft,
    ButtonRight, CampaignTitle, CompanyNameBox,
    DividerDashed, LinkStyled,
    NichesBlock, NichesMobileBlock,
    StackLeft,
    StackRight,
    Subtitle
} from "./CampaignBlock.styles";
import {CampaignExample} from "./CampaignExample";
// import Uniswap from '../../assets/img/uniswap.webp'
import {useBreakpoints} from "../../shared/hooks/useBreakpoints";
import {data} from '../../data/data'

const buttons = {
    left: [
        {color: '#f2a626', name: 'Defi'},
        {color: '#04B78A', name: 'Decentralized Exchange'},
        {color: '#636E95', name: 'Blockchain Companies'},
        {color: '#0D81B3', name: 'Gaming'},
    ],
    right: [
        {color: '#FA5087', name: 'Metaverse'},
        {color: '#369AFE', name: 'NFT'},
        {color: '#C09449', name: 'Crypto Analytics Tools'},
        {color: '#1EB9A8', name: 'Other'},
    ]
}

const buttonsMobile = [
    {color: '#f2a626', name: 'Defi', xs: 3.5},
    {color: '#FA5087', name: 'Metaverse', xs: 5.5},
    {color: '#369AFE', name: 'NFT', xs: 3},
    {color: '#04B78A', name: 'Decentralized Exchange', xs: 8.5},
    {color: '#0D81B3', name: 'Gaming', xs: 3.5},
    {color: '#C09449', name: 'Crypto Analytics Tools', xs: 8},
    {color: '#1EB9A8', name: 'Other', xs: 4},
    {color: '#636E95', name: 'Blockchain Companies', xs: 12},
]

export const CampaignBlock = (props) => {
    const {active, setActive} = props
    const {desktopOnly, mobileOnly, screen768} = useBreakpoints()

    useEffect(() => {
        if (screen768) {
            const index = buttonsMobile.findIndex(button => button.name.toLowerCase() === active.name.toLowerCase())
            setActive({...active, num: index + 1})
        } else {
            const index = [...buttons.left, ...buttons.right].findIndex(button => button.name.toLowerCase() === active.name.toLowerCase())
            setActive({...active, num: index + 1})
        }
    }, [screen768])

    return (
        <SectionContainer>
            <CampaignTitle variant={'h2'}>{'Feel how it works'}</CampaignTitle>
            <Subtitle>
                {'Here are some web3 companies which already launched a campaign with us'}
            </Subtitle>
            <NichesBlock container sx={desktopOnly}>
                <Grid item xs={6}>
                    <StackLeft active={active.num}>
                        <>{buttons.left.map((button, i) => <Fragment key={i + button.color}>
                            <LinkStyled
                                href={'example'}
                                to={'example'}
                                onClick={() => setActive({name: button.name, num: i + 1})}
                                offset={-220}
                                spy
                                smooth
                            >
                                <ButtonLeft background={button.color} i={i + 1} active={active.num}>
                                    {button.name}
                                </ButtonLeft>
                            </LinkStyled>
                            {active.num === (i + 1) && <>
                                <DividerDashed orientation={'vertical'} component={'div'} active={active.num}
                                               i={i + 1}/>
                                <Ball active={active.num} i={i + 1}/>
                                <CompanyNameBox active={active.num} i={i + 1} niche={active.name.toLowerCase()}>
                                    <img src={data[active.name.toLowerCase()].brandLogo}/>
                                    {/*<Typography>{data[active.name.toLowerCase()].brandName}</Typography>*/}
                                </CompanyNameBox>
                            </>}</Fragment>)}</>
                    </StackLeft>
                </Grid>
                <Grid item xs={6}>
                    <StackRight active={active.num}>
                        <>{buttons.right.map((button, i) => <Fragment key={i + button.color}>
                            <LinkStyled
                                href={'example'}
                                to={'example'}
                                onClick={() => setActive({name: button.name, num: i + 5})}
                                offset={-220}
                                spy
                                smooth
                            >
                                <ButtonRight background={button.color} i={i + 5} active={active.num}>
                                    {button.name}
                                </ButtonRight>
                            </LinkStyled>
                            {(active.num - 4) === (i + 1) && <>
                                <DividerDashed orientation={'vertical'} component={'div'} active={active.num - 4}
                                               i={i + 1}/>
                                <Ball active={active.num - 4} i={i + 1} right/>
                                <CompanyNameBox active={active.num - 4} i={i + 1} right
                                                niche={active.name.toLowerCase()}>
                                    <img src={data[active.name.toLowerCase()].brandLogo}/>
                                </CompanyNameBox>
                            </>}</Fragment>)}</>
                    </StackRight>
                </Grid>
            </NichesBlock>
            <NichesMobileBlock sx={mobileOnly} container spacing={2}>
                <>
                    {buttonsMobile.map((button, i) => <Fragment key={button.name + i}>
                            <Grid item xs={button.xs}>
                                <LinkStyled
                                    href={'example'}
                                    to={'example'}
                                    onClick={() => setActive({name: button.name, num: i + 1})}
                                    offset={-140}
                                    spy
                                    smooth
                                >
                                    <ButtonLeft background={button.color} active={active.num} i={i + 1}>
                                        {button.name}
                                    </ButtonLeft>
                                </LinkStyled>
                            </Grid>
                            <CompanyNameBox active={active.num} i={i + 1} niche={active.name.toLowerCase()}>
                                <img src={data[active.name.toLowerCase()].brandLogo}/>
                                {/*<Typography>{data[active.name.toLowerCase()].brandName}</Typography>*/}
                            </CompanyNameBox>
                        </Fragment>
                    )}
                </>
            </NichesMobileBlock>
            <CampaignExample active={active}/>
        </SectionContainer>
    )
}