import { Button, styled } from "@mui/material";
import { RabbitLogo } from "../../../assets/svg/logo";
// import { connectWallet } from "../../../shared/helpers/metamaskConnect";
import React from "react";
import {LoadingButton} from "@mui/lab";

const StyledButton = styled(LoadingButton)`
  position: absolute;
  right: 1.5rem;

  font-size: 2.2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #fff;
  text-transform: none;

  border: 1px solid #fff;
  border-radius: 4rem;
  padding: 1.3rem 3.5rem;

  svg {
    height: 4rem;
  }

  &.dark {
    /* position: relative; */
    color: #00213a;
    border-color: #00213a;

    svg {
      path {
        fill: #00213a;
      }
    }
  }

  &.float {
    position: relative;
    padding: 0.3rem 3rem;
    /* width: 25rem; */
    font-weight: normal;
    font-size: 2rem;
    letter-spacing: -0.21px;
    line-height: 1.25;

    svg {
      height: 3rem;
    }
  }

  @media only screen and (max-width: 2100px) {
    position: relative;

    &:not(.float) {
      margin-left: 2rem;
    }
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ConnectNFTButton = ({ dark, float, onClick, title, loading }) => {
  return (
    <StyledButton startIcon={<RabbitLogo />} className={`${dark && "dark"} ${float && "float"}`} onClick={onClick} loading={loading}>
        {title}
    </StyledButton>
  );
};

export default ConnectNFTButton;
