import axios from "axios";
import {DEV_ONLY, storageToken} from "../utils/helpers";

const baseURL = DEV_ONLY
    ? "https://gateway-develop-dot-winky-apis.ew.r.appspot.com/"
    : "https://gateway-dot-winky-apis.ew.r.appspot.com/";


const gatewayClient = axios.create({
    baseURL,
});


export const findToken = () => {
    console.log(DEV_ONLY)
    if (typeof window !== 'undefined') {
        const user_data = localStorage.getItem(storageToken);
        if (user_data) {
            return JSON.parse(user_data)?.body?.access_token;
        }
    }
};

// gatewayClient.defaults.headers.common['Authorization'] = `Bearer ${findToken()}`

gatewayClient.defaults.timeout = 60000

export default gatewayClient;