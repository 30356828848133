import React from "react";
import { Box } from "@mui/material";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const axisStyle = {
  style: { fontSize: "1.2rem" },
  tickLine: { opacity: "0.5" },
};

const LineGraph = ({ data, legend, comingSoon = false }) => {
  return (
    <Box
      sx={{
        height: "38rem",
        position: "relative",
        ".recharts-wrapper > .recharts-surface": {
          width: "105%",
          marginLeft: "-2.5%",
        },
      }}
    >
      <Box sx={{ opacity: comingSoon ? 0.3 : 1, height: "100%" }}>
        <ResponsiveContainer maxHeight={350} width="100%" height="100%">
          <LineChart
            height={350}
            width={700}
            data={data}
            margin={{ left: -20 }}
          >
            <XAxis dataKey="xAxis" {...axisStyle} />
            <YAxis tickCount={8} {...axisStyle} />
            <CartesianGrid opacity="0.5" />
            <Legend
              verticalAlign="top"
              height={50}
              iconType="rect"
              align="left"
              formatter={(value) => legend[value]}
              chartWidth={500}
              fontFamily="'Hellix', sans-serif"
              wrapperStyle={{
                paddingLeft: "3rem",
                fontSize: "1.2rem",
                color: "#000",
              }}
            />
            <Tooltip
              formatter={(value, name) => [value, legend[name]]}
              labelStyle={{ fontFamily: "'Hellix', sans-serif" }}
            />
            <Line
              dataKey="line1"
              stroke="rgb(255, 99, 132)"
              strokeWidth={2}
              dot={{ strokeWidth: 5, r: 1 }}
            />
            <Line
              dataKey="line2"
              stroke="rgb(53, 162, 235)"
              strokeWidth={2}
              dot={{ strokeWidth: 5, r: 1 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default LineGraph;
