import axios from "axios";

export const whoIsItForList = [
  "Companies from the blockchain, crypto, NFT, Metaverse space",
  "Brands",
  "Agencies",
];

export const sendFormData = async (inputData) => {
  const data = {
    email: inputData.email,
    first_name: inputData.firstName,
    last_name: inputData.lastName,
    company_name: inputData.company,
  };

  try {
    const response = await axios.post(
      "https://mvp-web3-dot-winky-apis.ew.r.appspot.com/sign_up",
      data
    );

    if (response.data.status !== "success") {
      throw new Error("Server Error!");
    }
  } catch (err) {
    console.log(err);
  }
};
