import { Box, Divider, Stack, styled, Typography } from "@mui/material";
import { FlexBox, ImgBox, NFTSectionContainer, SectionContainer } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";
import { MainButton } from "../../../styles/components/buttons";

export const BackgroundRound = styled(Box)`
  background-color: #131415;
  border-top-left-radius: 31rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 0;
  ${theme.breakpoints.down('br768')} {
    border-top-left-radius: 20rem;
  }
`

export const FaqNftContainer = styled(FlexBox)`
  background-color: #00213a;
  padding-bottom: 15rem;
  padding-top: 25rem;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
`

export const EarthBox = styled(ImgBox)`
  position: absolute;
  width: 205rem;
  top: -18rem;
  left: 55%;
  z-index: 1;
  ${theme.breakpoints.down('lg')} {
    width: 130rem;
    left: 65%;
  }
  ${theme.breakpoints.down('br768')} {
    width: 130rem;
    left: 70%;
    top: -40rem;
  }
`

export const EarthLeftBox = styled(ImgBox)`
  position: absolute;
  right: 65%;
  //left: 0;
  bottom: -75rem;
  width: 180rem;
  ${theme.breakpoints.down('xl')} {
    width: 130rem;
  }
  ${theme.breakpoints.down('lg')} {
    left: 0;
    transform: translateX(-70%);
    bottom: 65rem;
  }
  ${theme.breakpoints.down('md')} {
    width: 110rem;
   }
  ${theme.breakpoints.down('sm')} {
    width: 90rem;
    bottom: 70rem;
  }
`

export const ExtraEarth = styled(ImgBox)`
  display: none;
  ${theme.breakpoints.down('md')} {
    position: absolute;
    width: 100rem;
    bottom: -40rem;
    left: 45%;
    display: block;
  }
`

export const RabbitBox = styled(ImgBox)`
  position: absolute;
  left: 55%;
  bottom: -1.2rem;
  max-height: 120rem;
  max-width: 1200px;
  z-index: 1;
  ${theme.breakpoints.down('md')} {
    left: 55%;
    max-width: 35%;
  }
  ${theme.breakpoints.down('br768')} {
    max-width: 40%;
  }
  ${theme.breakpoints.down('sm')} {
    right: 0;
    max-width: 40%;
  }
`

export const FaqNftContent = styled(NFTSectionContainer)`
  z-index: 1;
  ${theme.breakpoints.down('xl')} {
    max-width: 75%;
    padding-bottom: 10rem;
  }
  ${theme.breakpoints.down('lg')} {
    padding-bottom: 7rem;
  }
  ${theme.breakpoints.down('md')} {
    max-width: 85%;
    padding-bottom: 5rem;
  }
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 10rem;
  }
`
export const MainTitleFAQ = styled(Typography)`
  color: #fff;
  margin-bottom: 11rem;
`

export const QuestionAnswerBlock = styled(Stack)`
  width: 70%;
  svg {
    width: 5.2rem;
    height: 5.2rem;
    flex-shrink: 0;
    margin-right: 2.3rem;
  }
  ${theme.breakpoints.down('br1024')} {
    width: 90%;
  }
`

export const LineBlock = styled(FlexBox)`
  &:first-of-type {
    margin-bottom: 2.6rem;
  }
  &:last-of-type{
    align-items: flex-start;
  }
`

export const QuestionText = styled(Typography)`
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.32px;
  color: rgba(255, 255, 255, 0.74);
`

export const AnswerText = styled(Typography)`
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: rgba(255, 255, 255, 0.66);
  width: ${props => props.index === 2 ? '60%' : '100%'};
  ${theme.breakpoints.down('xl')} {
    width: ${props => props.index === 2 ? '60%' : '80%'};
  }
  ${theme.breakpoints.down('lg')} {
    width: ${props => props.index === 2 ? '60%' : '100%'};
  }
  ${theme.breakpoints.down('br1024')} {
    width: ${props => props.index === 2 ? '60%' : props.index === 4 ? '75%' : '95%'};
  }
  ${theme.breakpoints.down('md')} {
    width: ${props => props.index === 2 ? '60%' : '100%'};
  }
`

export const DividerDashed = styled(Divider)`
  width: 100%;
  height: 1px;
  border-style: dashed;
  margin: 7.4rem 0 6.9rem;
  border-color: rgba(255, 255, 255, 0.1);
`

export const LastQuestion = styled(Typography)`
  font-size: 5rem;
  font-weight: 600;
  line-height: 0.5;
  letter-spacing: -0.53px;
  color: rgba(255, 255, 255, 0.74);
  margin-top: 10rem;
  margin-bottom: 4rem;
  ${theme.breakpoints.down('sm')} {
    margin-top: 5rem;
    font-size: 4rem;
  }
`

export const ContactUs = styled(Typography)`
  font-size: 2.4rem;
  line-height: 1.04;
  letter-spacing: -0.25px;
  color: rgba(255, 255, 255, 0.57);
  a {
    text-decoration: underline;
    color: #8e74e8;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 2rem;
  }
`

export const ApplyButtonBox = styled(FlexBox)`
  flex-shrink: 0;
  z-index: 2;
  top: -0.7rem;
  left: 50%;
  transform: translateX(-190%);
  position: absolute;
  padding: 1.2rem;
  background-color: #00213a;
  border-radius: 50px;
  &>svg {
    position: absolute;
    bottom: 0.7rem;
    left: -9rem;
    width: 150%;
    margin-bottom: 0;
  }
  ${theme.breakpoints.down('xl')} {
    transform: unset;
    left: 13%;
  }
  ${theme.breakpoints.down('br1024')} {
    left: 17%;
  }
  ${theme.breakpoints.down('md')} {
    left: 20%;
  }
  ${theme.breakpoints.down('br768')} {
    left: 50%;
    transform: translateX(-60%);
    padding: 1.5rem;
    width: max-content;
    &>svg {
      display: none;
      //width: 100%;
      //top: 0.5rem;
      //left: 4.5rem;
    }
  }
  ${theme.breakpoints.down('sm')} {
    transform: translateX(-50%);
  }
`

export const FAQButton = styled(MainButton)`
  ${theme.breakpoints.down('br768')} {
    padding: 1.5rem 8.5rem;
    flex-shrink: 0;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 1.5rem 7.5rem;
  }
  @media screen and (max-width: 430px) {
    padding: 1.5rem 6.5rem;
  }
`