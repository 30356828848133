import {
  Avatar,
  Chip,
  IconButton,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import theme from "../../styles/theme";

export const AdContainer = styled(Stack)`
  position: relative;
  border: solid 1px #f0f0f0;
  padding: 3.5rem;
  height: 100%;
  background-color: #fff;

  .imageBox {
    height: 15rem;
    overflow: hidden;
    ${theme.breakpoints.down('br768')} {
      height: unset;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    width: 3.1rem;
    height: 2.9rem;
    right: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.7rem;
      transform: translateX(10%);
    }
  }
  p {
    span {
      color: #222;
    }
  }

  .infoBox {
    padding: 2rem 2.4rem 2.8rem;
    ${theme.breakpoints.down("br768")} {
      padding: 2rem 3.4rem 2.8rem;
    }
  }

  .title {
    font-size: 1.44rem;
    font-weight: bold;
    letter-spacing: 0.17px;
    color: #182fcd;
    margin-bottom: 1.5rem;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${theme.breakpoints.down("br768")} {
      font-size: 2.4rem;
      line-height: 1.2;
      overflow: unset;
      white-space: unset;
    }
  }

  .desc1 {
    font-size: 1.54rem;
    letter-spacing: 0.17px;
    color: #020202;
    margin-bottom: 2rem;
  }
  .desc2 {
    font-size: 1.54rem;
    letter-spacing: 0.17px;
    color: #020202;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  .logo {
    width: 4rem;
    height: 4rem;
    border-radius: 0.7rem;
    margin-right: 0.9rem;
    align-self: flex-start;
    & img {
      //width: 100%;
      width: auto;
      height: 100%;
    }
    ${theme.breakpoints.down("br768")} {
      width: 6rem;
      height: 6rem;
    }
  }
  .respDesc {
    font-size: 1.16rem;
    letter-spacing: 0.13px;
    line-height: 1;
    color: #020202;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    width: 100%;
    margin-right: 1rem;
    word-break: break-word;
    ${theme.breakpoints.down("br768")} {
      font-size: 1.8rem;
      margin-top: 0.5rem;
    }
  }
  .businessName {
    font-size: 1.07rem;
    font-weight: 500;
    letter-spacing: 0.13px;
    color: #9ba39c;
    margin-top: 0.5rem;
    ${theme.breakpoints.down("br768")} {
      font-size: 1.6rem;
      margin-top: 0.7rem;
    }
  }

  .url {
    align-items: center;
    margin-bottom: 1.5rem;

    svg {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 0.5rem;
    }

    p {
      font-size: 1.14rem;
      font-weight: 500;
      letter-spacing: 0.13px;
      color: #9ba39c;
    }
  }

  .channelIcon {
    position: absolute;
    bottom: 0.6rem;
    right: 0.8rem;
    width: fit-content;
    height: 1.8rem;
    svg {
      height: 100%;
    }

    img {
      width: auto;
    }
  }

  button {
    border-radius: 4px;
    background-color: #5b5b5b;
    width: 9.4rem;
    height: 3.4rem;
    align-self: flex-start;
    transition: 0.3s;
    margin-left: 0.7rem;
    &:disabled {
      background-color: #5b5b5b;
    }

    svg {
      height: 1.4rem;
      transform: scale(1.5);
      path {
        fill: #fff;
      }
    }

    &.resp {
      background-color: #182fcd;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      ${theme.breakpoints.down("br768")} {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
`;
