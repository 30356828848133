import SimplifyImg from "../../assets/img/screens/Simplify.webp";
import LaunchImg from "../../assets/img/screens/Launch.webp";
import AdvertiseImg from "../../assets/img/screens/Advertise.webp";
import MonitorImg from "../../assets/img/screens/Monitor.webp";
import SimplifyMobImg from "../../assets/img/screens/Simplify_mob.webp";
import LaunchMobImg from "../../assets/img/screens/Launch_mob.webp";
import AdvertiseMobImg from "../../assets/img/screens/Advertise_mob.webp";
import MonitorMobImg from "../../assets/img/screens/Monitor_mob.webp";

export const keyPointsData = [
  {
    title: "Simplify content writing",
    desc: "Let us write content for you, define your target audience and simply make your life so much easier.",
    image: SimplifyImg,
    imageMob: SimplifyMobImg,
  },
  {
    title: "Launch a strong campaign in 5 minutes",
    desc: "The Web3M platform generates the entire marketing campaign for any web3 company so much quicker than any other alternative.",
    image: LaunchImg,
    imageMob: LaunchMobImg,
  },
  {
    title: "Advertise across multiple channels",
    desc: `Increase your ROI by advertising on the best channels and reaching the most relevant audiences for your web3 brand.
      Our targeting system matches you with the right ad placements based on your needs.
      `,
    image: AdvertiseImg,
    imageMob: AdvertiseMobImg,
  },
  {
    title: "Monitor campaign results in real time",
    desc: "You can see the results and control your campaigns across all the channels from one place - a powerful and easy-to-use dashboard.",
    image: MonitorImg,
    imageMob: MonitorMobImg,
  },
];
