import Rabbit1 from "../../../assets/img/nft/Rab1.webp";
import Rabbit2 from "../../../assets/img/nft/Rab2.webp";
import Rabbit3 from "../../../assets/img/nft/Rab3.webp";
import Rabbit4 from "../../../assets/img/nft/Rab4.webp";
import Rabbit5 from "../../../assets/img/nft/Rab5.webp";

export const nftHolderGain = [
  "Early access to Webm’s advertising platform",
  "A kickback from 2% of total ad spend on Webm",
  "Exclusive access to the Webm token presale",
];

export const rabImages = [
  <img src={Rabbit1} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit2} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit3} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit4} alt="rabbit nft image" className={"desktopOnly"} />,
  <img src={Rabbit5} alt="rabbit nft image" className={"desktopOnly"} />,
];
