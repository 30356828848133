export const legendData = { line1: "Positive Feedback", line2: "Negative Feedback" };

export const graphData = {
  1: [
    {
      xAxis: "June",
      line1: 400,
      line2: 100,
    },
    {
      xAxis: "July",
      line1: 200,
      line2: -800,
    },
    {
      xAxis: "August",
      line1: -900,
      line2: 180,
    },
    {
      xAxis: "September",
      line1: -400,
      line2: 300,
    },
  ],
  2: [
    {
      xAxis: "June",
      line1: 200,
      line2: 500,
    },
    {
      xAxis: "July",
      line1: 350,
      line2: -100,
    },
    {
      xAxis: "August",
      line1: 500,
      line2: 200,
    },
    {
      xAxis: "September",
      line1: 300,
      line2: 50,
    },
  ],
  3: [
    {
      xAxis: "June",
      line1: 100,
      line2: 50,
    },
    {
      xAxis: "July",
      line1: 150,
      line2: -100,
    },
    {
      xAxis: "August",
      line1: 300,
      line2: -150,
    },
    {
      xAxis: "September",
      line1: 500,
      line2: 0,
    },
  ],
  4: [
    {
      xAxis: "June",
      line1: 200,
      line2: -100,
    },
    {
      xAxis: "July",
      line1: 500,
      line2: -250,
    },
    {
      xAxis: "August",
      line1: -100,
      line2: 500,
    },
    {
      xAxis: "September",
      line1: 400,
      line2: 150,
    },
  ],
  5: [
    {
      xAxis: "June",
      line1: 100,
      line2: 300,
    },
    {
      xAxis: "July",
      line1: 700,
      line2: -100,
    },
    {
      xAxis: "August",
      line1: -350,
      line2: 400,
    },
    {
      xAxis: "September",
      line1: 500,
      line2: 100,
    },
  ],
  6: [
    {
      xAxis: "June",
      line1: 400,
      line2: 200,
    },
    {
      xAxis: "July",
      line1: 600,
      line2: -100,
    },
    {
      xAxis: "August",
      line1: 900,
      line2: -150,
    },
    {
      xAxis: "September",
      line1: 1000,
      line2: -50,
    },
  ],
  7: [
    {
      xAxis: "June",
      line1: -200,
      line2: 150,
    },
    {
      xAxis: "July",
      line1: 400,
      line2: -350,
    },
    {
      xAxis: "August",
      line1: -200,
      line2: 100,
    },
    {
      xAxis: "September",
      line1: 100,
      line2: -150,
    },
  ],
  8: [
    {
      xAxis: "June",
      line1: 100,
      line2: -100,
    },
    {
      xAxis: "July",
      line1: -100,
      line2: -300,
    },
    {
      xAxis: "August",
      line1: -150,
      line2: 150,
    },
    {
      xAxis: "September",
      line1: 300,
      line2: 100,
    },
  ],
};