export function NftHeroBgDesktop(props) {
  return (
    <svg
      viewBox="0 0 1124 686"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M304.5 0H1134v609c-189.752 50.678-372.068 76.016-546.95 76.016-174.88 0-268.685-25.338-281.414-76.016H304.5C136.33 609 0 472.67 0 304.5S136.33 0 304.5 0z"
          id="a"
        />
        <path
          d="M311.5 43H1139v523H311.5C167.078 566 50 448.922 50 304.5S167.078 43 311.5 43z"
          id="d"
        />
        <filter
          x="-7.7%"
          y="-15.6%"
          width="115.3%"
          height="131.9%"
          filterUnits="objectBoundingBox"
          id="c"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={27.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M315.5 92H1136v425H315.5C198.14 517 103 421.86 103 304.5S198.14 92 315.5 92z"
          id="f"
        />
        <filter
          x="-8.1%"
          y="-19.2%"
          width="116.2%"
          height="139.3%"
          filterUnits="objectBoundingBox"
          id="e"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={27.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M324.5 142H1136v325H324.5C234.754 467 162 394.246 162 304.5S234.754 142 324.5 142z"
          id="h"
        />
        <filter
          x="-8.6%"
          y="-25.1%"
          width="117.1%"
          height="151.4%"
          filterUnits="objectBoundingBox"
          id="g"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={27.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M345.5 191H1136v227H345.5C282.816 418 232 367.184 232 304.5S282.816 191 345.5 191z"
          id="j"
        />
        <filter
          x="-9.2%"
          y="-35.9%"
          width="118.5%"
          height="173.6%"
          filterUnits="objectBoundingBox"
          id="i"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={27.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M294.5 242H1136v125H294.5c-34.518 0-62.5-27.982-62.5-62.5s27.982-62.5 62.5-62.5z"
          id="l"
        />
        <filter
          x="-9.2%"
          y="-65.2%"
          width="118.5%"
          height="233.6%"
          filterUnits="objectBoundingBox"
          id="k"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={27.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <linearGradient
          x1="26.1548347%"
          y1="42.7203105%"
          x2="33.5140921%"
          y2="50.8672015%"
          id="m"
        >
          <stop stopColor="#F2A626" stopOpacity={0} offset="0%" />
          <stop stopColor="#F2A626" offset="100%" />
        </linearGradient>
      </defs>
      <g
        transform="translate(-916 -214) translate(916 214)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#F2A626" xlinkHref="#a" />
        <g mask="url(#b)">
          <use fill="#000" filter="url(#c)" xlinkHref="#d" />
          <use fill="#F2A626" xlinkHref="#d" />
        </g>
        <g mask="url(#b)">
          <use fill="#000" filter="url(#e)" xlinkHref="#f" />
          <use fill="#F2A626" xlinkHref="#f" />
        </g>
        <g mask="url(#b)">
          <use fill="#000" filter="url(#g)" xlinkHref="#h" />
          <use fill="#F2A626" xlinkHref="#h" />
        </g>
        <g mask="url(#b)">
          <use fill="#000" filter="url(#i)" xlinkHref="#j" />
          <use fill="#F2A626" xlinkHref="#j" />
        </g>
        <g mask="url(#b)">
          <use fill="#000" filter="url(#k)" xlinkHref="#l" />
          <use fill="#F2A626" xlinkHref="#l" />
        </g>
        <path
          d="M304.5 0H1134v609H304.5C136.33 609 0 472.67 0 304.5S136.33 0 304.5 0z"
          fill="url(#m)"
          mask="url(#b)"
        />
      </g>
    </svg>
  )
}

export function NftHeroBgMobile(props) {
  return (
    <svg
      viewBox="0 0 500 461"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M229.773 0c111.171 0 203.903 78.953 225.181 183.845v-.164c2.42 13.366 4.754 22.273 10.66 30.431 5.907 8.158 23.515 15.039 33.693 17.024l-.707.143c-9.844 2.08-24.902 6.47-30.67 14.438-5.1 7.044-9.76 16.467-12.494 27.512-20.309 106.122-113.621 186.316-225.663 186.316C102.873 459.545 0 356.672 0 229.773 0 102.873 102.873 0 229.773 0z"
          id="a"
        />
        <path
          d="M227.287 31.375h603.788v381.609H227.287c-105.378 0-190.804-85.426-190.804-190.805 0-105.378 85.426-190.804 190.804-190.804z"
          id="d"
        />
        <filter
          x="-7.0%"
          y="-19.1%"
          width="116.9%"
          height="135.1%"
          filterUnits="objectBoundingBox"
          id="c"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M230.206 67.128h598.68v310.103h-598.68c-85.633 0-155.052-69.419-155.052-155.052 0-85.632 69.42-155.05 155.052-155.05z"
          id="f"
        />
        <filter
          x="-7.4%"
          y="-23.5%"
          width="117.8%"
          height="143.2%"
          filterUnits="objectBoundingBox"
          id="e"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M236.773 103.61h592.113v237.138H236.773c-65.484 0-118.57-53.085-118.57-118.569 0-65.483 53.086-118.568 118.57-118.568z"
          id="h"
        />
        <filter
          x="-7.9%"
          y="-30.8%"
          width="118.9%"
          height="156.5%"
          filterUnits="objectBoundingBox"
          id="g"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M252.095 139.364h576.791v165.631h-576.79c-45.739 0-82.816-37.078-82.816-82.816 0-45.737 37.077-82.815 82.815-82.815z"
          id="j"
        />
        <filter
          x="-8.5%"
          y="-44.1%"
          width="120.3%"
          height="180.9%"
          filterUnits="objectBoundingBox"
          id="i"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M214.883 176.576h614.003v91.207H214.883c-25.186 0-45.603-20.418-45.603-45.604s20.417-45.603 45.603-45.603z"
          id="l"
        />
        <filter
          x="-8.5%"
          y="-80.0%"
          width="120.3%"
          height="246.9%"
          filterUnits="objectBoundingBox"
          id="k"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <linearGradient
          x1="26.1548347%"
          y1="42.7203105%"
          x2="33.5140921%"
          y2="50.8672015%"
          id="m"
        >
          <stop stopColor="#F2A626" stopOpacity={0} offset="0%" />
          <stop stopColor="#F2A626" offset="100%" />
        </linearGradient>
        <path id="n" d="M0 0H500V497.63976H0z" />
        <path
          d="M227.287 31.375h603.788v381.609H227.287c-105.378 0-190.804-85.426-190.804-190.805 0-105.378 85.426-190.804 190.804-190.804z"
          id="q"
        />
        <filter
          x="-7.0%"
          y="-19.1%"
          width="116.9%"
          height="135.1%"
          filterUnits="objectBoundingBox"
          id="p"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M230.206 67.128h598.68v310.103h-598.68c-85.633 0-155.052-69.419-155.052-155.052 0-85.632 69.42-155.05 155.052-155.05z"
          id="s"
        />
        <filter
          x="-7.4%"
          y="-23.5%"
          width="117.8%"
          height="143.2%"
          filterUnits="objectBoundingBox"
          id="r"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M236.773 103.61h592.113v237.138H236.773c-65.484 0-118.57-53.085-118.57-118.569 0-65.483 53.086-118.568 118.57-118.568z"
          id="u"
        />
        <filter
          x="-7.9%"
          y="-30.8%"
          width="118.9%"
          height="156.5%"
          filterUnits="objectBoundingBox"
          id="t"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M252.095 139.364h576.791v165.631h-576.79c-45.739 0-82.816-37.078-82.816-82.816 0-45.737 37.077-82.815 82.815-82.815z"
          id="w"
        />
        <filter
          x="-8.5%"
          y="-44.1%"
          width="120.3%"
          height="180.9%"
          filterUnits="objectBoundingBox"
          id="v"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M214.883 176.576h614.003v91.207H214.883c-25.186 0-45.603-20.418-45.603-45.604s20.417-45.603 45.603-45.603z"
          id="y"
        />
        <filter
          x="-8.5%"
          y="-80.0%"
          width="120.3%"
          height="246.9%"
          filterUnits="objectBoundingBox"
          id="x"
        >
          <feOffset
            dx={11}
            dy={-6}
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={20.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.774103579 0 0 0 0 0.517687625 0 0 0 0 0.0858291769 0 0 0 1 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        transform="translate(-177 -563) translate(177.082 563.819)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#F2A626" xlinkHref="#a" />
        <g mask="url(#b)">
          <g fill="none" transform="matrix(0 1 1 0 16.597 -3.633)">
            <use fill="#000" filter="url(#c)" xlinkHref="#d" />
            <use fill="#F2A626" xlinkHref="#d" />
          </g>
          <g fill="none" transform="matrix(0 1 1 0 16.597 -3.633)">
            <use fill="#000" filter="url(#e)" xlinkHref="#f" />
            <use fill="#F2A626" xlinkHref="#f" />
          </g>
          <g fill="none" transform="matrix(0 1 1 0 16.597 -3.633)">
            <use fill="#000" filter="url(#g)" xlinkHref="#h" />
            <use fill="#F2A626" xlinkHref="#h" />
          </g>
          <g fill="none" transform="matrix(0 1 1 0 16.597 -3.633)">
            <use fill="#000" filter="url(#i)" xlinkHref="#j" />
            <use fill="#F2A626" xlinkHref="#j" />
          </g>
          <g fill="none" transform="matrix(0 1 1 0 16.597 -3.633)">
            <use fill="#000" filter="url(#k)" xlinkHref="#l" />
            <use fill="#F2A626" xlinkHref="#l" />
          </g>
          <path
            d="M222.18 0h605.247v444.359H222.179C99.473 444.359 0 344.886 0 222.179 0 99.473 99.473 0 222.18 0z"
            fill="url(#m)"
            fillRule="evenodd"
            transform="matrix(0 1 1 0 16.597 -3.633)"
          />
        </g>
        <g mask="url(#b)">
          <g transform="translate(-7.027)">
            <mask id="o" fill="#fff">
              <use xlinkHref="#n" />
            </mask>
            <g
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd"
              mask="url(#o)"
            >
              <g fill="none" transform="rotate(-127 313.087 66.447)">
                <use fill="#000" filter="url(#p)" xlinkHref="#q" />
                <use fill="#F2A626" xlinkHref="#q" />
              </g>
              <g fill="none" transform="rotate(-127 313.087 66.447)">
                <use fill="#000" filter="url(#r)" xlinkHref="#s" />
                <use fill="#F2A626" xlinkHref="#s" />
              </g>
              <g fill="none" transform="rotate(-127 313.087 66.447)">
                <use fill="#000" filter="url(#t)" xlinkHref="#u" />
                <use fill="#F2A626" xlinkHref="#u" />
              </g>
              <g fill="none" transform="rotate(-127 313.087 66.447)">
                <use fill="#000" filter="url(#v)" xlinkHref="#w" />
                <use fill="#F2A626" xlinkHref="#w" />
              </g>
              <g fill="none" transform="rotate(-127 313.087 66.447)">
                <use fill="#000" filter="url(#x)" xlinkHref="#y" />
                <use fill="#F2A626" xlinkHref="#y" />
              </g>
              <path
                d="M222.18 0h605.247v444.359H222.179C99.473 444.359 0 344.886 0 222.179 0 99.473 99.473 0 222.18 0z"
                fill="url(#m)"
                fillRule="evenodd"
                transform="rotate(-127 313.087 66.447)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}