import { Box, Stack, styled } from "@mui/material";
import theme from "../../../styles/theme";

export const HowItWorksBg = styled(Box)`
  position: relative;
  z-index: 1;
  background-color: #fff;
  //height: 100rem;
 transform: translate(0,-30rem);
  padding-top: 43rem;
  border-bottom-right-radius: 32rem;
  
  ${theme.breakpoints.down('br768')}{
    border-bottom-right-radius: 10rem;
  }
  
  > div{
    position: relative;
    padding-bottom: 20rem;

    ${theme.breakpoints.down('br768')}{
      padding-bottom: 10vw;
    }
    
    svg > path{
      fill: #fff;
    }
  }
  
  h2{
    font-family: 'F37 Judge', sans-serif;
    font-size: 2.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.01px;
    color: #010a10;
    margin-bottom: 2.3rem;
  }
  
  p{
    line-height: 1.25;
  }
  
  text{
    position: relative;
    z-index: 10;
  }
`

export const HowItWorksListMobile = styled(Stack)`
  
  p{
    font-size: 2.2rem;
    white-space: pre-wrap;
    margin-bottom: 2rem;
  }
  
img{
  margin-bottom: 15vw;
}
  
`