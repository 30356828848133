import { Box, styled } from "@mui/material";
import theme from "../theme";

export const SectionContainer = styled(Box)`
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
  width: 90%;
  ${theme.breakpoints.down('xl')} {
    max-width: 90%;
    width: 90%;
  }
  ${theme.breakpoints.down('br768')} {
    max-width: 85%;
  }
`;

export const NFTSectionContainer = styled(SectionContainer)`
  max-width: 114.4rem;
  //max-width: 144rem;
  
  
  .mobMask{
    > path{
      fill: #F4EDE4;
    }
  }
`

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const SvgBox = styled(Box)`
  & svg {
    width: 100%;
    //height: 100%;
  }
`

export const ImgBox = styled(Box)`
  & img {
    width: 100%;
    //height: 100%;
  }
`
