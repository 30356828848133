import { styled, Button, Link } from "@mui/material";
import theme from "../theme";

export const MainButton = styled(Button)`
  background-color: ${theme.palette.secondary.main};
  color: #ffffff;
  border-radius: 50px;
  padding: 2.2rem 4.8rem;
  font-size: 2.2rem;
  letter-spacing: 0.29px;
  text-transform: unset;
  font-weight: 600;
  svg {
    height: 4.5rem;
  }
  &:hover {
    background-color: ${theme.palette.secondary.main};
  }

  &:disabled {
    background-color: #ccc;
  }
`;

export const LinkDiscord = styled(Link)`
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: 0.22px;
  color: #195afe;
  cursor: pointer;
  z-index: 2;
`;

export const SecondaryButton = styled(Button)`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.22px;
  text-transform: none;
  text-decoration: underline;
  color: #195afe;
  transition: 0.3s;
  &:hover {
    text-decoration: underline;
    background-color: transparent;
    opacity: 0.8;
  }
`;
