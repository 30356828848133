//Companies
import KyberNetwork from '../assets/img/partners/logos/Kyber Network.webp'
import KyberNetworkLogo from '../assets/img/partners/logos/Kyber Network logo.webp'
import AxieInfinity from '../assets/img/partners/logos/Axie Infinity (AXS).webp'
import AxieInfinityLogo from '../assets/img/partners/logos/Axie Infinity (AXS) logo.webp'
import Vee from '../assets/img/partners/logos/vee.webp'
import VeeLogo from '../assets/img/partners/logos/veeFriends.webp'
import Bitdao from '../assets/img/partners/logos/bitdao.webp'
import BitdaoLogo from '../assets/img/partners/logos/bitdao_logo.webp'
import Nansen from '../assets/img/partners/logos/nansen.webp'
import NansenLogo from '../assets/img/partners/logos/nansen_logo.webp'
import Ripple from '../assets/img/partners/logos/ripple.webp'
import RippleLogo from '../assets/img/partners/logos/ripple logo.webp'
import Sandbox from '../assets/img/partners/logos/Sandbox.webp'
import SandboxLogo from '../assets/img/partners/logos/Sandbox (SAND).webp'
import Uniswap from '../assets/img/partners/logos/uniswap.webp'
import UniswapLogo from '../assets/img/partners/logos/Uniswap (UNI).webp'
//Creatives
import Creative3 from '../assets/img/creatives/creative3.webp'
import Creative6 from '../assets/img/creatives/Depositphotos_130855816_S.jpg'
import Creative7 from '../assets/img/creatives/Depositphotos_138448862_S.jpg'
import Creative8 from '../assets/img/creatives/Depositphotos_148537285_S.jpg'
import Creative9 from '../assets/img/creatives/Depositphotos_156951870_S.jpg'
import Creative10 from '../assets/img/creatives/Depositphotos_158963922_S.jpg'
import Creative11 from '../assets/img/creatives/Depositphotos_177502754_S.jpg'
import Creative12 from '../assets/img/creatives/Depositphotos_177765702_S.jpg'
import Creative13 from '../assets/img/creatives/Depositphotos_180070738_S.jpg'
import Creative14 from '../assets/img/creatives/Depositphotos_180370064_S.jpg'
import Creative15 from '../assets/img/creatives/Depositphotos_182718642_S.jpg'
import Creative16 from '../assets/img/creatives/Depositphotos_183265774_S.jpg'
import Creative18 from '../assets/img/creatives/Depositphotos_195808964_S.jpg'
import Creative19 from '../assets/img/creatives/Depositphotos_206522260_S.jpg'
import Creative20 from '../assets/img/creatives/Depositphotos_206992842_S.jpg'
import Creative21 from '../assets/img/creatives/Depositphotos_216897218_S.jpg'
import Creative22 from '../assets/img/creatives/Depositphotos_224762738_S.jpg'
import Creative25 from '../assets/img/creatives/Depositphotos_250176274_S.jpg'
import Creative27 from '../assets/img/creatives/Depositphotos_287710030_S.jpg'
import Creative29 from '../assets/img/creatives/Depositphotos_316649110_S.jpg'
import Creative30 from '../assets/img/creatives/Depositphotos_317658106_S.jpg'
import Creative31 from '../assets/img/creatives/Depositphotos_318996972_S.jpg'
import Creative32 from '../assets/img/creatives/Depositphotos_322807618_S.jpg'
import Creative33 from '../assets/img/creatives/Depositphotos_334182742_S.jpg'
import Creative34 from '../assets/img/creatives/Depositphotos_344777872_S.jpg'
import Creative35 from '../assets/img/creatives/Depositphotos_351678720_S.jpg'
import Creative36 from '../assets/img/creatives/Depositphotos_366408696_S.jpg'
import Creative37 from '../assets/img/creatives/Depositphotos_381454792_S.jpg'
import Creative38 from '../assets/img/creatives/Depositphotos_384456116_S.jpg'
import Creative39 from '../assets/img/creatives/Depositphotos_395207070_S.jpg'
import Creative40 from '../assets/img/creatives/Depositphotos_398714064_S.jpg'
import Creative41 from '../assets/img/creatives/Depositphotos_454862958_S.jpg'
import Creative42 from '../assets/img/creatives/Depositphotos_457420956_S.jpg'
import Creative43 from '../assets/img/creatives/Depositphotos_534555506_S.jpg'
import Creative44 from '../assets/img/creatives/Depositphotos_544494654_S.jpg'

export const data = {
    defi: {
        brandName: 'Kyber Network',
        logo: KyberNetworkLogo,
        brandLogo: KyberNetwork,
        sentimentData: [
            [
                {
                    name: 'Mason D.',
                    text: 'KyberSwap for the best rates!',
                },
                {
                    name: 'Noah V.',
                    text: 'Love the governence terms of this!'
                },
                {
                    name: 'Michael S.',
                    text: '@KyberNetwork Whyy is the media silent about thiss???'
                }
            ],
            [
                {
                    name: 'Jacob E.',
                    text: '@KyberNetwork @0xPolygon but team will do nothing of course , coz this is "DECENTRALIZED"',
                },
                {
                    name: 'Alexznder B.',
                    text: '@KyberNetwork @0xPolygon Me and my community not win anyone.. I think random winners trading volume minimum 2000$',
                },
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 2,
                title: 'We move markets.',
                description: 'Liquidity for every token.\n' +
                    '\n' +
                    'Send, receive and swap your tokens in one place.\n' +
                    '\n' +
                    'Built to scale transparently.',
                image: Creative42,
                numbers: [{
                    name: 'spent',
                    value: 13000
                }, {
                    name: 'views',
                    value: 200000
                }, {
                    name: 'clicks',
                    value: 11728
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 1,
                title: 'The key to crypto trading.',
                description: 'Trade at the best rate.\n' +
                    '\n' +
                    'Kyber Network Crystal v2.',
                image: Creative30,
                numbers: [{
                    name: 'spent',
                    value: 30000
                }, {
                    name: 'views',
                    value: 150000
                }, {
                    name: 'clicks',
                    value: 25240
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 4,
                title: 'Try it now.',
                description: 'Instant, secure and trustless.\n' +
                    '\n' +
                    'The future needs you.',
                image: Creative33,
                numbers: [{
                    name: 'spent',
                    value: 5000
                }, {
                    name: 'views',
                    value: 15000
                }, {
                    name: 'clicks',
                    value: 5800
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 1,
                title: `Kyber Network Crystal v2.`,
                description: `Ad https://kyber.network/\n` +
                    `Best rates for decentralized applications and users.\n` +
                    `\n` +
                    `Secure and instant`,
                image: Creative15,
                numbers: [{
                    name: 'spent',
                    value: 13000
                }, {
                    name: 'views',
                    value: 32000
                }, {
                    name: 'clicks',
                    value: 10334
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: `The world\'s liquidity protocol.`,
                description: `Ad https://kyber.network/\n` +
                    `Instant, seamless, and secure.\n` +
                    `\n` +
                    `A new era of crypto trading.`,
                image: Creative41,
                numbers: [{
                    name: 'spent',
                    value: 8202
                }, {
                    name: 'views',
                    value: 39000
                }, {
                    name: 'clicks',
                    value: 7829
                }],
            },
        ],
    },
    ['decentralized exchange']: {
        brandName: 'Uniswap',
        logo: UniswapLogo,
        brandLogo: Uniswap,
        sentimentData: [
            [
                {
                    name: 'William A.',
                    text: 'Tried all the alternatives. @uniswap still the best in market.'
                },
                {
                    name: 'Liam N.',
                    text: 'This platform is absolutely amazing! '
                },
                {
                    name: 'Ethan G.',
                    text: 'Glad to have great leaders in this market. Great project with great support!'
                }
            ],
            [
                {
                    name: 'Emma A.',
                    text: 'Hex.com is up 1,000,000% in 623 days\n' +
                        '@Uniswap @stevibiel @BoysClubCrypto Pools and tokens aren’t loading on Eth chain. What’s going on?',
                },
                {
                    name: 'Henry O.',
                    text: '@Uniswap @Uniswap The uniswap browser crashed, please solve it as soon as possible, thank you',
                },
                {
                    name: 'Christina V.',
                    text: '@Uniswap If I straked my money I could get a better return rather it site in the bank getting close to nothing',
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 5,
                title: 'Trade your crypto for faster and cheaper.',
                description: 'Trade anytime, anywhere.\n' +
                    '\n' +
                    'Swap, earn and build.',
                image: Creative11,
                numbers: [{
                    name: 'spent',
                    value: 20000
                }, {
                    name: 'views',
                    value: 100000
                }, {
                    name: 'clicks',
                    value: 17600
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 2,
                title: 'Uniswap now!',
                description: 'Trade everything in one place.\n' +
                    '\n' +
                    'Secure, fast and reliable.',
                image: Creative27,
                numbers: [{
                    name: 'spent',
                    value: 15000
                }, {
                    name: 'views',
                    value: 100000
                }, {
                    name: 'clicks',
                    value: 12000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 4,
                title: 'Uniswap now!',
                description: 'Decentralized protocol.\n' +
                    '\n' +
                    'Every swap counts.',
                image: Creative18,
                numbers: [{
                    name: 'spent',
                    value: 25000
                }, {
                    name: 'views',
                    value: 280000
                }, {
                    name: 'clicks',
                    value: 20800
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 4,
                title: `Trade without limits.`,
                description: 'Decentralized protocol.\n' +
                    '\n' +
                    'Every swap counts.',
                image: Creative39,
                numbers: [{
                    name: 'spent',
                    value: 10000
                }, {
                    name: 'views',
                    value: 110000
                }, {
                    name: 'clicks',
                    value: 10300
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 1,
                title: `Uniswap.`,
                description: 'Decentralized trading protocol.\n' +
                    '\n' +
                    'Leading decentralized crypto trading protocol that enables anyone to trade any ERC-20 token.',
                image: Creative3,
                numbers: [{
                    name: 'spent',
                    value: 312412
                }, {
                    name: 'views',
                    value: 200100
                }, {
                    name: 'clicks',
                    value: 12341241
                }],
            },
        ],
    },
    ['blockchain companies']: {
        brandName: 'Ripple',
        logo: RippleLogo,
        brandLogo: Ripple,
        sentimentData: [
            [
                {
                    name: 'Joseph E.',
                    text: 'Xrp is a standard for me'
                },
                {
                    name: 'Alex R.',
                    text: `Ooh you're making my hub liquid @ripple`
                },
                {
                    name: 'Daniel V.',
                    text: 'I smell a huge announcement! #XRP'
                }
            ],
            [
                {
                    name: 'Diana T.',
                    text: 'let\'s raise the issue of burning ripple🔥'
                },
                {
                    name: 'Josh B.',
                    text: 'For me @ripple is not relevant anymore...there are better alternatives'
                },
                {
                    name: 'Josh B.',
                    text: 'For me @ripple is not relevant anymore...there are better alternatives'
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 4,
                title: 'Blockchain for Developers',
                description: 'Bringing Crypto to the Masses',
                image: Creative7,
                numbers: [{
                    name: 'spent',
                    value: 23000
                }, {
                    name: 'views',
                    value: 300000
                }, {
                    name: 'clicks',
                    value: 31000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 1,
                title: 'Try Ripple',
                description: 'Instant Payments, Low Fees',
                image: Creative31,
                numbers: [{
                    name: 'spent',
                    value: 34000
                }, {
                    name: 'views',
                    value: 340000
                }, {
                    name: 'clicks',
                    value: 25000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 2,
                title: 'Get to Know Ripple',
                description: 'Get Free Instant Access to Our Library of Crypto Resources.',
                image: Creative40,
                numbers: [{
                    name: 'spent',
                    value: 12000
                }, {
                    name: 'views',
                    value: 100000
                }, {
                    name: 'clicks',
                    value: 15000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 1,
                title: `Build Apps on XRP Ledger`,
                description: 'Create a Crypto Payment Button using the Crypto Toolkit',
                image: Creative38,
                numbers: [{
                    name: 'spent',
                    value: 52000
                }, {
                    name: 'views',
                    value: 750000
                }, {
                    name: 'clicks',
                    value: 50000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: `Reduce Transaction Fees`,
                description: 'Ripple Trusted By Banks And Leading Payment',
                image: Creative32,
                numbers: [{
                    name: 'spent',
                    value: 32000
                }, {
                    name: 'views',
                    value: 200000
                }, {
                    name: 'clicks',
                    value: 22000
                }],
            },
        ],
    },
    gaming: {
        brandName: 'Axie infinity',
        logo: AxieInfinityLogo,
        brandLogo: AxieInfinity,
        sentimentData: [
            [
                {
                    name: 'Grace R.',
                    text: 'great idols in axie community..❤️❤️'
                },
                {
                    name: 'Edwin P.',
                    text: 'I love how everyone talks but only a few actually execute. I believe in @Axieinfinity. We didn\'t see anything yet'
                },
                {
                    name: 'Sohan L.',
                    text: '@AxieInfinity Best game in the ecosystem!'
                }

            ],
            [
                {
                    name: 'Danut Alexandru',
                    text: '@AxieInfinity Solve the fcking game, is stupid.🤦🤦🤦'
                },
                {
                    name: 'JUAN DIEGO MONTOYA NATERA',
                    text: '@AxieInfinity We are not interested in that shit, what we want is for this game to be more fun and not that to be good you have to spend 500 dollars that you never recover in this'
                },

            ]
        ],
        creatives: [
            {
                channel: 'bitmedia',
                group_num: 3,
                title: 'Join the revolution with axie infinity!',
                description: 'The revolution of blockchain gaming.\n' +
                    '\n' +
                    'Collect and trade rare, one-of-a-kind digital pets.\n' +
                    '\n' +
                    'Earn Axies and have your pet become more powerful.\n' +
                    '\n' +
                    'It\'s a world of battle and play!',
                image: Creative8,
                numbers: [{
                    name: 'spent',
                    value: 50000
                }, {
                    name: 'views',
                    value: 500000
                }, {
                    name: 'clicks',
                    value: 40000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 5,
                title: 'Be part of the revolution Join the blockchain gaming play.',
                description: 'A blockchain game that is easy to play but hard to master.\n' +
                    '\n' +
                    'Axie Infinity is a decentralized game on Ethereum and Blockchain.',
                image: Creative20,
                numbers: [{
                    name: 'spent',
                    value: 15000
                }, {
                    name: 'views',
                    value: 180000
                }, {
                    name: 'clicks',
                    value: 10000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: 'Revolution is coming.',
                description: 'Fight for your Axie against other players and win.\n' +
                    '\n' +
                    'Earn Axie tokens and revolutionize the gaming industry.',
                image: Creative12,
                numbers: [{
                    name: 'spent',
                    value: 50000
                }, {
                    name: 'views',
                    value: 650000
                }, {
                    name: 'clicks',
                    value: 50000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 1,
                title: `Best game to earn!`,
                description: 'Win your opponents, Earn Axie tokens and revolutionize the gaming industry.',
                image: Creative10,
                numbers: [{
                    name: 'spent',
                    value: 50000
                }, {
                    name: 'views',
                    value: 340000
                }, {
                    name: 'clicks',
                    value: 40000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 2,
                title: `Make the revolution with us!`,
                description: 'Collect and trade rare, one-of-a-kind digital pets.\n' +
                    '\n' +
                    'Earn Axies and have your pet become more powerful.\n' +
                    '\n' +
                    'It\'s a world of battle and play!"',
                image: Creative36,
                numbers: [{
                    name: 'spent',
                    value: 60000
                }, {
                    name: 'views',
                    value: 550000
                }, {
                    name: 'clicks',
                    value: 60000
                }],
            },
        ],
    },
    metaverse: {
        brandName: 'Sandbox',
        logo: SandboxLogo,
        brandLogo: Sandbox,
        sentimentData: [
            [
                {
                    name: 'Sam J.',
                    text: 'Welcome to Hanjin Music Palooza (@Hanjintan) - a colorful amusement park with oversized musical instrument buildings built on @TheSandboxGame'
                },
                {
                    name: 'Calvin H.',
                    text: `Once the market will be bullish again, @TheSandboxGame will be skyrocketing 100%️`
                },
                {
                    name: 'Olivia Y.',
                    text: 'What better metaverses there is? can someone name one?'
                }
            ],
            [
                {
                    name: 'Charlie D.',
                    text: '500 sand is worth the shit'
                },
                {
                    name: 'Mia I.',
                    text: `@TheSandboxGame @Hanjintan @Indexgamehk When does the mobile come? It doesn\'t look fun on the desktop`
                },
                {
                    name: 'Jacob F.',
                    text: '@TheSandboxGame @Hanjintan @Indexgamehk having some issues with this one quest doesn\'t progress passed the guitar ❤️'
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 3,
                title: 'The Sandbox for creative monetization',
                description: 'A way to monetize your creative work.\n' +
                    '\n' +
                    'Accessible from anywhere and anytime.\n' +
                    '\n' +
                    'Monetize your art in the blockchain!',
                image: Creative44,
                numbers: [{
                    name: 'spent',
                    value: 50000
                }, {
                    name: 'views',
                    value: 600000
                }, {
                    name: 'clicks',
                    value: 65000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 5,
                title: 'Community-driven platform',
                description: 'Creators can monetize voxel ASSETS and gaming experiences on the blockchain',
                image: Creative43,
                numbers: [{
                    name: 'spent',
                    value: 16000
                }, {
                    name: 'views',
                    value: 140000
                }, {
                    name: 'clicks',
                    value: 9000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: 'Sandbox Metaverse',
                description: 'There will only ever be 166,464 LANDS available, which can be used to host games, build multiplayer experiences, create housing, or offer social experiences to the community',
                image: Creative19,
                numbers: [{
                    name: 'spent',
                    value: 41000
                }, {
                    name: 'views',
                    value: 430000
                }, {
                    name: 'clicks',
                    value: 28000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 1,
                title: `Sandbox NFT Marketplace`,
                description: 'Discover Collections, get Equipment, or find assets to build your own unique Experiences.\n',
                image: Creative10,
                numbers: [{
                    name: 'spent',
                    value: 33000
                }, {
                    name: 'views',
                    value: 550000
                }, {
                    name: 'clicks',
                    value: 52000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 2,
                title: `Building games for the Metaverse`,
                description: 'Anyone can build 3D games for free. No coding required. Contribute to a vast metaverse, filled with amazing creations and experiences.',
                image: Creative35,
                numbers: [{
                    name: 'spent',
                    value: 38000
                }, {
                    name: 'views',
                    value: 203000
                }, {
                    name: 'clicks',
                    value: 30000
                }],
            },
        ],
    },
    nft: {
        brandName: 'Veefriends',
        logo: VeeLogo,
        brandLogo: Vee,
        sentimentData: [
            [
                {
                    name: 'Benjamin L.',
                    text: 'I love the veefriends community it’s a beautiful way to push love to the world and it’s absolutely free🤜🏽🤛🏽🔥🔥🔥'
                },
                {
                    name: 'Charlie E.',
                    text: '@garyvee I know you spread love every day of your life but this would be an amazing honor if he jumped in on this movement just like the \n' +
                        '@veefriends community has'
                },
                {
                    name: 'Anna R.',
                    text: 'A great community with amazing humans trying to spread a great message! ♥️'
                }
            ],
            [
                {
                    name: 'Mary J.',
                    text: 'Am I the only one not believing in what @veefriends is trying to sell?!'
                },
                {
                    name: 'Edwin O.',
                    text: `This project will fall eventually`
                },
                {
                    name: 'Jacob F.',
                    text: 'Veefriends value decreases with time...'
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 5,
                title: 'Be an NFT Pioneer',
                description: 'Own a piece of the Internet',
                image: Creative19,
                numbers: [{
                    name: 'spent',
                    value: 25000
                }, {
                    name: 'views',
                    value: 200000
                }, {
                    name: 'clicks',
                    value: 27000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: 'Join the VeeFriends Community',
                description: 'Shop for a wide range of NFTs.',
                image: Creative13,
                numbers: [{
                    name: 'spent',
                    value: 18000
                }, {
                    name: 'views',
                    value: 100000
                }, {
                    name: 'clicks',
                    value: 7000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 3,
                title: 'VeeFriends Experiences',
                description: 'Join VeeFriends and Get Access to VeeCon.',
                image: Creative37,
                numbers: [{
                    name: 'spent',
                    value: 34000
                }, {
                    name: 'views',
                    value: 320000
                }, {
                    name: 'clicks',
                    value: 15000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 1,
                title: `Own a Gary Vee VeeFriend`,
                description: 'Own A Piece of History. ',
                image: Creative16,
                numbers: [{
                    name: 'spent',
                    value: 50000
                }, {
                    name: 'views',
                    value: 700000
                }, {
                    name: 'clicks',
                    value: 68000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 4,
                title: `Collect Unique Crypto Art`,
                description: 'Get Your Own NFT Now.',
                image: Creative21,
                numbers: [{
                    name: 'spent',
                    value: 6000
                }, {
                    name: 'views',
                    value: 70000
                }, {
                    name: 'clicks',
                    value: 8000
                }],
            },
        ],
    },
    ['crypto analytics tools']: {
        brandName: 'Nansen',
        logo: NansenLogo,
        brandLogo: Nansen,
        sentimentData: [
            [
                {
                    name: 'Elizabeth K.',
                    text: '@nansen_ai absolutely love it 🔥🔥🔥'
                },
                {
                    name: 'Eva J.',
                    text: 'just uncovered some forgotten positions that were staked ❤️❤️❤️'
                },
                {
                    name: 'Dennis S.',
                    text: 'My quarterly membership has expired, but I will continue to renew it for sure'
                }
            ],
            [
                {
                    name: 'Harry N.',
                    text: 'I tried joining your community but it seems too dry and boring...what\'s the value?'
                },
                {
                    name: 'George E.',
                    text: 'Your platform is so complicated...try make it more accessible'
                },
                {
                    name: 'Nick M.',
                    text: 'sounds ok...I know of better tools to use'
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 1,
                title: 'Discover new investment ',
                description: 'See where funds are moving to, identify new projects or tokens, and trace transactions down to the most granular level.',
                image: Creative29,
                numbers: [{
                    name: 'spent',
                    value: 20000
                }, {
                    name: 'views',
                    value: 100000
                }, {
                    name: 'clicks',
                    value: 17600
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 4,
                title: 'Perform Due Diligence',
                description: `Develop your edge and make informed investment decisions by tracking the top holders of a NFT or token.`,
                image: Creative16,
                numbers: [{
                    name: 'spent',
                    value: 25000
                }, {
                    name: 'views',
                    value: 200000
                }, {
                    name: 'clicks',
                    value: 27000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 3,
                title: 'Defend Your Positions',
                description: 'Play bear market defense with custom alerts and get notified when key wallets move funds.',
                image: Creative34,
                numbers: [{
                    name: 'spent',
                    value: 28000
                }, {
                    name: 'views',
                    value: 220000
                }, {
                    name: 'clicks',
                    value: 22000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 2,
                title: `Surface the Signal`,
                description: 'Track gas usage to understand where the action is happening',
                image: Creative25,
                numbers: [{
                    name: 'spent',
                    value: 13000
                }, {
                    name: 'views',
                    value: 98000
                }, {
                    name: 'clicks',
                    value: 7000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 4,
                title: `Follow the Smart Money`,
                description: `Every trade from the top performers, market makers, and funds is at your fingertips! Track and follow the top crypto investors.`,
                image: Creative14,
                numbers: [{
                    name: 'spent',
                    value: 10000
                }, {
                    name: 'views',
                    value: 42000
                }, {
                    name: 'clicks',
                    value: 12000
                }],
            },
        ],
    },
    other: {
        brandName: 'BitDAO',
        logo: BitdaoLogo,
        brandLogo: Bitdao,
        sentimentData: [
            [
                {
                    name: 'Leo K.',
                    text: '@BitDAO_Official ✅Massive marketing ✅AMA tours ✅Huge strategic partners ✅Audit, KYC passed ✅Game DApp'
                },
                {
                    name: 'Anthony T.',
                    text: 'Best DAO in the game!'
                },
                {
                    name: 'Alice N.',
                    text: 'After joining some of the latest spaces with those guys I can tell you they are serious 😎️'
                }
            ],
            [
                {
                    name: 'Ann E.',
                    text: `BIT GO down to next zero`
                },
                {
                    name: 'Matthew O.',
                    text: `This narrative seems misleading. 90% of the funds will go towards creating a VC that doesn't even commit to investing in web3 projects (or even African for that matter).`
                },
                {
                    name: 'Alex Y.',
                    text: 'Don\'t buy bit dao'
                }
            ]
        ],
        creatives: [
            {
                channel: 'coinzilla',
                group_num: 2,
                title: 'A Decentralized, Open-Source Protocol',
                description: 'Built on the blockchain',
                image: Creative6,
                numbers: [{
                    name: 'spent',
                    value: 18000
                }, {
                    name: 'views',
                    value: 87000
                }, {
                    name: 'clicks',
                    value: 14000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 1,
                title: 'Build a Crypto Company',
                description: 'Make Your Ideas Happen',
                image: Creative41,
                numbers: [{
                    name: 'spent',
                    value: 18000
                }, {
                    name: 'views',
                    value: 144000
                }, {
                    name: 'clicks',
                    value: 11000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 1,
                title: 'Build and Manage Dapps',
                description: 'Create your own digital currency',
                image: Creative9,
                numbers: [{
                    name: 'spent',
                    value: 29000
                }, {
                    name: 'views',
                    value: 230000
                }, {
                    name: 'clicks',
                    value: 23000
                }],
            },
            {
                channel: 'bitmedia',
                group_num: 4,
                title: `Play with $BIT`,
                description: 'Purchase $BIT Tokens to Become a BitDAO Citizen',
                image: Creative22,
                numbers: [{
                    name: 'spent',
                    value: 28000
                }, {
                    name: 'views',
                    value: 420000
                }, {
                    name: 'clicks',
                    value: 46000
                }],
            },
            {
                channel: 'coinzilla',
                group_num: 3,
                title: `Join the BitDAO Ecosystem`,
                description: 'A collective of builders enabling Web3 ecosystems',
                image: Creative36,
                numbers: [{
                    name: 'spent',
                    value: 19000
                }, {
                    name: 'views',
                    value: 111000
                }, {
                    name: 'clicks',
                    value: 14000
                }],
            },
        ],
    },
}
