import {Box, Button, Divider, Grid, Stack, styled, Typography} from "@mui/material";
import {FlexBox, ImgBox} from "../../styles/components/Layout";
import theme from "../../styles/theme";
import Link from "react-scroll/modules/components/Link";

export const CampaignTitle = styled(Typography)`
  ${theme.breakpoints.down('br768')} {
    text-align: center;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 4rem;
  }
`

export const Subtitle = styled(Typography)`
  max-width: 70rem;
  margin-top: 2rem;
  margin-bottom: 6.62rem;

  ${theme.breakpoints.down('br768')} {
    text-align: center;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 1.8rem;
  }
`

export const NichesBlock = styled(Grid)`
  justify-content: center;
  margin-top: 11.23rem;
  margin-bottom: 19rem;
  position: relative;
`

export const NichesMobileBlock = styled(Grid)`
  margin-bottom: 12rem;
  position: relative;
`
export const LinkStyled = styled(Link)`
  margin-bottom: 2.8rem;
  text-decoration: unset;
  &:visited {
    color: inherit;
  }
  ${theme.breakpoints.down('br768')} {
    width: 100%;
    margin-bottom: 0;
  }
`

const ButtonNiche = styled(Button)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 2.52rem;
  width: fit-content;
  font-weight: bold;
  letter-spacing: 1.12px;
  padding: 4.15rem 6.2rem;
  color: ${props => props.active === props.i ? '#636E95' : '#FFFFFF'};
  border: 4.2px ${props => props.active === props.i ? 'dashed #636E95' : 'solid #FFFFFF'};
  box-shadow: ${props => props.active === props.i ? 'unset' : '0 5px 17px 0 rgba(0, 0, 0, 0.17)'};
  background-color: ${props => props.active === props.i ? '#FFFFFF' : props.background};
  
  &:hover {
    background-color: ${props => props.active === props.i ? '#FFFFFF' : props.background};
    box-shadow: unset;
  }

  ${theme.breakpoints.down('br768')} {
    width: 100%;
    border: 3.6px ${props => props.active === props.i ? 'dashed #636E95' : 'solid #FFFFFF'};
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 2rem;
    padding: 3.2rem 0;
    border: 2.8px ${props => props.active === props.i ? 'dashed #636E95' : 'solid #FFFFFF'};
  }
`
export const ButtonLeft = styled(ButtonNiche)`
  border-radius: 0 4rem;
  margin-right: ${props => (props.active && props.active < props.i) ? '2.2rem' : 0};
  transition: 0.3s;
`

export const ButtonRight = styled(ButtonNiche)`
  border-radius: 4rem 0;
  margin-left: ${props => (props.active && props.active < props.i && props.active > 4) ? '2.8rem' : 0};
  transition: 0.3s;
`

export const StackLeft = styled(Stack)`
  margin-right: 2rem;
  align-items: end;
  position: relative;
`
export const StackRight = styled(Stack)`
  position: relative;
`

export const DividerDashed = styled(Divider)`
  border-right: 4px dashed #636E95;
  height: ${props => `calc(${4 - props.active} * 25% + 15rem)`};
  position: absolute;
  width: 0;
  opacity: 1;
  top: ${props => `calc(${props.active} * 25% - 2.7rem)`};
  animation: 0.3s linear 0s down;
  display: ${props => props.active !== props.i && 'none'};

  @keyframes down {
    from {
      height: 0;
      opacity: 0;
    }
    to {
      height: ${props => `calc(${4 - props.active} * 25% + 16rem)`};
      opacity: 1;
    }
  }

  ${theme.breakpoints.down('br768')} {
    display: none;
  }
`

export const Ball = styled(Box)`
  position: absolute;
  margin: 0 auto;
  top: calc(100% + 12.4rem);
  width: 3.32rem;
  height: 3.32rem;
  border: solid 4px #636e95;
  background-color: #FFFFFF;
  z-index: 4;
  border-radius: 5rem;
  transform: ${props => props.right ? 'translateX(-45%)' : 'translateX(45%)'};
  animation: 0.4s view;
  opacity: ${props => props.active !== props.i && 0};
  @keyframes view {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${theme.breakpoints.down('br768')} {
    display: none;
  }
`

export const CompanyNameBox = styled(ImgBox)`
  border: 4px dashed #636E95;
  position: absolute;
  padding: 2.6rem 4rem;
  top: calc(100% + 13.7rem);
  margin: 0 auto;
  transform: ${props => props.right ? 'translateX(-50%)' : 'translateX(50%)'};
  width: 28.5rem;
  height: ${props => props.niche === 'defi' ? '12.5rem' :
          props.niche === 'decentralized exchange' ? '10.2rem' : 
          props.niche === 'blockchain companies' ? '10.5rem' :
          props.niche === 'gaming' ? '12.5rem' :
          props.niche === 'metaverse' ? '11.5rem' :
          props.niche === 'nft' ? '11rem' :
          props.niche === 'crypto analytics tools' ? '11rem' : '10.2rem'};
  z-index: 3;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 1.26rem;
  animation: 0.4s view;
  opacity: ${props => props.active !== props.i && 0};

  & img {
    width: unset;
    height: 100%;
    max-width: 100%;
  }

  @keyframes view {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${theme.breakpoints.down('br768')} {
    top: calc(100% + 6.5rem);
    left: 50%;
    animation: unset;
    transform: translateX(-45%);
    border: 3.6px dashed #636E95;
  }

  ${theme.breakpoints.down('sm')} {
    top: calc(100% + 7.5rem);
    border: 2.8px dashed #636E95;
    width: 20rem;
    height: 8rem;
    padding: 2rem 3rem 2rem 3rem;

  }
`
