import { Paper, styled } from "@mui/material";
import { Box } from "@mui/system";
import { FlexBox } from "../../styles/components/Layout";
import theme from "../../styles/theme";

export const FAQContentBackground = styled(Paper)`
  background-color: #f4ede4;
  width: 100vw;
  padding: 9rem 0 21rem;

  h4 {
    font-size: 2.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.5px;
    color: #636e95;
    margin-bottom: 2.4rem;
  }
`;

export const FAQTitleContainer = styled(FlexBox)`
  margin: 7.7rem 10rem 7.7rem 0;

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    margin-right: 0;
    p {
      text-align: center;
    }
    h1 {
      text-align: center;
      margin: 0 auto;
    }

    svg {
      margin-top: -10rem;
      transform: translateY(110%);
    }
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 2rem;
    p {
      font-size: 2.3rem;
    }
    h1 {
      font-size: 3.3rem;
    }

    svg{
      max-width: 10rem !important;
      transform: translateY(140%);

    }
  }
`;

export const FAQItem = styled(Box)`
  position: relative;
  border-radius: 1rem;
  border: solid 1px #eaedf7;
  background-color: #fff;
  padding: 2.4rem;

  > div:first-of-type {
    justify-content: space-between;
    cursor: pointer;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.63;
    letter-spacing: 0.5px;
    color: #242f57;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #242f57;
    padding: 0 2.4rem 2.4rem 2.4rem;
  }

  hr {
    margin: 2.1rem 2.4rem;
  }

  svg {
    transform: rotate(90deg);
    transition: 0.3s;
  }

  .content {
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
    z-index: 5;
    background-color: #fff;
    border-radius: 0 0 1rem 1rem;
    transition: 0.3s;
    transform-origin: top;
    box-shadow: 0 10px 9px 2px rgba(163, 153, 140, 0.21);
    a:visited {
      color: black;
    }
  }

  &.hidden {
    .content {
      transform: scaleY(0.7) translateY(-25%);
      opacity: 0;
      visibility: hidden;
    }

    svg {
      transform: rotate(0);
    }
  }
`;
