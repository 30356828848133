import { ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { AdContainer } from "./AdConstructor.styles";
import Creative1 from "../../assets/img/creatives/creative1.webp";
import BrandLogo from "../../assets/img/creatives/BrandLogo.webp";
import React from "react";
import { FlexBox } from "../../styles/components/Layout";
import { channelIcons, TriangleSvg } from "../../assets/svg/channels";

const ResponsiveTemplate = ({ data }) => {
  return (
    <AdContainer sx={{ padding: 0 }} marginTop={"1rem"}>
      <Box className={'imageBox'}><img src={data.image ?? Creative1} alt={"Creative"} /></Box>
      <Box className="icon"><TriangleSvg/></Box>
      <Box className="infoBox">
        <Typography className="title">{data.title}</Typography>
        <FlexBox>
          <Box className="logo">
            {data.logo ? (
              <img src={data.logo} alt={'Brand Logo'} width="4rem" height="4rem" />
            ) : (
              <img src={BrandLogo} alt={'Brand Logo'}/>
            )}
          </Box>
          <Box flex={1}>
            <Typography className="respDesc">{data.description}</Typography>
            <Typography className="businessName">{data.brandName}</Typography>
          </Box>
          <IconButton disabled disableRipple className="resp">
            <ArrowForwardIos />
          </IconButton>
        </FlexBox>
        <Box className='channelIcon'>{channelIcons[data.channel]}</Box>
      </Box>
    </AdContainer>
  );
};

export default ResponsiveTemplate;
