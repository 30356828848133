import {Box, Stack, styled, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {FlexBox} from "../../styles/components/Layout";
import theme from "../../styles/theme";

export const FormContainer = styled(Box)`
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #F4F7FC;
  padding: 5rem 0 5rem;
`

export const FormBox = styled(Stack)`
  width: 72rem;
  height: fit-content;
  background-color: #FFFFFF;
  padding: 6.2rem 8.4rem 4.5rem;
  border-radius: 1.6rem;
`

export const SupportTitle = styled(Typography)`
  margin: 0.5rem 0 4.6rem;
  font-family: 'Hellix', sans-serif;
  font-size: 2.6rem;
  color: #242F57;
  letter-spacing: 0.5px;
  line-height: 36px;
  font-weight: 500;
`

export const FormInput = styled(TextField)`
  width: 100%;
  margin: 0.4rem 0 2.4rem;
  background-color: unset;
  
  .MuiOutlinedInput-root {
    background-color: #fcfafa;
    padding-right: 0;
  }

  .MuiOutlinedInput-input {
    background-color: #fcfafa !important;
    border-radius: 1rem;
    font-size: 1.6rem;
    
  }
  input:['-internal-autofill-selected'] {
  background-color: #fcfafa !important;
}
  .MuiInputBase-inputMultiline {
    padding: 0;
  }
  .MuiFormHelperText-root {
    font-size: 1.2rem;
  }
`

export const FormInputName = styled(Typography)`
  font-family: 'Hellix', sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.16px;
  color: #4a5767;
  font-weight: 500
`

export const SupportFileAdd = styled(LoadingButton)({
    display: 'flex',
    flexDirection: 'column',
    width: '11.7rem',
    aspectRatio: '1/1',
    backgroundColor: '#fcfafa',
    border: '0.7px solid rgba(130,131,136,0.45)',
    borderRadius: '7px',
    'p': {
        fontSize: '1.2rem',
        letterSpacing: '0.12px',
        color: 'rgba(9,27,50,0.18)',
        marginTop: '1rem',
        textTransform: 'capitalize',
    },
    '&:hover': {
        borderColor: '#9FB0C6',
        backgroundColor: '#fcfafa',
    },

})

export const ButtonsBox = styled(FlexBox)`
  justify-content: space-between;
  margin-bottom: 5.8rem;
  padding-top: 0.98rem;
`

export const LoaderButton = styled(LoadingButton)`
  border-radius: 2.32rem;
  box-shadow: unset;
  padding: 1rem 6.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.17px;
  color: #FFFFFF;

  &:hover {
    background-color: ${theme.palette.success.main};
  }
`

export const SupportFootnote = styled(Typography)`
  opacity: 0.53;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.11px;
  color: #4a5767;
`

export const SupportImageBox = styled(FlexBox)`
  position: relative;
  border: 0.7px solid #9FB0C6;
  border-radius: 7px;
  padding: 0.5rem;
  width: 11.7rem;
  height: 11.7rem;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  button {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0.2rem;
    right: 0.2rem;
    cursor: pointer;
    padding: 0.5rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export const SupportThankYouTitle = styled(Typography)`
  font-size: 4.4rem;
  font-weight: 500;
  line-height: 0.82;
  letter-spacing: 0.85px;
  color: #242f57;
  margin: 3.2rem 0 3.9rem;
`

export const SupportThankYouText = styled(Typography)`
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #242f57;
  margin-bottom: 6rem;
`