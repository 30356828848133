import {
  BackgroundCreative,
  // ButtonClose,
  ChannelBox,
  ChannelImgBox,
  CommunitySentiment,
  CommunitySentimentDescription,
  CommunitySentimentTitle,
  CreativeCard,
  CreativeNumber,
  CreativeNumberText,
  CreativesContainer,
  CreativeTop,
  ExampleContainer,
  NumbersBox,
  SentimentComments,
  SentimentCustomTab,
  SubtitleChannel,
  TitleChannel,
} from "./CampaignExample.styles";
// import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Stack, Tabs, Typography } from "@mui/material";
import Link from "react-scroll/modules/components/Link";
// import {useState} from "react";
import { SadSvg, SmileSvg } from "../../assets/svg/buttonIcons";
import LineGraph from "../../shared/graphs/LineGraph";
import Bitmedia from "../../assets/img/channels/bitmedia.webp";
import Coinzilla from "../../assets/img/channels/coinzilla.webp";
import ResponsiveTemplate from "./ResponsiveTemplate";
import { useBreakpoints } from "../../shared/hooks/useBreakpoints";
import { data } from "../../data/data";
import { graphData, legendData } from "./campaignData";

const channels = {
  bitmedia: Bitmedia,
  coinzilla: Coinzilla,
};

const formatNumber = (number) => {
  if (number < 1000) return number.toString();
  else if (number < 10000) return `${(number / 1000).toFixed(1)}K`;
  else if (number < 1000000) return `${(number / 1000).toFixed(0)}K`;
  else return `${(number / 1000000).toFixed(0)}M`;
};
export const CampaignExample = (props) => {
  const { active } = props;
  // console.log(active)
  // const [activeTab, setActiveTab] = useState(0);
  const activeTab = 0;
  const { desktopOnly, mobileOnly, laptopScreen, screen1024, tabletScreen } =
    useBreakpoints();

  const niche = data[active.name.toLowerCase()];

  const Creative = ({ data, brandName, logo, sx }) => {
    return (
      <CreativeCard sx={sx}>
        <CreativeTop>
          <BackgroundCreative />
          <ChannelBox>
            <ChannelImgBox>
              <img src={channels[data.channel]} alt={"Channel"} />
            </ChannelImgBox>
            <Stack>
              <TitleChannel>{data.channel}</TitleChannel>
              <SubtitleChannel>{`Group ${data.group_num}`}</SubtitleChannel>
            </Stack>
          </ChannelBox>
          <NumbersBox>
            {data.numbers.map((num, i) => (
              <Stack key={i}>
                <CreativeNumber>
                  {num.name === "spent"
                    ? `$${formatNumber(num.value)}`
                    : formatNumber(num.value)}
                </CreativeNumber>
                <CreativeNumberText>{num.name}</CreativeNumberText>
              </Stack>
            ))}
          </NumbersBox>
        </CreativeTop>
        <ResponsiveTemplate data={{ ...data, brandName, logo }} />
      </CreativeCard>
    );
  };
  return (
    <ExampleContainer active={active.num} id={"example"}>
      <Creative
        sx={mobileOnly}
        data={niche.creatives[0]}
        brandName={niche.brandName}
        logo={niche.logo}
      />
      <CommunitySentiment>
        <CommunitySentimentTitle>
          {"Community Sentiment"}
        </CommunitySentimentTitle>
        <Grid container spacing={{ xs: "5rem", lg: "17rem" }}>
          <Grid item xs={12} md={5.9}>
            <CommunitySentimentDescription marginBottom="6rem">
              {`Community is at the heart of every company. We must understand our community’s mindset, and what the 
                            community thinks of our company, whether the mindset is positive or negative - and thus identify our own 
                            strengths and weak points.`}
            </CommunitySentimentDescription>
            <LineGraph data={graphData[active.num]} legend={legendData} />
          </Grid>
          <Grid item xs={12} md={6.1}>
            <Stack height="100%">
              <Tabs value={activeTab}>
                <SentimentCustomTab
                  disabled
                  label="Positive Feedback"
                  icon={<SmileSvg />}
                />
                <SentimentCustomTab
                  disabled
                  label="Negative Feedback"
                  icon={<SadSvg />}
                />
              </Tabs>
              <SentimentComments spacing={"4rem"}>
                {data[active.name.toLowerCase()].sentimentData[activeTab]?.map(
                  (el, i) => (
                    <Box key={el.text + i}>
                      <Typography>{el.name}</Typography>
                      <Typography>{el.text}</Typography>
                    </Box>
                  )
                )}
              </SentimentComments>
            </Stack>
          </Grid>
        </Grid>
      </CommunitySentiment>
      <CreativesContainer sx={desktopOnly}>
        {!laptopScreen &&
          niche.creatives
            .slice(0, 5)
            .map((el, i) => (
              <Creative
                key={i}
                data={el}
                brandName={niche.brandName}
                logo={niche.logo}
              />
            ))}
        {laptopScreen &&
          !tabletScreen &&
          niche.creatives
            .slice(0, 4)
            .map((el, i) => (
              <Creative
                key={i}
                data={el}
                brandName={niche.brandName}
                logo={niche.logo}
              />
            ))}
        {tabletScreen &&
          niche.creatives
            .slice(0, 3)
            .map((el, i) => (
              <Creative
                key={i}
                data={el}
                brandName={niche.brandName}
                logo={niche.logo}
              />
            ))}
      </CreativesContainer>
    </ExampleContainer>
  );
};
