import { Box, styled } from "@mui/material";
import { NFTSectionContainer, SectionContainer } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";

export const CommunityNftOuterContainer = styled(Box)`
  position: relative;
  background-image: linear-gradient(to bottom, #F2A626, #fff);
  padding-bottom: 30rem;
`;

export const CommunityNftBg = styled(Box)`
  position: absolute;
  top: -3rem;
  width: 100%;
  height: calc(100% + 3rem);
  //border-radius: 0 32rem 32rem 0;
  border-top-right-radius: 32rem;
  z-index: 2;

  background-color: #fff;
  box-shadow: .7rem 1rem 1.7rem 0 rgba(0, 0, 0, 0.08);
  
  ${theme.breakpoints.down("md")}{
    border-top-right-radius: 20rem;
  }
  ${theme.breakpoints.down("br768")}{
    top: -30rem;
    height: calc(100% + 30rem);
    border-top-right-radius: 0;
  }
`;

export const CommunityNftContent = styled(NFTSectionContainer)`
  position: relative;
  z-index: 3;
  padding-top: 5.8rem;
  padding-bottom: 27rem;
  
img{
  margin-top: -2.5rem;
  margin-left: -3rem;
  width: calc(100% + 3rem);
  ${theme.breakpoints.down('br768')} {
    margin-left: 0;
    width: 100%;
  }
  }

  h2 {
    margin-bottom: 4.4rem;
    max-width: 70rem;
  }

  p {
    max-width: 93rem;
    line-height: 1.25;
    margin-bottom: 5.6rem;
  }

  .MuiButtonBase-root{
    //width: 100%;
    margin: 0 auto 8.8rem;
    padding: 1.2rem 9rem 1.2rem 7rem;
    font-weight: bold;
    
    ${theme.breakpoints.down("br768")}{
      display: flex !important;
    }

    ${theme.breakpoints.down("br480")}{
      padding: 1.5rem 10vw;
    }
  }

  ${theme.breakpoints.down("768")}{
    padding-top: 35rem;
  }
  ${theme.breakpoints.down("sm")}{
    padding-top: 30rem;
  }
  ${theme.breakpoints.down("br480")}{
    padding-top: 22rem;
  }
`;
