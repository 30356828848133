export function Checkbox(props) {
    return (
        <svg viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M0 8.286A4.285 4.285 0 014.286 4h27.428A4.285 4.285 0 0136 8.286v27.428A4.285 4.285 0 0131.714 40H4.286A4.285 4.285 0 010 35.714V8.286zm4.286-2.572a2.572 2.572 0 00-2.572 2.572v27.428a2.572 2.572 0 002.572 2.572h27.428a2.572 2.572 0 002.572-2.572V8.286a2.572 2.572 0 00-2.572-2.572H4.286zM27.178 16.25a.86.86 0 010 1.214L16.893 27.75a.86.86 0 01-1.214 0l-5.143-5.143a.86.86 0 010-1.214.86.86 0 011.214 0l4.536 4.538 9.679-9.68a.86.86 0 011.213 0z"
            transform="translate(-1388 -1189) translate(1388 1185.7)"
            fill="#F2A626"
            stroke="none"
            strokeWidth={1}
            fillRule="evenodd"
          />
        </svg>
      )
  }

  export function CloseSvg(props) {
    return (
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M1726.7 455.048c-7.816 0-14.152 6.336-14.152 14.152 0 7.816 6.336 14.152 14.152 14.152 7.816 0 14.152-6.336 14.152-14.152 0-7.816-6.336-14.152-14.152-14.152m-15.5 14.152c0-8.561 6.939-15.5 15.5-15.5s15.5 6.939 15.5 15.5-6.939 15.5-15.5 15.5-15.5-6.939-15.5-15.5m9.632-5.868a.676.676 0 01.954 0l4.914 4.915 4.914-4.915a.676.676 0 01.954.954l-4.915 4.914 4.915 4.914a.676.676 0 01-.954.954l-4.914-4.915-4.914 4.915a.676.676 0 01-.954-.954l4.915-4.914-4.915-4.914a.676.676 0 010-.954"
          transform="translate(-1711 -453)"
          fill="#293644"
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
          opacity={0.36563837}
        />
      </svg>
    )
  }


export function AttentionUserSvg(props) {
    return (
        <svg viewBox="0 0 15 13" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path
                d="M1915.91 33.534a.784.784 0 00-1.345 0l-5.31 8.85a.784.784 0 00.672 1.188h10.621a.784.784 0 00.673-1.188l-5.311-8.85zm-2.151-.484a1.724 1.724 0 012.957 0l5.311 8.85c.69 1.15-.139 2.613-1.479 2.613h-10.621c-1.34 0-2.169-1.463-1.478-2.612l5.31-8.85zm1.479 2.996c.26 0 .47.211.47.47v2.51a.47.47 0 01-.94 0v-2.51c0-.259.21-.47.47-.47zm0 4.39c.26 0 .47.211.47.47v.628a.47.47 0 01-.94 0v-.627c0-.26.21-.47.47-.47z"
                transform="translate(-1908 -32)"
                fill={props.fill}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function EnergySvg(props) {
  return (
    <svg
      viewBox="0 0 39 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-210 -411) translate(210.679 411.62)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <circle
          stroke="#FFF"
          strokeWidth={3}
          cx={19.1145961}
          cy={19.1145961}
          r={17.6145961}
        />
        <path
          d="M20.294 8.062c1.396-1.571 3.96-.2 3.43 1.833l-1.726 6.619 2.858 1.144a2 2 0 01.753 3.185l-8.289 9.324c-1.396 1.572-3.96.2-3.429-1.833l1.726-6.618-2.859-1.144a2 2 0 01-.753-3.186l8.29-9.324z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export function QuestionSvg(props) {
  return (
    <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg"{...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M26.005 52.01a25.902 25.902 0 0016.828-6.177c.941-.8 4.785.747 6.645.475 1.86-.272-2.8-4.495-2.259-5.258a25.887 25.887 0 004.791-15.045C52.01 11.643 40.368 0 26.005 0 11.643 0 0 11.643 0 26.005 0 40.368 11.643 52.01 26.005 52.01z"
          fill="#369AFE"
          transform="translate(-210 -7249) translate(210 7249.01)"
        />
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={38.5}
          fontWeight="normal"
          letterSpacing={1.3444438}
          fill="#FFF"
          transform="translate(-210 -7249) translate(210 7249.01)"
        >
          <tspan x={17.2552466} y={39.5760932}>
            {"?"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export function AnswerSvg(props) {
  return (
    <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg"{...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M26.005 52.01a25.902 25.902 0 0016.828-6.177c.941-.8 4.785.747 6.645.475 1.86-.272-2.8-4.495-2.259-5.258a25.887 25.887 0 004.791-15.045C52.01 11.643 40.368 0 26.005 0 11.643 0 0 11.643 0 26.005 0 40.368 11.643 52.01 26.005 52.01z"
          fill="#E69D22"
          transform="translate(-211 -7327) translate(210 7249.01) translate(1.105 78)"
        />
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={34.5}
          fontWeight="normal"
          letterSpacing={1.20476133}
          fill="#FFF"
          transform="translate(-211 -7327) translate(210 7249.01) translate(1.105 78)"
        >
          <tspan x={14.5052466} y={36.0052466}>
            {"A"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export function PlusSvg(props) {
  return (
    <svg
      viewBox="0 0 147 148"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M794.805 2171.781l.98 26.874a4 4 0 003.56 3.83l26.077 2.878a6 6 0 015.32 6.487l-1.858 21.23a6 6 0 01-6.5 5.455l-20.71-1.812a4 4 0 00-4.346 4.13l.838 22.928a6 6 0 01-5.996 6.219h-24.754a6 6 0 01-6-6v-27.134a4 4 0 00-3.652-3.985l-27.117-2.372a6 6 0 01-5.461-6.412l1.698-23.347a6 6 0 016.642-5.528l23.45 2.588a4 4 0 004.44-3.976V2172a6 6 0 016-6h21.393a6 6 0 015.996 5.781z"
          id="b"
        />
        <filter
          x="-30.6%"
          y="-25.0%"
          width="161.1%"
          height="163.5%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology
            radius={7}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy={7}
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={7.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.183740576 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        transform="translate(-705 -2151) rotate(-11 778.005 2218)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <path
          stroke="#FFF"
          strokeWidth={7}
          d="M788.809 2162.5a9.473 9.473 0 016.594 2.661 9.473 9.473 0 012.9 6.492l.98 26.875 26.523 3.356a9.472 9.472 0 016.304 3.419 9.472 9.472 0 012.118 6.852l-1.857 21.23a9.469 9.469 0 01-3.222 6.334 9.474 9.474 0 01-6.788 2.323l-20.992-1.832.294 23.443a9.47 9.47 0 01-2.535 6.815 9.47 9.47 0 01-6.682 3.028l-25.03.004a9.47 9.47 0 01-6.718-2.783 9.47 9.47 0 01-2.782-6.717v-27.134l-27.574-2.87a9.471 9.471 0 01-6.405-3.304 9.471 9.471 0 01-2.242-6.85l1.698-23.346a9.47 9.47 0 013.263-6.497 9.47 9.47 0 016.973-2.283l23.731 2.615.556-22.331a9.47 9.47 0 012.782-6.717 9.47 9.47 0 016.718-2.783z"
          fill="#E69D22"
        />
      </g>
    </svg>
  )
}