import Pic1 from "../../../assets/img/nft/howItWorks/hiw-1.webp";
import Pic2 from "../../../assets/img/nft/howItWorks/hiw-2.webp";
import Pic3 from "../../../assets/img/nft/howItWorks/hiw-3.webp";
import Pic4 from "../../../assets/img/nft/howItWorks/hiw-4.webp";

export const howItWorksData = [
  {
    title: "Advertise your project across Web3",
    desc: `Webm is the first decentralized Web3 ad buying and media planning platform. Gain early access to the full tool suite.

Plus, enjoy one-on-one consults with Webm executives to get the most out of the platform.`,
    pic: Pic1,
  },
  {
    title: "A community of top marketers",
    desc: `Not just anyone can purchase the Web3m NFT. Through careful vetting, our goal is to create an exclusive network of the most proficient entrepreneurs and marketers in Web3.`,
    pic: Pic2,
  },
  {
    title: "Join the exclusive Web3m token presale",
    desc: `NFT holders have the opportunity to purchase Web3m’s future token at a discounted price before it is officially launched. 

Token holders help determine the future of Web3m through voting privileges.`,
    pic: Pic3,
  },
  {
    title: "Enjoy a 2% kickback for just being an owner",
    desc: `NFT holders are part owners in Web3m. You will receive a kickback based on 2% of all marketing spending that occurs through the platform.`,
    pic: Pic4,
  },
];
