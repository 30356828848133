import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { connectWallet } from "../../../shared/helpers/metamaskConnect";
import { pageData, sectionsAvailable } from "./HeaderData";
import { CheckNFT } from "../../../api/web3Requests";
import { dashboardLink } from "../../../utils/helpers";

export const useHeader = () => {
  const [headerFloat, setHeaderFloat] = useState(window.pageYOffset > 350);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasNFT, setHasNFT] = useState(null);
  const [wallet, setWallet] = useState("");
  const [walletChecking, setWalletChecking] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const { user, loginWithPopup, isAuthenticated, error } = useAuth0();

  const status = !isAuthenticated ? "notAuthorized" : "connected";

  const pages = pageData.filter((el) => sectionsAvailable[status]?.includes(el.title));

  const toggleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const getUserData = async (user_id) => {
    setWalletChecking(true);
    const data = await axios.get(`https://mvp-statistics-manager-dot-winky-apis.ew.r.appspot.com/user/${user_id}`);
    const userPaymentInfo = data?.data?.user?.payment_info;
    setWallet(userPaymentInfo?.wallet_address);
    setHasNFT(userPaymentInfo?.has_nft);
    setWalletChecking(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserData(user?.sub);
    }
  }, [isAuthenticated]);

  const login = async () => {
    await loginWithPopup();
    setRedirectToDashboard(true);
  };

  useEffect(() => {
    if (!isAuthenticated) return setRedirectToDashboard(false);

    if (redirectToDashboard) {
      console.log("redirect");

      window.location.replace(dashboardLink);
    }
  }, [isAuthenticated, redirectToDashboard]);

  useEffect(function mount() {
    function onScroll(e) {
      // console.log(window.scrollY);
      if (window.scrollY > 350 && !headerFloat) {
        setHeaderFloat(true);
      }
      if (window.scrollY <= 350 && headerFloat) {
        setHeaderFloat(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return function unMount() {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return {
    headerFloat,
    menuOpen,
    toggleMobileMenu,
    attentionUser: wallet && wallet !== "" && !hasNFT,
    pages,
    login,
    walletChecking
  };
};
