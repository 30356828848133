import {Box, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import {TrianglesSvg} from "../../assets/svg/buttonIcons";
import {SectionContainer} from "../../styles/components/Layout";
import {KeyPointContainer, KeyPointsBackground} from "./KeyPoints.styles";
import {keyPointsData} from "./keyPointsData";
import {useAuth0} from "@auth0/auth0-react";
import React from "react";

const KeyPoints = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const {isAuthenticated} = useAuth0()
    return (
        <Box position="relative">
            <KeyPointsBackground elevation={0}/>
            <SectionContainer paddingTop={"50rem"}>
                {keyPointsData.map((point, index) => (
                    <KeyPointContainer key={index + point.title}>
                        <Box>
                            <Typography variant="h2">{point.title}</Typography>
                            <Typography>{point.desc}</Typography>
                            <Typography className="early-access">
                                {isAuthenticated
                                    ? <a href={'https://dashboard.web3m.io'} target={'_dashboard'}>
                                        {'Enter your user account '}<TrianglesSvg/>
                                    </a>
                                    : <Link to="/signup">
                                        {'Get early access '}<TrianglesSvg/>
                                    </Link>}
                            </Typography>
                        </Box>
                        <Box>
                            <img
                                src={isMobile ? point.imageMob : point.image}
                                alt="platform-change-description"
                            />
                        </Box>
                    </KeyPointContainer>
                ))}
            </SectionContainer>
        </Box>
    );
};

export default KeyPoints;
