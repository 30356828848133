import Step1 from '../../../assets/img/nft/steps1.webp'
import Step2 from '../../../assets/img/nft/steps2.webp'
import Step3 from '../../../assets/img/nft/steps3.webp'


export const stepsData = [
  {
    image: Step1,
    title1: 'Apply',
    title2: 'online',
    desc: 'Click the apply button below and follow the prompts.'
  },
  {
    image: Step2,
    title1: 'Interview with',
    title2: 'Webm',
    desc: 'Schedule an interview with the Webm executive team.'
  },
  {
    image: Step3,
    title1: 'Purchase',
    title2: 'the NFT',
    desc: 'If approved, you’ll have the option to buy the Web3m NFT — and join the community.'
  },
]