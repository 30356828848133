import { Box, styled } from "@mui/material";
import theme from "../../../styles/theme";

export const MembershipNftBg = styled(Box)`
  position: relative;
  background-color: #f3f2f2;
  //border-radius: 0 32rem 32rem 0;

  //border-bottom-right-radius: 32rem;
  z-index: 5;
  margin-top: -30rem;
  border-radius: 0 10rem 10rem 0;

  > div {
    height: 70rem;

    > div:first-of-type {
      position: absolute;
      z-index: 5;
      top: -27rem;

      column-gap: 7rem;

    }
  }

  ${theme.breakpoints.down("br768")} {

    > div {
      height: auto;

        > div:first-of-type {
          position: relative;
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
    }

    //position: relative;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const MembershipNftCard = styled(Box)`
  position: relative;
  border: 2px dashed #c5c5c5;
  border-bottom: none;
  //border-image-source: linear-gradient(to top, #f4ede4 100%, #c5c5c5);
  //border-image-slice: 1;
  padding: 5.6rem 16rem 13rem 6.3rem;
  border-radius: 8.8rem;
  background-image: linear-gradient(to bottom, #f4ede4, #f4ede4),
    linear-gradient(to top, #f4ede4 100%, #c5c5c5);
  flex: 0 0 calc(50% - 3.5rem);
  align-self: stretch;

  h3 {
    font-family: "F37 Judge", sans-serif;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 1.4px;
    line-height: normal;
    color: #195afe;
    text-transform: uppercase;
    margin-bottom: 3.2rem;
  }

  p {
    line-height: 1.25;
    max-width: 31rem;
  }

  svg,
  img {
    position: absolute;
    width: 85%;

    left: 4rem;
    z-index: 10;
  }

  svg {
    top: 82%;
  }

  img {
    top: 75%;
    left: 4rem;
  }

  ${theme.breakpoints.down("br768")} {
    &:first-of-type {
      margin-bottom: 50vw;
    }
    &:last-of-type {
      margin-top: -2vw;
      margin-bottom: 42vw;
      border: none;
      background-image: none;
    }

    .mobCardTop {
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    h3,
    p {
      position: relative;
      z-index: 3;
    }

    img {
      top: 80%;
      left: 50%;
      width: 90%;
      transform: translateX(-51%);
    }
  }

  ${theme.breakpoints.down("br480")} {
    &:first-of-type {
      margin-bottom: 52%;
    }
    &:last-of-type {
      margin-top: -4vw;
      margin-bottom: 30vw;
    }
    padding: 5rem 5rem 13rem;
    h3 > br {
      display: none;
    }
    p {
      max-width: 100%;
    }
  }
`;

export const PlusIcon = styled(Box)`
  position: absolute;
  width: 14.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  svg {
    width: 100%;
  }

  ${theme.breakpoints.down("br768")} {
    position: relative;

    top: 0;
    left: 0;
    margin: 0 auto;
    transform: translateX(-5%);
    //left: 50%;
    //transform: translate(-57%,-25%);
  }
  ${theme.breakpoints.down("sm")} {
    transform: translateX(5%);
  }
  ${theme.breakpoints.down("br480")} {
    width: 10rem;
    //transform: translate(-57%,-13%);
    transform: translateX(-5%);
  }
`;

export const MembershipCTA = styled(Box)`
  position: absolute;
  bottom: 1rem;
  z-index: 7;
  transform: translate(0, 50%);

  > svg {
    position: absolute;
    bottom: -2.5rem;
    width: 62rem;
    transform: translate(-13rem, 1.2rem);
  }

  .MuiButtonBase-root {
    margin-bottom: -1.5rem;
  }

  ${theme.breakpoints.down("br768")} {
    width: 100%;
    display: flex;

    > svg {
      width: 76rem;
      bottom: -2.8rem;
      left: 50%;
      transform: translate(-50%, 2rem);
    }

    .MuiButtonBase-root {
      padding: 1.5rem 10rem 1.5rem 7rem;
      margin: -1rem auto 0;
    }
  }

  ${theme.breakpoints.down("br480")} {
    
    .MuiButtonBase-root {
      padding: 1.5rem 10vw 1.5rem;
    }
  }
`;
